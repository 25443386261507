import React, { useState, useEffect } from 'react';

// Rechart
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell,
    LabelList,
} from 'recharts';

// Models
import { Team } from 'Modules/Models/Team';
import { User } from 'Modules/Models/User';

type TeamHorizontalBarGraphProps = { users: User[]; teams: Team[] };
function TeamHorizontalBarGraph({ users, teams }: TeamHorizontalBarGraphProps) {
    const [chartData, setChartData] = useState<
        {
            category: string;
            Users: number;
        }[]
    >([]);

    useEffect(() => {
        let data = teams.map((team, i) => ({
            category: team.name,
            Users: team.userCount,
        }));
        data.push({
            category: 'Unassigned',
            Users: users.filter((u) => u.teamId == null).length,
        });
        setChartData(data);
    }, [users, teams]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 30,
                    bottom: 5,
                }}
                layout="vertical"
            >
                <CartesianGrid horizontal={false} />
                <XAxis type="number" allowDecimals={false} hide />
                <YAxis width={110} dataKey="category" type="category" />
                <Tooltip />
                <Bar dataKey="Users">
                    {chartData.map((entry, key) => (
                        <Cell
                            key={key}
                            fill={
                                entry.category === 'Unassigned'
                                    ? '#63c2d2'
                                    : '#3d9a8b'
                            }
                        ></Cell>
                    ))}
                    <LabelList dataKey="Users" position="right" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
export default TeamHorizontalBarGraph;
