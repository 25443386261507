import { useState, useEffect } from 'react';

import BasePage from 'HammerTemplate/PageTemplates/BasePage';

import {
    getAllPhonesSuperAdmin,
    getAllOrganizationsSuperAdmin,
    deletePhonesSuperAdmin,
    updatePhoneCapability,
    updatePhoneOrganization,
    getTestingPairSuperAdmin,
} from 'Modules/Http/SuperAdminInterface';

// Material UI
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import SuperAdminTableAutocomplete from 'Modules/SuperAdmin//Components/SuperAdminTableAutoComplete';
import SuperAdminTableToggle from 'Modules/SuperAdmin//Components/SuperAdminTableToggle';
import ConfirmDeleteDialog from 'Modules/Common/Components/ConfirmDeleteDialog';
import AddPhoneDialog from 'Modules/SuperAdmin/Components/SuperAdminAddPhoneDialog';
//Models
import { Organization } from 'Modules/Models/Organization';
import SuperAdminGetPhones from 'Modules/Models/SuperAdminApi/SuperAdminGetPhones';
import { SuperAdminGetTestingPair } from 'Modules/Models/SuperAdminApi/SuperAdminGetTestingPair';

async function editPhoneAssignedOrganization(
    phoneId: number | undefined,
    organizationId: number | undefined
): Promise<boolean> {
    if (phoneId) {
        updatePhoneOrganization(phoneId, organizationId);
    }
    return true;
}
async function editPhoneAccessInboundSMS(
    phoneId: number | undefined,
    changeStateTo: boolean | undefined
): Promise<boolean> {
    if (phoneId && changeStateTo !== undefined) {
        await updatePhoneCapability(phoneId, changeStateTo, null, null, null);
        return true;
    } else {
        return false;
    }
}
async function editPhoneAccessOutboundSMS(
    phoneId: number | undefined,
    changeStateTo: boolean | undefined
): Promise<boolean> {
    if (phoneId && changeStateTo !== undefined) {
        await updatePhoneCapability(phoneId, null, changeStateTo, null, null);
        return true;
    } else {
        return false;
    }
}
async function editPhoneAccessInboundVoice(
    phoneId: number | undefined,
    changeStateTo: boolean | undefined
): Promise<boolean> {
    if (phoneId && changeStateTo !== undefined) {
        await updatePhoneCapability(phoneId, null, null, changeStateTo, null);
        return true;
    } else {
        return false;
    }
}
async function editPhoneAccessOutboundVoice(
    phoneId: number | undefined,
    changeStateTo: boolean | undefined
): Promise<boolean> {
    if (phoneId && changeStateTo !== undefined) {
        await updatePhoneCapability(phoneId, null, null, null, changeStateTo);
        return true;
    } else {
        return false;
    }
}

function SuperAdminPhoneManagementPage() {
    async function getAdminData() {
        let allPhones = await getAllPhonesSuperAdmin();
        setSelectedPhoneIds(allPhones ? allPhones : []);
        setOrganizations(await getAllOrganizationsSuperAdmin());
    }

    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [selectedPhoneIds, setSelectedPhoneIds] = useState<
        SuperAdminGetPhones[]
    >([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [isCreatePhoneDialogOpen, setIsCreatePhoneDialogOpen] =
        useState<boolean>(false);
    const [isDeletePhoneDialogOpen, setIsDeletePhoneDialogOpen] =
        useState<boolean>(false);
    const [containsTestPair, setContainsTestPair] = useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        getAdminData();
    }, [isCreatePhoneDialogOpen, isDeletePhoneDialogOpen]);

    useEffect(() => {
        checkTestPairs();
    }, [selectedUsers]);

    async function checkTestPairs() {
        let testPairs: SuperAdminGetTestingPair[] | null =
            await getTestingPairSuperAdmin();
        setContainsTestPair(false);
        if (testPairs) {
            for (let i: number = 0; i < testPairs?.length; i++) {
                for (let j: number = 0; j < selectedUsers.length; j++) {
                    if (
                        testPairs[i].receiverPhone.id === selectedUsers[j] ||
                        testPairs[i].senderPhone.id === selectedUsers[j]
                    ) {
                        setContainsTestPair(true);
                    }
                }
            }
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'creationDate',
            headerName: 'Creation Date',
            flex: 0.3,
            width: 150,
            valueGetter: (params) => {
                return params.row?.creationDate.split('T')[0];
            },
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            flex: 0.3,
            width: 150,
        },
        {
            field: 'networkProvider',
            headerName: 'Network Provider',
            flex: 0.3,

            width: 150,
        },
        {
            field: 'temp',
            headerName: 'Assigned Organization',
            flex: 0.5,

            width: 150,
            renderCell: (params) => {
                return (
                    <SuperAdminTableAutocomplete
                        organizations={organizations}
                        renderCellParams={params}
                        editPhoneAssignedOrganization={
                            editPhoneAssignedOrganization
                        }
                    />
                );
            },
        },
        {
            field: 'inboundSmsCapable',
            headerName: 'Inbound SMS',
            flex: 0.3,

            width: 150,
            renderCell: (params) => {
                return (
                    <SuperAdminTableToggle
                        renderCellParams={params}
                        editPhoneAccess={editPhoneAccessInboundSMS}
                    />
                );
            },
        },
        {
            field: 'outboundSmsCapable',
            headerName: 'Outbound SMS',
            flex: 0.3,

            width: 150,
            renderCell: (params) => {
                return (
                    <SuperAdminTableToggle
                        renderCellParams={params}
                        editPhoneAccess={editPhoneAccessOutboundSMS}
                    />
                );
            },
        },
        {
            field: 'inboundVoiceCapable',
            headerName: 'Inbound Voice',
            flex: 0.3,

            width: 150,
            renderCell: (params) => {
                return (
                    <SuperAdminTableToggle
                        renderCellParams={params}
                        editPhoneAccess={editPhoneAccessInboundVoice}
                    />
                );
            },
        },
        {
            field: 'outboundVoiceCapable',
            headerName: 'Outbound Voice',
            flex: 0.3,

            width: 150,
            renderCell: (params) => {
                return (
                    <SuperAdminTableToggle
                        renderCellParams={params}
                        editPhoneAccess={editPhoneAccessOutboundVoice}
                    />
                );
            },
        },
    ];

    return (
        <BasePage pageName="Phone Management">
            {isDeletePhoneDialogOpen && (
                <ConfirmDeleteDialog
                    isOpen={isDeletePhoneDialogOpen}
                    handleDialogClose={() => setIsDeletePhoneDialogOpen(false)}
                    handleDelete={async () => {
                        try {
                            await deletePhonesSuperAdmin(selectedUsers);
                            setIsDeletePhoneDialogOpen(false);
                        } catch {
                            setErrorAlert(true);
                        }
                    }}
                >
                    <Stack>
                        <Typography variant="subtitle1">
                            Are you sure you want to delete these phones?
                        </Typography>
                        {containsTestPair && (
                            <Alert severity="warning">
                                Selected Phone is in a test pair
                            </Alert>
                        )}
                        {errorAlert && (
                            <Alert severity="error">
                                Unable to delete phone
                            </Alert>
                        )}{' '}
                    </Stack>
                </ConfirmDeleteDialog>
            )}

            {isCreatePhoneDialogOpen && (
                <AddPhoneDialog
                    isOpen={isCreatePhoneDialogOpen}
                    handleDialogClose={() => {
                        setIsCreatePhoneDialogOpen(false);
                    }}
                ></AddPhoneDialog>
            )}
            <Box height="5%" width="100%" pr={5} mt={2}>
                <Stack
                    component="span"
                    justifyContent="flex-end"
                    direction="row"
                >
                    <IconButton
                        onClick={() => setIsDeletePhoneDialogOpen(true)}
                        disabled={selectedUsers.length === 0}
                    >
                        <Tooltip title="Delete">
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        onClick={() => setIsCreatePhoneDialogOpen(true)}
                    >
                        <Tooltip title="Create Phone">
                            <AddBoxIcon />
                        </Tooltip>
                    </IconButton>{' '}
                </Stack>
            </Box>
            <Box
                width="100%"
                height="95%"
                display="grid"
                pr={5}
                pl={5}
                gridTemplateRows="95%"
            >
                <Box height="100%" width="100%">
                    <DataGridPro
                        rows={selectedPhoneIds}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={(newSelection) => {
                            setSelectedUsers(newSelection as number[]);
                        }}
                    />
                </Box>
            </Box>
        </BasePage>
    );
}

export default SuperAdminPhoneManagementPage;
