import React, { useState, useEffect } from 'react';

// Material UI
import BasePage from 'HammerTemplate/PageTemplates/BasePage';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

// User Management Components
import UsersTable from 'Modules/UserManagement/Components/UsersTable';
import CreateUserDialog from 'Modules/UserManagement/Components/CreateUserDialog';
import TeamsTable from 'Modules/UserManagement/Components/TeamsTable';
import TeamHorizontalBarGraph from 'Modules/UserManagement/Components/TeamHorizontalBarGraph';
import CreateTeamDialog from 'Modules/UserManagement/Components/CreateTeamDialog';

// Common Components
import ConfirmDeleteDialog from 'Modules/Common/Components/ConfirmDeleteDialog';

// Models
import { User, UserType } from 'Modules/Models/User';
import { Team } from 'Modules/Models/Team';

// Http
import {
    getAllUsersInOrganizationAsync,
    assignUserToTeamAsync,
    removeUserFromTeamAsync,
    addUserAsync,
    deleteUsersAsync,
    getAllUsersInTeamAsync,
} from 'Modules/Http/UserInterface';
import {
    addTeamForOrganizationAsync,
    deleteTeamsAsync,
    getAllTeamsInOrganizationAsync,
} from 'Modules/Http/TeamInterface';

// User Context
import { useUserContext } from 'Modules/UserContextProvider';

function UserManagementPage() {
    const currentUser = useUserContext();

    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<number[]>([]);
    const [deleteUserErrorAlert, setDeleteUserErrorAlert] =
        useState<boolean>(false);
    const [deleteTeamErrorAlert, setDeleteTeamErrorAlert] =
        useState<boolean>(false);

    const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] =
        useState<boolean>(false);
    const [isCreateTeamDialogOpen, setIsCreateTeamDialogOpen] =
        useState<boolean>(false);
    const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] =
        useState<boolean>(false);
    const [isDeleteTeamDialogOpen, setIsDeleteTeamDialogOpen] =
        useState<boolean>(false);

    const [showTeamManagementColumn, setShowTeamManagementColumn] =
        useState<boolean>(false);

    function handleDeleteTeamDialogClose() {
        setIsDeleteTeamDialogOpen(false);
    }
    function handleDeleteUserDialogClose() {
        setIsDeleteUserDialogOpen(false);
    }
    function handleCreateUserDialogClose() {
        setIsCreateUserDialogOpen(false);
    }
    function handleCreateTeamDialogClose() {
        setIsCreateTeamDialogOpen(false);
    }
    async function editUserTeam(userId: number, teamId?: number) {
        let singleUserId: number[] = [userId];
        if (teamId == null) {
            let res = await removeUserFromTeamAsync(userId);
            fetchInitialValues();
            return res;
        } else {
            let res = await assignUserToTeamAsync(teamId, singleUserId);
            fetchInitialValues();
            return res;
        }
    }

    async function createNewUser(
        username: string,
        password: string,
        role: UserType | string,
        team: Team | null
    ) {
        let teamId: number | null = null;
        if (team != null) {
            teamId = team.id;
        }
        let res = await addUserAsync(username, password, role, teamId, null);
        if (res) {
            handleCreateUserDialogClose();
            fetchInitialValues();
        }
    }
    async function createNewTeam(name: string, userIds: number[]) {
        let teamId = await addTeamForOrganizationAsync(name, null);
        if (teamId != null) {
            if (userIds.length !== 0) {
                await assignUserToTeamAsync(teamId, userIds);
            }
            await fetchInitialValues();
            handleCreateTeamDialogClose();
        }
    }
    async function deleteSelectedUsers() {
        try {
            await deleteUsersAsync(selectedUsers);
            handleDeleteUserDialogClose();
            fetchInitialValues();
        } catch (e: any) {
            setDeleteUserErrorAlert(true);
        }
    }
    async function deleteSelectedTeams() {
        try {
            await deleteTeamsAsync(selectedTeams);
            handleDeleteTeamDialogClose();
            fetchInitialValues();
        } catch (e: any) {
            setDeleteTeamErrorAlert(true);
        }
    }

    async function fetchInitialValues() {
        if (
            currentUser.userType === UserType.OrganizationAdmin ||
            currentUser.userType === UserType.SuperAdmin
        ) {
            let getUsers = getAllUsersInOrganizationAsync();
            let getTeams = getAllTeamsInOrganizationAsync();

            let users = await getUsers;
            let teams = await getTeams;
            if (users != null) {
                setUsers(users);
            }
            if (teams != null) {
                setTeams(teams);
            }
        } else if (currentUser.userType === UserType.TeamAdmin) {
            let getUsers = getAllUsersInTeamAsync();
            let users = await getUsers;
            if (users != null) {
                setUsers(users);
            }
        }
    }
    function setDisplayForUserType() {
        if (
            currentUser.userType === UserType.OrganizationAdmin ||
            currentUser.userType === UserType.SuperAdmin
        ) {
            setShowTeamManagementColumn(true);
        } else {
            setShowTeamManagementColumn(false);
        }
    }
    useEffect(() => {
        fetchInitialValues();
        setDisplayForUserType();
    }, []);
    return (
        <BasePage pageName="User and Team Management">
            {isCreateUserDialogOpen && (
                <CreateUserDialog
                    isOpen={isCreateUserDialogOpen}
                    handleDialogClose={handleCreateUserDialogClose}
                    teams={teams}
                    users={users}
                    createNewUser={createNewUser}
                />
            )}
            {isCreateTeamDialogOpen && (
                <CreateTeamDialog
                    isOpen={isCreateTeamDialogOpen}
                    handleDialogClose={handleCreateTeamDialogClose}
                    teamAdminUsers={users.filter(
                        (u) =>
                            u.userType === UserType.TeamAdmin &&
                            u.teamId == null
                    )}
                    users={users.filter(
                        (u) => u.userType === UserType.User && u.teamId == null
                    )}
                    teams={teams}
                    createNewTeam={createNewTeam}
                />
            )}
            {isDeleteUserDialogOpen && (
                <ConfirmDeleteDialog
                    isOpen={isDeleteUserDialogOpen}
                    handleDialogClose={handleDeleteUserDialogClose}
                    handleDelete={deleteSelectedUsers}
                >
                    <Typography variant="subtitle1">
                        Are you sure you want to delete these users?
                    </Typography>
                    {deleteUserErrorAlert && (
                        <Alert severity="error">Unable to delete user</Alert>
                    )}
                </ConfirmDeleteDialog>
            )}
            {isDeleteTeamDialogOpen && (
                <ConfirmDeleteDialog
                    isOpen={isDeleteTeamDialogOpen}
                    handleDialogClose={handleDeleteTeamDialogClose}
                    handleDelete={deleteSelectedTeams}
                >
                    <Typography variant="subtitle1">
                        Are you sure you want to delete these teams?
                    </Typography>
                    {deleteTeamErrorAlert && (
                        <Alert severity="error">Unable to delete team</Alert>
                    )}
                </ConfirmDeleteDialog>
            )}
            <Grid2
                pl={5}
                pr={5}
                pb={5}
                pt={2}
                container
                width="100%"
                height="100%"
            >
                <Grid2
                    border={0}
                    height="100%"
                    xs={showTeamManagementColumn ? 7.1 : 12}
                >
                    <Stack width="100%" height="100%">
                        <Stack direction="row">
                            <Box width="100%">
                                <Typography pl={1} variant="h5">
                                    <b>Users</b>
                                </Typography>
                            </Box>
                            <Box
                                width="100%"
                                justifyContent="flex-end"
                                display="flex"
                            >
                                <IconButton
                                    onClick={() =>
                                        setIsDeleteUserDialogOpen(true)
                                    }
                                    disabled={selectedUsers.length === 0}
                                >
                                    <Tooltip title="Delete">
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        setIsCreateUserDialogOpen(true)
                                    }
                                >
                                    <Tooltip title="Create User">
                                        <AddBoxIcon />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        </Stack>
                        <UsersTable
                            users={users}
                            teams={teams}
                            editUserTeam={editUserTeam}
                            setSelectedUsers={setSelectedUsers}
                        />
                    </Stack>
                </Grid2>
                {(currentUser.userType === UserType.OrganizationAdmin ||
                    currentUser.userType === UserType.SuperAdmin) && (
                    <React.Fragment>
                        <Grid2 xs={0.3} />
                        <Grid2 height="100%" xs={4.6}>
                            <Stack width="100%" height="48%">
                                <Stack direction="row">
                                    <Box width="100%">
                                        <Typography pl={1} variant="h5">
                                            <b>Teams</b>
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="100%"
                                        justifyContent="flex-end"
                                        display="flex"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                setIsDeleteTeamDialogOpen(true)
                                            }
                                            disabled={
                                                selectedTeams.length === 0
                                            }
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                setIsCreateTeamDialogOpen(true)
                                            }
                                        >
                                            <Tooltip title="Create Team">
                                                <AddBoxIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <TeamsTable
                                    teams={teams}
                                    setSelectedTeams={setSelectedTeams}
                                />
                            </Stack>
                            <Box width="100%" height="4%" />
                            <Stack
                                p={1}
                                width="100%"
                                height="48%"
                                border={1}
                                borderRadius={1}
                                borderColor="grey.500"
                                boxShadow={3}
                                display="flex"
                                alignItems="center"
                            >
                                <Typography variant="h5">
                                    <b>Users Per Team</b>
                                </Typography>

                                <TeamHorizontalBarGraph
                                    users={users}
                                    teams={teams}
                                />
                            </Stack>
                        </Grid2>
                    </React.Fragment>
                )}
            </Grid2>
        </BasePage>
    );
}
export default UserManagementPage;
