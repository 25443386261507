import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DialpadIcon from '@mui/icons-material/Dialpad';
import MessageIcon from '@mui/icons-material/Message';
import IconButton from '@mui/material/IconButton';

// Hammer Template
import BasePage from 'HammerTemplate/PageTemplates/BasePage';

// Usage Component
import UsageSummaryCard from 'Modules/Usage/Components/UsageSummaryCard';

// Models
import { Phone } from 'Modules/Models/Phone';

// Http
import { getAllCurrentUsersPhonesAsync } from 'Modules/Http/PhoneInterface';

function HomePage() {
    const navigate = useNavigate();

    const [userPhones, setUserPhones] = useState<Phone[]>([]);

    const phoneCardIcon = () => (
        <IconButton onClick={() => navigate('/manage/phoneData')}>
            <LocalPhoneIcon sx={{ color: 'black' }} />
        </IconButton>
    );
    const callCardIcon = () => (
        <IconButton onClick={() => navigate('/calls')}>
            <DialpadIcon sx={{ color: 'black' }} />
        </IconButton>
    );
    const messageCardIcon = () => (
        <IconButton onClick={() => navigate('/messages')}>
            <MessageIcon sx={{ color: 'black' }} />
        </IconButton>
    );

    function calcNumPhoneUnreadSms() {
        let count: number = 0;
        userPhones.forEach((phone) => {
            count += phone.unreadSmsCount;
        });
        return count;
    }
    function calcNumPhoneUnseenCalls() {
        let count: number = 0;
        userPhones.forEach((phone) => {
            count += phone.unseenPhoneCallsCount;
        });
        return count;
    }
    async function getPhonesAsync() {
        let res = await getAllCurrentUsersPhonesAsync();
        if (res != null) {
            setUserPhones(res);
        }
    }
    useEffect(() => {
        getPhonesAsync();
    }, []);

    return (
        <BasePage pageName="Home">
            <Box width="100" height="100%" pt="10%">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={10}
                >
                    <UsageSummaryCard
                        title="Phones"
                        Icon={phoneCardIcon}
                        entries={[
                            {
                                label: 'Available Phones',
                                value: userPhones.length,
                            },
                        ]}
                    ></UsageSummaryCard>
                    <UsageSummaryCard
                        title="Calls"
                        Icon={callCardIcon}
                        entries={[
                            {
                                label: 'Missed Calls',
                                value: calcNumPhoneUnseenCalls(),
                            },
                        ]}
                    ></UsageSummaryCard>
                    <UsageSummaryCard
                        title="Messages"
                        Icon={messageCardIcon}
                        entries={[
                            {
                                label: 'Unread Messages',
                                value: calcNumPhoneUnreadSms(),
                            },
                        ]}
                    ></UsageSummaryCard>
                </Stack>
            </Box>
        </BasePage>
    );
}

export default HomePage;
