import axios from 'axios';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Get system version number through GET /api/System/Version
 * @returns Version string
 */
async function getSystemVersionNumberAsync(): Promise<string> {
    let response = await FetchDataWrapperAsync<string>(() =>
        axios.get(`/api/System/Version`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return '';
}

export { getSystemVersionNumberAsync };
