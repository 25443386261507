import axios from 'axios';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Send a message through POST /api/Message/Send
 * @param message
 * @param senderPhone
 * @param receiverPhone
 * @returns True on success, false on error
 */
async function sendMessageAsync(
    message: string,
    senderPhone: string,
    receiverPhone: string
): Promise<number | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('message', message);
    searchParams.append('senderPhone', senderPhone);
    searchParams.append('receiverPhone', receiverPhone);
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.post(
            `/api/Message/Send?${searchParams.toString()}`,
            {},
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Delete a message through DELETE /api/Message
 * @param messageId
 * @returns True on success, false on error
 */
async function deleteMessageAsync(messageId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('messageId', messageId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/Message?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Read a message through PUT /api/Message
 * @param messageId
 * @returns True on success, false on error
 */
async function readMessageAsync(messageId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('messageId', messageId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.put(`/api/Message?${searchParams}`, {}, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

/**
 * Get Service Providers
 * Super Admin Only
 * @returns
 */
async function getServiceProviders(): Promise<string[]> {
    let response = await FetchDataWrapperAsync<string[]>(() =>
        axios.get(`/api/Message/ServiceProviders`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return [];
}
export {
    sendMessageAsync,
    deleteMessageAsync,
    readMessageAsync,
    getServiceProviders,
};
