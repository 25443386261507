import React, { useEffect, useState } from 'react';

// Material UI
import { ThemeOptions, experimental_sx as sx } from '@mui/material/styles';
// Material UI - Imports necessary for CSS and Default Prop Customization
// see: https://mui.com/material-ui/about-the-lab/#typescript
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

// Hammer Template
import HammerTemplate from 'HammerTemplate/HammerTemplate';

import pageSetGenerator from 'PageSetGenerator';

import AccountMenu from 'AccountMenu';

// SignalR
import SignalRProvider from 'HammerTemplate/CommonFunctions/SignalRProvider';

// User Context
import UserContextProvider, { UserContext } from 'Modules/UserContextProvider';

// Voice Context
import VoiceContextProvider, {
    CallStatus,
    VoiceContext,
} from 'Modules/VoiceContextProvider';

// Http
import { getSystemVersionNumberAsync } from 'Modules/Http/SystemInterface';

// Active Call Box
import ActiveCallBox from 'Modules/Calls/Components/ActiveCallBox';

// Common
import DocumentTitleHandler from 'Modules/Common/Components/DocumentTitleHandler';
import UnreadContentContextProvider from 'Modules/UnreadContentContextProvider';

export const themeOptions: ThemeOptions = {
    palette: {
        //type: 'light',
        primary: {
            main: '#3d9a8b',
        },
        secondary: {
            main: '#63c2d2',
        },
        neutral: {
            main: '#e3e3e3',
        },
    },
    typography: {
        fontFamily: 'Open Sans',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: sx({
                    borderColor: 'primary.light',
                }),
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                    '&.Mui-selected': {
                        backgroundColor: '#d0ecf1',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: sx({
                    boxShadow: 3,
                    border: 1,
                    borderColor: 'grey.500',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'neutral.main',
                    },
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: sx({
                    boxShadow: 3,
                    border: 1,
                    borderColor: 'primary.light',
                }),
            },
        },
        MuiFab: {
            styleOverrides: {
                root: sx({ zIndex: 30, boxShadow: 2 }),
            },
        },
    },
};

const SignalR_URL = '/hub';

function App() {
    const [version, setVersion] = useState<string>();
    useEffect(() => {
        async function updateVersion() {
            setVersion(await getSystemVersionNumberAsync());
        }
        updateVersion();
    }, []);
    return (
        <SignalRProvider url={SignalR_URL}>
            {/*
            React StrictMode is a tool for highlighting potential problems in an application
            It does cause everything to initially render twice during dev, it was remove below
            the signalR provider to prevent duplicate sockets being created.
            https://reactjs.org/docs/strict-mode.html
        */}
            <UserContextProvider>
                <UserContext.Consumer>
                    {(user) =>
                        user != null && (
                            <VoiceContextProvider>
                                <React.StrictMode>
                                    <VoiceContext.Consumer>
                                        {(voiceContext) => (
                                            <UnreadContentContextProvider>
                                                <HammerTemplate
                                                    themeOptions={themeOptions}
                                                    appTitle="Voice"
                                                    pageSetGenerator={() =>
                                                        pageSetGenerator(
                                                            user.userType
                                                        )
                                                    }
                                                    HeaderBarAddOn={() =>
                                                        AccountMenu(user)
                                                    }
                                                    sideBarProps={{
                                                        sideBarGroupProps: {
                                                            collapsibleGroups:
                                                                false,
                                                            defaultGroupsOpen:
                                                                true,
                                                        },
                                                        sideBarFixed: false,
                                                        defaultSideBarOpen:
                                                            true,
                                                    }}
                                                    version={version}
                                                    additionalComponents={
                                                        <React.Fragment>
                                                            {voiceContext.currentCallStatus !==
                                                                CallStatus.Standby && (
                                                                <ActiveCallBox />
                                                            )}
                                                            <DocumentTitleHandler />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </UnreadContentContextProvider>
                                        )}
                                    </VoiceContext.Consumer>
                                </React.StrictMode>
                            </VoiceContextProvider>
                        )
                    }
                </UserContext.Consumer>
            </UserContextProvider>
        </SignalRProvider>
    );
}
export default App;
