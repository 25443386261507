import React, { useContext } from 'react';

import RoundBoxNumDisplay from 'Modules/Common/Components/RoundBoxNumDisplay';
import { UnreadContentContext } from 'Modules/UnreadContentContextProvider';

function SideBarMessagesIndicator() {
    const unreadHandler = useContext(UnreadContentContext);
    return (
        <RoundBoxNumDisplay displayValue={unreadHandler.unreadSms} size={1.8} />
    );
}

export default SideBarMessagesIndicator;
