import axios from 'axios';

// Models
import SuperAdminGetPhones from 'Modules/Models/SuperAdminApi/SuperAdminGetPhones';
import { SuperAdminGetTestingPair } from 'Modules/Models/SuperAdminApi/SuperAdminGetTestingPair';
import { Organization } from 'Modules/Models/Organization';
import { OrganizationDto } from 'Modules/Models/SuperAdminApi/OrganizationDto';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

async function getAllPhonesSuperAdmin(): Promise<SuperAdminGetPhones[] | null> {
    let response = await FetchDataWrapperAsync<SuperAdminGetPhones[]>(() =>
        axios.get(`api/Admin/Phones`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

async function getAllOrganizationsDtoSuperAdmin(): Promise<OrganizationDto[]> {
    let response = await FetchDataWrapperAsync<OrganizationDto[]>(() =>
        axios.get(`api/Admin/OrganizationDto`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return [];
}
async function getAllOrganizationsSuperAdmin(): Promise<Organization[]> {
    let response = await FetchDataWrapperAsync<Organization[]>(() =>
        axios.get(`api/Admin/Organization`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return [];
}

async function postPhoneSuperAdmin(
    phoneNumber: string,
    networkProvider: string,
    organizationId: number | undefined,
    inboundSmsCapable: boolean,
    outboundSmsCapable: boolean,
    inboundVoiceCapable: boolean,
    outboundVoiceCapable: boolean
) {
    let searchParams = new URLSearchParams();

    searchParams.append('phoneNumber', phoneNumber);
    searchParams.append('networkProvider', networkProvider);
    if (organizationId) {
        searchParams.append('organizationId', organizationId?.toString());
    }
    searchParams.append('inboundSmsCapable', String(inboundSmsCapable));
    searchParams.append('outboundSmsCapable', String(outboundSmsCapable));
    searchParams.append('inboundVoiceCapable', String(inboundVoiceCapable));
    searchParams.append('outboundVoiceCapable', String(outboundVoiceCapable));

    let response = await FetchDataWrapperAsync<void>(() =>
        axios.post(`api/Admin/Phone?${searchParams}`, {}, getAuthHeader())
    );
    if (response?.error) {
        throw response.data;
    }
}

async function postOrganizationSuperAdmin(
    organizationName: string,
    adminName: string,
    adminPassword: string
) {
    let searchParams = new URLSearchParams();
    searchParams.append('organizationName', organizationName);
    searchParams.append('adminName', adminName);
    searchParams.append('adminPassword', adminPassword);

    let response = await FetchDataWrapperAsync<void>(() =>
        axios.post(
            `api/Admin/organization?${searchParams}`,
            {},
            getAuthHeader()
        )
    );
    if (response?.error) {
        throw response.data;
    }
}

async function deleteOrganizationSuperAdmin(organizationIds: number[]) {
    for (let organizationId of organizationIds) {
        let searchParams = new URLSearchParams();
        searchParams.append('organizationId', organizationId.toString());
        let response = await FetchDataWrapperAsync<void>(() =>
            axios.delete(
                `api/Admin/organization?${searchParams}`,
                getAuthHeader()
            )
        );
        if (response?.error) {
            throw response.data;
        }
    }
}

async function getTestingPairSuperAdmin(): Promise<
    SuperAdminGetTestingPair[] | null
> {
    let response = await FetchDataWrapperAsync<void>(() =>
        axios.get(`api/Admin/TestPhones`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

async function postTestingPairSuperAdmin(
    testPairName: string,
    senderPhoneId: number,
    receiverPhoneId: number
) {
    let searchParams = new URLSearchParams();
    searchParams.append('testPairName', testPairName);
    searchParams.append('senderPhoneId', senderPhoneId.toString());
    searchParams.append('receiverPhoneId', receiverPhoneId.toString());

    let response = await FetchDataWrapperAsync<void>(() =>
        axios.post(`api/Admin/TestPhones?${searchParams}`, {}, getAuthHeader())
    );
    if (response?.error) {
        throw response.data;
    }
}

async function deleteTestingPairSuperAdmin(testingPairIds: number[]) {
    for (let testingPairId of testingPairIds) {
        let searchParams = new URLSearchParams();
        searchParams.append('testPairToDelete', testingPairId.toString());
        let response = await FetchDataWrapperAsync<void>(() =>
            axios.delete(
                `api/Admin/TestPhones?${searchParams}`,
                getAuthHeader()
            )
        );
        if (response?.error) {
            throw response.data;
        }
    }
}

async function deletePhonesSuperAdmin(phoneIdArray: number[]): Promise<void> {
    for (let phoneId of phoneIdArray) {
        let searchParams = new URLSearchParams();
        searchParams.append('phoneId', phoneId.toString());

        let response = await FetchDataWrapperAsync<void>(() =>
            axios.delete(`api/Admin/Phone?${searchParams}`, getAuthHeader())
        );
        if (response?.error) {
            throw response.data;
        }
    }
}

async function updatePhoneOrganization(
    phoneId: number,
    organizationId?: number
): Promise<void> {
    let searchParams = new URLSearchParams();
    if (organizationId != null) {
        searchParams.append('organizationId', organizationId.toString());
    }
    searchParams.append('phoneId', phoneId.toString());

    await FetchDataWrapperAsync<void>(() =>
        axios.put(
            `api/Admin/Phone/Organization?${searchParams}`,
            {},
            getAuthHeader()
        )
    );
}

async function updatePhoneCapability(
    phoneId: number,
    inboundSmsCapable: boolean | null,
    outboundSmsCapable: boolean | null,
    inboundVoiceCapable: boolean | null,
    outboundVoiceCapable: boolean | null
): Promise<void> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', phoneId.toString());

    if (inboundSmsCapable != null) {
        searchParams.append('inboundSmsCapable', String(inboundSmsCapable));
    }
    if (outboundSmsCapable != null) {
        searchParams.append('outboundSmsCapable', String(outboundSmsCapable));
    }
    if (inboundVoiceCapable != null) {
        searchParams.append('inboundVoiceCapable', String(inboundVoiceCapable));
    }
    if (outboundVoiceCapable != null) {
        searchParams.append(
            'outboundVoiceCapable',
            String(outboundVoiceCapable)
        );
    }
    await FetchDataWrapperAsync<void>(() =>
        axios.put(
            `api/Admin/Phone/Capability?${searchParams}`,
            {},
            getAuthHeader()
        )
    );
}
export {
    getAllPhonesSuperAdmin,
    getAllOrganizationsSuperAdmin,
    deletePhonesSuperAdmin,
    postPhoneSuperAdmin,
    postOrganizationSuperAdmin,
    deleteOrganizationSuperAdmin,
    getTestingPairSuperAdmin,
    postTestingPairSuperAdmin,
    deleteTestingPairSuperAdmin,
    updatePhoneOrganization,
    getAllOrganizationsDtoSuperAdmin,
    updatePhoneCapability,
};
