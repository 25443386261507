import React, { useState } from 'react';

// Material UI
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Models
import { UserType } from 'Modules/Models/User';

type TableRoleSelectionAutocompleteProps = {
    defaultRole: UserType | null;
};
function TableRoleSelectionAutocomplete({
    defaultRole,
}: TableRoleSelectionAutocompleteProps) {
    const [role, setRole] = useState<UserType | null>(defaultRole);

    return (
        <Autocomplete<UserType, undefined, boolean, boolean>
            sx={{ width: '95%' }}
            disabled
            fullWidth
            value={role}
            filterOptions={(options) =>
                options.filter((o) => o.valueOf() !== UserType.SuperAdmin)
            }
            options={Object.values(UserType)}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            )}
        />
    );
}
export default TableRoleSelectionAutocomplete;
