import React, { ReactNode } from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export type CenterLabelHeaderProps = {
    children?: ReactNode;
};

function CenterLabelHeader({ children }: CenterLabelHeaderProps) {
    return (
        <Box sx={{ py: 1, width: '90%' }}>
            <Stack alignItems="center">{children}</Stack>
        </Box>
    );
}

export { CenterLabelHeader };
