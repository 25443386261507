import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type RoundBoxNumDisplayProps = {
    displayValue: number;
    size: number;
};
function RoundBoxNumDisplay({ displayValue, size }: RoundBoxNumDisplayProps) {
    return (
        <React.Fragment>
            {displayValue !== 0 && (
                <Box
                    component="span"
                    borderRadius="50%"
                    bgcolor="primary.main"
                    color="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: `${size}rem`,
                        height: `${size}rem`,
                    }}
                >
                    <Typography fontSize={`${size / 2}rem`}>
                        {displayValue}
                    </Typography>
                </Box>
            )}
        </React.Fragment>
    );
}

export default RoundBoxNumDisplay;
