import React, { useContext } from 'react';

// Material UI
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

// Models
import { Phone } from 'Modules/Models/Phone';
import RoundBoxNumDisplay from 'Modules/Common/Components/RoundBoxNumDisplay';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';
import { VoiceContext } from 'Modules/VoiceContextProvider';

type PhoneDataManagementListProps = {
    phones: Phone[];
    selectedPhone: Phone | null;
    selectNewPhone: (phone: Phone) => void;
    displayType?: 'call' | 'message' | 'none';
};
function PhoneDataManagementList({
    phones,
    selectedPhone,
    selectNewPhone,
    displayType = 'none',
}: PhoneDataManagementListProps) {
    const voiceContext = useContext(VoiceContext);
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        phone: Phone
    ) => {
        selectNewPhone(phone);
    };

    function handleDisplayNumberCalc(phone: Phone): number {
        switch (displayType) {
            case 'call':
                return phone.unseenPhoneCallsCount;
            case 'message':
                return phone.unreadSmsCount;
            default:
                return 0;
        }
    }
    return (
        <React.Fragment>
            {phones.length === 0 ? (
                <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                >
                    <Typography variant="h5">No phones available</Typography>
                    <PhoneDisabledIcon sx={{ fontSize: 45 }} />
                </Stack>
            ) : (
                <List>
                    {phones.map((phone) => (
                        <React.Fragment key={phone.id}>
                            <ListItemButton
                                dense
                                selected={selectedPhone?.id === phone.id}
                                onClick={(event) =>
                                    handleListItemClick(event, phone)
                                }
                            >
                                <ListItemText
                                    sx={{ userSelect: 'text' }}
                                    primary={
                                        <b>
                                            {phone.nickname.length === 0
                                                ? formatNumberInternational(
                                                      phone.phoneNumber
                                                  )
                                                : phone.nickname}
                                        </b>
                                    }
                                    primaryTypographyProps={{
                                        variant: 'subtitle1',
                                    }}
                                    secondary={formatNumberInternational(
                                        phone.phoneNumber
                                    )}
                                />
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={3}
                                >
                                    {voiceContext.currentPhoneInCall?.id ===
                                        phone.id && <PhoneInTalkIcon />}
                                    {displayType !== 'none' && (
                                        <RoundBoxNumDisplay
                                            displayValue={handleDisplayNumberCalc(
                                                phone
                                            )}
                                            size={1.7}
                                        />
                                    )}
                                </Stack>
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            )}
        </React.Fragment>
    );
}

export default PhoneDataManagementList;
