import { StatusCodes } from 'http-status-codes';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosResponse, AxiosError } from 'axios';

export type FetchWrapperDataResponse<T> = {
    error: boolean;
    statusCode: StatusCodes;
    data?: T;
} | null;

/**
 * Adds a Try catch around a data request
 * Designed to be used with Axios
 * @function
 * @category HTTP
 * @subcategory Helper
 * @param {int} status Expected Status Return value
 * @param {*} defaultValue Return error
 * @param {function} request the Request being called
 * @returns
 */

async function FetchDataWrapperAsync<T>(
    request: () => Promise<AxiosResponse<any, any>>
): Promise<FetchWrapperDataResponse<T>> {
    try {
        let response = await request();
        return {
            error: false,
            statusCode: response?.status,
            data: response?.data,
        };
    } catch (error: any | AxiosError) {
        if (axios.isAxiosError(error)) {
            return {
                error: true,
                statusCode:
                    error.response?.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
                data: error.response?.data,
            };
            // Access to config, request, and response
        } else {
            return {
                error: true,
                statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
            };
        }
    }
}

export default FetchDataWrapperAsync;
