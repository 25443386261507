import { useState, useEffect } from 'react';

import BasePage from 'HammerTemplate/PageTemplates/BasePage';

import {
    getTestingPairSuperAdmin,
    deleteTestingPairSuperAdmin,
} from 'Modules/Http/SuperAdminInterface';

import ConfirmDeleteDialog from 'Modules/Common/Components/ConfirmDeleteDialog';
import AddTestingPairDialog from 'Modules/SuperAdmin/Components/SuperAdminAddTestingPairDialog';
// Material UI
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

//Models
import { SuperAdminGetTestingPair } from 'Modules/Models/SuperAdminApi/SuperAdminGetTestingPair';

function SuperAdminTestingPairPage() {
    async function getAdminData() {
        let allTestingPair: SuperAdminGetTestingPair[] | null =
            await getTestingPairSuperAdmin();
        setRow(allTestingPair ? allTestingPair : []);
    }

    const [selectedTestPairIds, setSelectedTestPairIds] = useState<number[]>(
        []
    );
    const [rows, setRow] = useState<SuperAdminGetTestingPair[]>([]);
    const [isCreateTestPairDialogOpen, setIsCreateTestPairDialogOpen] =
        useState<boolean>(false);
    const [isDeleteTestPairDialogOpen, setIsDeleteTestPairDialogOpen] =
        useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        getAdminData();
    }, [isCreateTestPairDialogOpen, isDeleteTestPairDialogOpen]);

    const columns: GridColDef[] = [
        {
            field: 'testPairName',
            headerName: 'Testing Pair Name',
            flex: 0.3,
            width: 150,
        },
        {
            field: 'senderPhone.currentUser.userName',
            headerName: 'Sender Name',
            valueGetter: (params) => {
                return params.row?.senderPhone?.nickname;
            },
            flex: 0.3,
            width: 150,
        },
        {
            field: 'senderPhone',
            headerName: 'Sender Phone Number',
            valueGetter: (params) => {
                return params.row?.senderPhone?.phoneNumber;
            },
            flex: 0.3,
            width: 150,
        },
        {
            field: 'receiverPhone.currentUser.userName',
            headerName: 'Receiver Name',
            valueGetter: (params) => {
                return params.row?.receiverPhone?.nickname;
            },
            flex: 0.3,
            width: 150,
        },
        {
            field: 'receiverPhone.phoneNumber',
            headerName: 'Receiver Phone Number',
            valueGetter: (params) => {
                return params.row?.receiverPhone?.phoneNumber;
            },
            flex: 0.3,
            width: 150,
        },
    ];

    return (
        <BasePage pageName="Testing Pair Management">
            {isDeleteTestPairDialogOpen && (
                <ConfirmDeleteDialog
                    isOpen={isDeleteTestPairDialogOpen}
                    handleDialogClose={() =>
                        setIsDeleteTestPairDialogOpen(false)
                    }
                    handleDelete={async () => {
                        try {
                            await deleteTestingPairSuperAdmin(
                                selectedTestPairIds
                            );
                            setIsDeleteTestPairDialogOpen(false);
                        } catch (e: any) {
                            setErrorAlert(true);
                        }
                    }}
                >
                    <Typography variant="subtitle1">
                        Are you sure you want to delete these test pairs?
                    </Typography>
                    {errorAlert && (
                        <Alert severity="error">Unable to delete testing pair</Alert>
                    )}
                </ConfirmDeleteDialog>
            )}

            {isCreateTestPairDialogOpen && (
                <AddTestingPairDialog
                    isOpen={isCreateTestPairDialogOpen}
                    handleDialogClose={() => {
                        setIsCreateTestPairDialogOpen(false);
                    }}
                ></AddTestingPairDialog>
            )}
            <Box height="5%" width="100%" pr={5} mt={2}>
                <Stack
                    component="span"
                    justifyContent="flex-end"
                    direction="row"
                >
                    <IconButton
                        onClick={() => setIsDeleteTestPairDialogOpen(true)}
                        disabled={selectedTestPairIds.length === 0}
                    >
                        <Tooltip title="Delete">
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        onClick={() => setIsCreateTestPairDialogOpen(true)}
                    >
                        <Tooltip title="Create Test Pair">
                            <AddBoxIcon />
                        </Tooltip>
                    </IconButton>{' '}
                </Stack>
            </Box>
            <Box
                width="100%"
                height="95%"
                display="grid"
                pr={5}
                pl={5}
                gridTemplateRows="95%"
            >
                <Box height="100%" width="100%">
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={(newSelection) => {
                            setSelectedTestPairIds(newSelection as number[]);
                        }}
                    />
                </Box>
            </Box>
        </BasePage>
    );
}

export default SuperAdminTestingPairPage;
