import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import BasePage from 'HammerTemplate/PageTemplates/BasePage';
import PhoneBasedPage from 'Modules/Common/Components/PhoneBasedPage';

// Message components
import ConversationList from 'Modules/Messages/Components/ConversationList';
import MessageWindow from 'Modules/Messages/Components/MessageWindow';
import CreateConversation from 'Modules/Messages/Components/CreateConversation';

// Http
import { getAllCurrentUsersPhonesAsync } from 'Modules/Http/PhoneInterface';
import {
    getConversationMessagesAsync,
    getPhoneConversationsAsync,
    markAllConversationMessagesAsReadAsync,
    deleteConversationAsync,
} from 'Modules/Http/ConversationInterface';
import {
    deleteMessageAsync,
    readMessageAsync,
    sendMessageAsync,
} from 'Modules/Http/MessageInterface';

// SignalR
import { useSignalR } from 'HammerTemplate/CommonFunctions/SignalRProvider';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Conversation } from 'Modules/Models/Conversation';
import { Message, MessageStatus } from 'Modules/Models/Message';

// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Unread context
import { UnreadContentContext } from 'Modules/UnreadContentContextProvider';

// The default max amount of messages or conversations we fetch per request
const DEFAULT_NUM_TO_FETCH = 50;

function MessagesPage() {
    const state = useLocation().state;
    const unreadContext = useContext(UnreadContentContext);
    // Global phones
    const [phones, setPhones] = useState<Phone[]>([]);
    const [selectedPhone, setSelectedPhone] = useState<Phone | null>(null);

    // Global conversations
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [selectedConversationId, setSelectedConversationId] =
        useState<number>(0);

    //Global messages
    const [messages, setMessages] = useState<Message[]>([]);

    // Conversation Dialog
    const [isConversationDialogOpen, setIsConversationDialogOpen] =
        React.useState(false);
    const handleConversationDialogClickOpen = () => {
        setIsConversationDialogOpen(true);
    };
    const handleConversationDialogClose = () => {
        setIsConversationDialogOpen(false);
    };

    // Contact Dialog
    const [isContactDialogOpen, setIsContactDialogOpen] =
        useState<boolean>(false);

    const handleContactDialogClose = async () => {
        reloadConversations(false);
        setIsContactDialogOpen(false);
    };
    const handleContactDialogOpen = () => setIsContactDialogOpen(true);

    //
    //***** Phone logic* *****
    //
    // Load initial phones on page load
    useEffect(() => {
        const fetchPhones = async () => {
            let fetchPhones = await getAllCurrentUsersPhonesAsync();
            if (fetchPhones != null) {
                fetchPhones = fetchPhones.filter(
                    (p) => p.outboundSmsCapable || p.inboundSmsCapable
                );
                setPhones(fetchPhones);
                if (fetchPhones.length > 0) {
                    if (
                        state?.phoneId !== null &&
                        fetchPhones.find(
                            (phone) => phone?.id === state?.phoneId
                        )
                    ) {
                        selectNewPhone(state.phoneId);
                        setSelectedPhone(
                            fetchPhones.find(
                                (p) => p.id === state.phoneId
                            ) as Phone
                        );
                    } else {
                        selectNewPhone(fetchPhones[0].id);
                        setSelectedPhone(fetchPhones[0]);
                    }
                }
            }
        };
        fetchPhones();
        return () => {
            unreadContext.setSelectedConversationId(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Process for when a new phone is selected
    async function selectNewPhone(phoneId?: number | null) {
        if (phoneId == null) {
            return;
        }
        let fetchConversations = await getPhoneConversationsAsync(
            0,
            DEFAULT_NUM_TO_FETCH,
            phoneId
        );
        if (fetchConversations != null) {
            setConversations(fetchConversations);
            if (fetchConversations.length > 0) {
                await selectNewConversation(fetchConversations[0].id, false);
            } else {
                await selectNewConversation(0, false);
            }
        }
    }
    const reloadPhones = useCallback((resetSelected: boolean) => {
        // reset phones to first load
        const reloadPhones = async (resetSelected: boolean) => {
            let fetchPhones = await getAllCurrentUsersPhonesAsync();
            if (fetchPhones != null) {
                fetchPhones = fetchPhones.filter(
                    (p) => p.outboundSmsCapable || p.inboundSmsCapable
                );
                setPhones(fetchPhones);
                if (fetchPhones.length > 0 && resetSelected) {
                    setSelectedPhone(fetchPhones[0]);
                }
            }
        };
        reloadPhones(resetSelected);
    }, []);
    //
    // ***** Conversation logic *****
    //

    // function called every time a conversation selection is made
    const selectNewConversation = useCallback(
        async (conversationId: number, markAsRead: boolean) => {
            async function selectNewConversation(
                conversationId: number,
                markAsRead: boolean
            ) {
                unreadContext.setSelectedConversationId(conversationId);
                setSelectedConversationId(conversationId);
                if (markAsRead) {
                    await markAllConversationMessagesAsReadAsync(
                        conversationId
                    );
                    setConversationToRead(conversationId);
                    reloadPhones(false);
                }
                let fetchMessages = await getConversationMessagesAsync(
                    0,
                    DEFAULT_NUM_TO_FETCH,
                    conversationId
                );
                if (fetchMessages != null) {
                    setMessages(fetchMessages);
                }
            }
            await selectNewConversation(conversationId, markAsRead);
        },
        [reloadPhones, unreadContext]
    );

    // reset conversations to first load
    const reloadConversations = useCallback(
        (resetSelected: boolean) => {
            const reloadConversationsInsideCallback = async (
                resetSelected: boolean
            ) => {
                if (selectedPhone == null) {
                    return;
                }
                let fetchConversations = await getPhoneConversationsAsync(
                    0,
                    DEFAULT_NUM_TO_FETCH,
                    selectedPhone.id
                );
                if (fetchConversations != null) {
                    setConversations(fetchConversations);
                    if (fetchConversations.length > 0 && resetSelected) {
                        selectNewConversation(fetchConversations[0].id, false);
                    }
                }
            };
            reloadConversationsInsideCallback(resetSelected);
        },
        [selectNewConversation, selectedPhone]
    );

    // add additional conversations to list
    const fetchAdditionalConversations = async () => {
        if (selectedPhone == null) {
            return;
        }
        let fetchConversations = await getPhoneConversationsAsync(
            conversations.length,
            DEFAULT_NUM_TO_FETCH,
            selectedPhone.id
        );
        if (fetchConversations != null) {
            let newConversations = fetchConversations;
            setConversations((conversations) => [
                ...conversations,
                ...newConversations,
            ]);
        }
    };

    // function to handle when user clicks delete button on conversation
    async function deleteConversation(conversationId: number) {
        await deleteConversationAsync(conversationId);
        if (selectedConversationId === conversationId) {
            reloadConversations(true);
        } else {
            reloadConversations(false);
        }
    }

    // Modify a conversation to read in state
    function setConversationToRead(conversationId: number) {
        setConversations((current) =>
            current.map((obj) => {
                if (obj.id === conversationId) {
                    return { ...obj, unread: 0 };
                }
                return obj;
            })
        );
    }
    //
    // ***** Message logic *****
    //
    // Send an sms
    async function sendSmsMessageAsync(messageToSend: string) {
        if (selectedPhone == null || messageToSend.length === 0) {
            return;
        }
        let currentPhoneNumber = phones.find(
            (e) => e.id === selectedPhone.id
        )?.phoneNumber;
        let currentOtherParty = conversations.find(
            (e) => e.id === selectedConversationId
        )?.otherParty;
        if (
            currentPhoneNumber === undefined ||
            currentOtherParty === undefined
        ) {
            return;
        }
        let newMessage: Message = {
            id: -1,
            timestamp: new Date(),
            messageContent: messageToSend,
            isSender: true,
            phoneNumber: currentPhoneNumber,
            otherParty: currentOtherParty,
            isRead: true,
            isDeleted: false,
            messageStatus: MessageStatus.Sending,
            phoneId: -1,
        };
        setMessages((messages) => [newMessage, ...messages]);
        let Id = await sendMessageAsync(
            messageToSend,
            currentPhoneNumber,
            currentOtherParty
        );
        if (Id != null) {
            reloadPhones(false);
            selectNewPhone(selectedPhone.id);
        } else {
            selectNewPhone(selectedPhone.id);
        }
    }
    // Send a message from the start conversation dialog
    async function startConversationSendSmsAsync(
        messageToSend: string,
        senderPhone: Phone,
        receiverPhone: string
    ): Promise<boolean> {
        let Id = await sendMessageAsync(
            messageToSend,
            senderPhone.phoneNumber,
            receiverPhone
        );
        if (Id !== null) {
            reloadPhones(false);
            setSelectedPhone(senderPhone);
            selectNewPhone(senderPhone.id);
            handleConversationDialogClose();
            return true;
        }
        // Failed to send message
        else {
            return false;
        }
    }
    const reloadMessages = useCallback(() => {
        // reset messages to first load
        const reloadMessages = async () => {
            let fetchMessages = await getConversationMessagesAsync(
                0,
                DEFAULT_NUM_TO_FETCH,
                selectedConversationId
            );
            if (fetchMessages != null) {
                setMessages(fetchMessages);
            }
        };
        reloadMessages();
    }, [selectedConversationId]);

    // delete a message
    async function deleteMessage(messageId: number) {
        await deleteMessageAsync(messageId);
        reloadMessages();
    }

    // Subscribe to signalR messages
    const signalR: any = useSignalR();
    useEffect(() => {
        // function called on inboundSms signalR message
        async function InboundSms(message: Message) {
            if (message.conversationId === selectedConversationId) {
                await readMessageAsync(message.id);
                reloadMessages();
            }
            reloadPhones(false);
            reloadConversations(false);
        }

        // function called on smsDelivery signalR message
        async function SmsDelivery(messageId: number) {
            if (messages.find((m) => m.id === messageId) != null) {
                reloadMessages();
            }
        }
        // Keys for signalR messages
        const MessageHandlers = [
            {
                key: 'InboundSms',
                func: InboundSms,
            },
            {
                key: 'SmsDelivery',
                func: SmsDelivery,
            },
        ];
        let cleanup = MessageHandlers.map(
            (entry) => {
                return signalR.subscribe(entry.key, (message: any) =>
                    entry.func(message)
                );
            },
            [signalR]
        );
        return () => {
            cleanup.forEach((clean) => clean());
        };
    }, [
        signalR,
        messages,
        reloadConversations,
        reloadMessages,
        reloadPhones,
        selectedConversationId,
        unreadContext,
    ]);

    // When selected phone changes trigger select new phone
    useEffect(() => {
        selectNewPhone(selectedPhone?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPhone]);

    return (
        <BasePage pageName="Messages">
            {isConversationDialogOpen && selectedPhone != null && (
                <CreateConversation
                    open={isConversationDialogOpen}
                    handleClose={handleConversationDialogClose}
                    phones={phones}
                    defaultSelectedPhoneId={selectedPhone.id}
                    startConversationSendSmsAsync={
                        startConversationSendSmsAsync
                    }
                />
            )}
            <PhoneBasedPage
                inputPhones={phones}
                selectedPhone={selectedPhone}
                setSelectedPhone={setSelectedPhone}
                phoneHeaderWidth={0.25}
                displayType="message"
            >
                <Stack
                    width="100%"
                    height="100%"
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-evenly"
                    spacing={3}
                >
                    <Box border={1} borderRadius={1} width="35%">
                        <ConversationList
                            conversations={conversations}
                            fetchAdditionalConversations={
                                fetchAdditionalConversations
                            }
                            deleteConversation={deleteConversation}
                            selectedConversationIndex={selectedConversationId}
                            selectNewConversation={selectNewConversation}
                            handleConversationDialogClickOpen={
                                handleConversationDialogClickOpen
                            }
                            disabled={!selectedPhone?.outboundSmsCapable}
                        />
                    </Box>
                    <Box border={1} borderRadius={1} width="65%">
                        <MessageWindow
                            messages={messages}
                            selectedConversationId={selectedConversationId}
                            sendSmsMessageAsync={sendSmsMessageAsync}
                            deleteMessage={deleteMessage}
                            currentOtherParty={
                                conversations.find(
                                    (e) => e.id === selectedConversationId
                                )?.otherParty
                            }
                            selectedPhoneIndex={selectedPhone?.id}
                            contact={
                                conversations.find(
                                    (e) => e.id === selectedConversationId
                                )?.contact
                            }
                            isContactDialogOpen={isContactDialogOpen}
                            handleContactDialogOpen={handleContactDialogOpen}
                            handleContactDialogClose={handleContactDialogClose}
                            disableSendMessageView={
                                selectedConversationId === 0 ||
                                !selectedPhone?.outboundSmsCapable
                                    ? true
                                    : false
                            }
                        />
                    </Box>
                </Stack>
            </PhoneBasedPage>
        </BasePage>
    );
}
export { DEFAULT_NUM_TO_FETCH };
export default MessagesPage;
