import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

type SuperAdminTableToggleProps = {
    renderCellParams: GridRenderCellParams<any, any, any>;
    editPhoneAccess(phoneId: number, state: boolean): Promise<boolean>;
};

function SuperAdminTableToggle({
    renderCellParams,
    editPhoneAccess,
}: SuperAdminTableToggleProps) {
    const [toggleState, setToggleState] = useState<boolean>(
        renderCellParams.value
    );

    async function toggleChange(
        phoneId: number,
        changeTo: boolean
    ): Promise<boolean> {
        let newState: boolean = await editPhoneAccess(phoneId, changeTo);
        if (newState) {
            setToggleState(changeTo);
        }
        return true;
    }

    return (
        <Checkbox
            checked={toggleState}
            onClick={() => toggleChange(renderCellParams.row.id, !toggleState)}
        />
    );
}

export default SuperAdminTableToggle;
