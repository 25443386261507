import React, { useState } from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

// Common Components
import TitleWithExitButton from 'Modules/Common/Components/TitleWithExitButton';

// Models
import { User } from 'Modules/Models/User';
import { Team } from 'Modules/Models/Team';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type CreateTeamDialogProps = {
    isOpen: boolean;
    handleDialogClose(): void;
    teamAdminUsers: User[];
    users: User[];
    teams: Team[];
    createNewTeam(name: string, users: number[]): Promise<void>;
};
function CreateTeamDialog({
    isOpen,
    handleDialogClose,
    teamAdminUsers,
    users,
    teams,
    createNewTeam,
}: CreateTeamDialogProps) {
    const [teamName, setTeamName] = useState<string>('');
    const [selectedTeamAdmin, setSelectedTeamAdmin] = useState<User | null>(
        null
    );
    const [selectedUsersForTeam, setSelectedUsersForTeam] = useState<User[]>(
        []
    );
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    async function handleCreateButtonClick() {
        let userIds: number[] = [];
        if (selectedTeamAdmin != null) {
            userIds.push(selectedTeamAdmin.id);
        }
        userIds = userIds.concat(selectedUsersForTeam.map((u) => u.id));
        try {
            await createNewTeam(teamName, userIds);
        } catch (e: any) {
            setErrorAlert(true);
        }
    }
    function isValidTeamName(): boolean {
        if (teams.some((t) => t.name === teamName)) {
            return false;
        }
        return true;
    }
    function isCreateButtonDisabled(): boolean {
        if (teamName.length !== 0 && isValidTeamName()) {
            return false;
        }
        return true;
    }
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                <TitleWithExitButton
                    title="Create Team"
                    exitAction={handleDialogClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Stack width="100%" height="100%" gap={1.5}>
                    <TextField
                        value={teamName}
                        error={!isValidTeamName()}
                        helperText={
                            isValidTeamName() ? '' : 'Team name already taken'
                        }
                        onChange={(event) => setTeamName(event.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                        label="Team Name"
                    />
                    <Autocomplete<User, undefined, boolean, boolean>
                        fullWidth
                        options={teamAdminUsers}
                        value={selectedTeamAdmin}
                        onChange={(event, value) => {
                            if (typeof value !== 'string') {
                                setSelectedTeamAdmin(value);
                            }
                        }}
                        getOptionLabel={(option) => {
                            if (typeof option !== 'string') {
                                return option.username;
                            }
                            return option;
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField
                                label="Team Lead (optional)"
                                {...params}
                            />
                        )}
                    />
                    <Box display="inline-flex">
                        <Autocomplete<User, boolean, boolean, boolean>
                            fullWidth
                            multiple
                            limitTags={2}
                            disableCloseOnSelect
                            disabled={selectedTeamAdmin == null}
                            options={users}
                            value={selectedUsersForTeam}
                            onChange={(event, value) => {
                                if (typeof value !== 'string') {
                                    setSelectedUsersForTeam(value as User[]);
                                }
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option !== 'string') {
                                    return option.username;
                                }
                                return option;
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.username}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    label="Team Users (optional)"
                                    {...params}
                                />
                            )}
                        />
                    </Box>

                    {errorAlert && (
                        <Alert severity="error">Unable to add team</Alert>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                <Button
                    disabled={isCreateButtonDisabled()}
                    fullWidth
                    variant="contained"
                    onClick={handleCreateButtonClick}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default CreateTeamDialog;
