import React, { useContext, useEffect, useRef, useState } from 'react';

// Material UI
import Draggable from 'react-draggable';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import CallEndIcon from '@mui/icons-material/CallEnd';
import PhoneIcon from '@mui/icons-material/Phone';
import DialpadIcon from '@mui/icons-material/Dialpad';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TypographyVariant } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

// Context
import { VoiceContext, CallStatus } from 'Modules/VoiceContextProvider';

// Call Components
import DialPad from 'Modules/Calls/Components/DialPad';

// Common Components
import Timer from 'Modules/Common/Components/Timer';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

type OtherPartyViewProps = {
    primaryDisplayVariant: TypographyVariant;
    secondaryDisplayVariant: TypographyVariant;
};
function OtherPartyView({
    primaryDisplayVariant,
    secondaryDisplayVariant,
}: OtherPartyViewProps) {
    const phone = useContext(VoiceContext);
    return (
        <React.Fragment>
            {typeof phone.currentOtherParty === 'string' ? (
                <Typography variant={primaryDisplayVariant}>
                    {formatNumberInternational(phone.currentOtherParty)}
                </Typography>
            ) : (
                <Stack justifyContent="center" alignItems="center">
                    <Typography variant={primaryDisplayVariant}>
                        {phone.currentOtherParty?.name}
                    </Typography>
                    <Typography variant={secondaryDisplayVariant}>
                        {formatNumberInternational(
                            phone.currentOtherParty?.phoneNumber
                        )}
                    </Typography>
                </Stack>
            )}
        </React.Fragment>
    );
}

function RingingDisplay() {
    const phone = useContext(VoiceContext);
    return (
        <React.Fragment>
            <Stack direction="row" gap={3}>
                <Typography variant="h5">Ringing</Typography>
                <CircularProgress />
            </Stack>
            <Fab color="error" onClick={() => phone.hangup()}>
                <CallEndIcon fontSize="large" />
            </Fab>
        </React.Fragment>
    );
}
function IncomingCallDisplay() {
    const phone = useContext(VoiceContext);
    return (
        <React.Fragment>
            <Typography variant="h5">is calling you</Typography>
            <Stack
                height="100%"
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-around"
            >
                <Fab color="error" onClick={() => phone.rejectIncomingCall()}>
                    <CallEndIcon fontSize="large" />
                </Fab>

                <Box position="relative">
                    <Fab
                        color="success"
                        onClick={() => {
                            if (
                                phone.currentCallStatus !==
                                CallStatus.Connecting
                            ) {
                                phone.acceptIncomingCall();
                            }
                        }}
                    >
                        <PhoneIcon fontSize="large" />
                    </Fab>
                    {phone.currentCallStatus === CallStatus.Connecting && (
                        <CircularProgress
                            size={68}
                            sx={{
                                color: 'green',
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1,
                            }}
                        />
                    )}
                </Box>
            </Stack>
        </React.Fragment>
    );
}
type InCallDisplayProps = {
    toggleDisplayDialPad: boolean;
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
};
function InCallDisplay({
    toggleDisplayDialPad,
    counter,
    setCounter,
}: InCallDisplayProps) {
    const phone = useContext(VoiceContext);
    const [inCallInput, setInCallInput] = useState<string>('');
    return (
        <React.Fragment>
            <Typography variant="h5">
                <Timer counter={counter} setCounter={setCounter} />
            </Typography>
            <Stack width="65%" gap={3}>
                {toggleDisplayDialPad && (
                    <DialPad
                        placeCall={function placeCall() {}}
                        displayNumberFabs={toggleDisplayDialPad}
                        setDialedNumber={setInCallInput}
                        rowGap={1}
                    />
                )}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Fab color="error" onClick={() => phone.hangup()}>
                        <CallEndIcon fontSize="large" />
                    </Fab>
                    <Fab
                        onClick={() => phone.setMicMuteState(!phone.micEnabled)}
                    >
                        {!phone.micEnabled ? (
                            <MicOffIcon fontSize="large" />
                        ) : (
                            <MicIcon fontSize="large" />
                        )}
                    </Fab>
                </Stack>
            </Stack>
        </React.Fragment>
    );
}
function ActiveCallBox() {
    //Draggable Ref
    const nodeRef = useRef(null);

    // Docked Ref
    const dockedRef = useRef<HTMLDivElement>();

    // Phone Context
    const phone = useContext(VoiceContext);

    // Screen size
    const width: number = window.screen.width;
    const height: number = window.screen.height;

    const [displayDialPad, setDisplayDialPad] = useState<boolean>(false);
    const [isDocked, setIsDocked] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(0);
    const [dockedWidth, setDockedWidth] = useState(0);
    function toggleDisplayDialPad() {
        setDisplayDialPad((value) => !value);
    }

    useEffect(() => {
        if (dockedRef.current != null) {
            setDockedWidth(dockedRef.current.offsetWidth);
        }
    }, [isDocked]);
    return (
        <React.Fragment>
            {phone.currentPhoneInCall != null && (
                <React.Fragment>
                    {isDocked ? (
                        // Docked Call Box
                        <Stack
                            ref={dockedRef}
                            direction="row"
                            bgcolor="background.paper"
                            borderBottom={1}
                            borderLeft={1}
                            borderRight={1}
                            alignItems="center"
                            justifyContent="space-evenly"
                            pl={1}
                            pr={1}
                            gap={3}
                            sx={{
                                height: '64px', // height of navBar
                                position: 'absolute',
                                zIndex: 1300,
                                top: '0%',
                                right: `calc(50vw - ${dockedWidth / 2}px)`,
                                borderBottomRightRadius: 5,
                                borderBottomLeftRadius: 5,
                            }}
                        >
                            <Stack
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h5">
                                    {phone.currentPhoneInCall.nickname === ''
                                        ? formatNumberInternational(
                                              phone.currentPhoneInCall
                                                  .phoneNumber
                                          )
                                        : phone.currentPhoneInCall.nickname}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {phone.currentPhoneInCall.nickname === ''
                                        ? ''
                                        : formatNumberInternational(
                                              phone.currentPhoneInCall
                                                  .phoneNumber
                                          )}
                                </Typography>
                            </Stack>
                            <ArrowForwardIcon fontSize="large" />
                            <OtherPartyView
                                primaryDisplayVariant="h5"
                                secondaryDisplayVariant="subtitle1"
                            />
                            <Typography variant="h5">
                                <Timer
                                    counter={counter}
                                    setCounter={setCounter}
                                />
                            </Typography>
                            <Fab
                                size="small"
                                onClick={() =>
                                    phone.setMicMuteState(!phone.micEnabled)
                                }
                            >
                                {!phone.micEnabled ? (
                                    <MicOffIcon />
                                ) : (
                                    <MicIcon />
                                )}
                            </Fab>
                            <Fab
                                size="small"
                                color="error"
                                onClick={() => phone.hangup()}
                            >
                                <CallEndIcon />
                            </Fab>

                            <IconButton
                                onClick={() =>
                                    setIsDocked((isDocked) => !isDocked)
                                }
                            >
                                <OpenInNewIcon fontSize="medium" />
                            </IconButton>
                        </Stack>
                    ) : (
                        // Draggable Call Box
                        <Draggable bounds="parent" nodeRef={nodeRef}>
                            <Box
                                ref={nodeRef}
                                sx={{
                                    zIndex: 1300,
                                    position: 'absolute',
                                    right: width * 0.01,
                                    bottom: height * 0.01,
                                    width: '20rem',
                                }}
                            >
                                <Stack
                                    bgcolor="background.paper"
                                    width="100%"
                                    height="100%"
                                    border={1}
                                    borderRadius={2}
                                    boxShadow={10}
                                >
                                    <Box width="100%" borderBottom={1}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-around"
                                        >
                                            <Stack
                                                width="80%"
                                                height="100%"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                            >
                                                <Typography
                                                    variant="h5"
                                                    pt={
                                                        phone.currentPhoneInCall
                                                            .nickname === ''
                                                            ? 1
                                                            : 0
                                                    }
                                                    pb={
                                                        phone.currentPhoneInCall
                                                            .nickname === ''
                                                            ? 1
                                                            : 0
                                                    }
                                                >
                                                    {phone.currentPhoneInCall
                                                        .nickname === ''
                                                        ? formatNumberInternational(
                                                              phone
                                                                  .currentPhoneInCall
                                                                  .phoneNumber
                                                          )
                                                        : phone
                                                              .currentPhoneInCall
                                                              .nickname}
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {phone.currentPhoneInCall
                                                        .nickname === ''
                                                        ? ''
                                                        : formatNumberInternational(
                                                              phone
                                                                  .currentPhoneInCall
                                                                  .phoneNumber
                                                          )}
                                                </Typography>
                                            </Stack>
                                            <Box width="10%" height="100%">
                                                {phone.currentCallStatus ===
                                                    CallStatus.CallInProgress && (
                                                    <IconButton
                                                        onClick={() =>
                                                            setIsDocked(
                                                                (isDocked) =>
                                                                    !isDocked
                                                            )
                                                        }
                                                    >
                                                        <VerticalAlignTopIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <IconButton
                                            disabled={
                                                phone.currentCallStatus !==
                                                CallStatus.CallInProgress
                                            }
                                            onClick={() =>
                                                toggleDisplayDialPad()
                                            }
                                        >
                                            <Tooltip title="Toggle Dialpad">
                                                {phone.currentCallStatus ===
                                                CallStatus.CallInProgress ? (
                                                    <DialpadIcon />
                                                ) : (
                                                    <React.Fragment />
                                                )}
                                            </Tooltip>
                                        </IconButton>
                                    </Box>
                                    <Stack
                                        mb={4}
                                        gap={3}
                                        width="100%"
                                        height="100%"
                                        alignItems="center"
                                    >
                                        <OtherPartyView
                                            primaryDisplayVariant="h4"
                                            secondaryDisplayVariant="subtitle1"
                                        />
                                        {phone.currentCallStatus ===
                                            CallStatus.Ringing && (
                                            <RingingDisplay />
                                        )}
                                        {phone.currentCallStatus ===
                                            CallStatus.CallInProgress && (
                                            <InCallDisplay
                                                toggleDisplayDialPad={
                                                    displayDialPad
                                                }
                                                counter={counter}
                                                setCounter={setCounter}
                                            />
                                        )}
                                        {(phone.currentCallStatus ===
                                            CallStatus.IncomingCall ||
                                            phone.currentCallStatus ===
                                                CallStatus.Connecting) && (
                                            <IncomingCallDisplay />
                                        )}
                                    </Stack>
                                </Stack>
                            </Box>
                        </Draggable>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
export default ActiveCallBox;
