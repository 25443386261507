import React, { useState, useEffect } from 'react';

// Material UI
import Autocomplete from '@mui/material/Autocomplete';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';

// Models
import { Team } from 'Modules/Models/Team';

// User Context
import { useUserContext } from 'Modules/UserContextProvider';
import { filterDownRoles } from 'Modules/Common/RoleFilter';
import { UserType } from 'Modules/Models/User';

type TableTeamSelectionAutocompleteProps = {
    teams: Team[];
    renderCellParams: GridRenderCellParams<any, any, any>;
    editUserTeam(userId: number, teamId?: number): Promise<boolean>;
};
function TableTeamSelectionAutocomplete({
    teams,
    renderCellParams,
    editUserTeam,
}: TableTeamSelectionAutocompleteProps) {
    const currentUser = useUserContext();
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(
        renderCellParams.row.currentTeam
    );

    async function autoCompleteChange(
        teamId: number | undefined,
        team: Team | null
    ) {
        let res = await editUserTeam(renderCellParams.row.id, teamId);
        if (res) {
            setSelectedTeam(team);
        }
    }
    useEffect(() => {
        setSelectedTeam(renderCellParams.row.currentTeam);
    }, [renderCellParams.row.currentTeam]);

    return (
        <Autocomplete<Team, undefined, boolean, boolean>
            sx={{ width: '95%' }}
            value={selectedTeam}
            disabled={
                (!filterDownRoles(currentUser.userType, 'lessThan').includes(
                    renderCellParams.row.userType
                ) &&
                    renderCellParams.row.id !== currentUser.id) ||
                currentUser.userType === UserType.TeamAdmin
            }
            options={teams}
            blurOnSelect
            onChange={(event, value) => {
                let teamId = null;
                if (typeof value == 'string') {
                    return;
                } else {
                    teamId = value?.id;
                }
                autoCompleteChange(teamId, value);
            }}
            getOptionLabel={(option) => {
                if (typeof option !== 'string') {
                    return option.name;
                }
                return option;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            )}
        />
    );
}
export default TableTeamSelectionAutocomplete;
