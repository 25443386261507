import React, { useState, useEffect } from 'react';

type TimerProps = {
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
};
function Timer({ counter, setCounter }: TimerProps) {
    const [timeDisplay, setTimeDisplay] = useState<string>('');

    useEffect(() => {
        setTimeout(() => setCounter(counter + 1), 1000);
        setTimeDisplay(
            new Date(counter * 1000).toISOString().substring(11, 19)
        );
    }, [counter, setCounter]);

    return <React.Fragment> {timeDisplay}</React.Fragment>;
}

export default Timer;
