import React, { useState, useEffect } from 'react';

// Template
import BasePage from 'HammerTemplate/PageTemplates/BasePage';

// Common Components
import PhoneBasedPage from 'Modules/Common/Components/PhoneBasedPage';

// Phone Management Components
import PhoneDetailWindow from 'Modules/Phone Management/Components/PhoneDetailWindow';

// Models
import { Phone } from 'Modules/Models/Phone';

// Http
import {
    getAllCurrentUsersPhonesAsync,
    updatePhoneNicknameAsync,
} from 'Modules/Http/PhoneInterface';

function PhoneDataPage() {
    const [selectedPhone, setSelectedPhone] = useState<Phone | null>(null);
    const [phones, setPhones] = useState<Phone[]>([]);

    async function fetchPhones(resetSelected: boolean) {
        let fetchPhones = await getAllCurrentUsersPhonesAsync();
        if (fetchPhones != null) {
            setPhones(fetchPhones);
            if (fetchPhones.length > 0 && resetSelected) {
                setSelectedPhone(fetchPhones[0]);
            }
        }
    }

    async function EditPhoneName(newPhoneName: string) {
        if (selectedPhone != null) {
            let res = await updatePhoneNicknameAsync(
                newPhoneName,
                selectedPhone.id
            );
            if (res) {
                fetchPhones(false);
            }
        }
    }

    useEffect(() => {
        fetchPhones(true);
    }, []);
    return (
        <BasePage pageName="Phone Data">
            <PhoneBasedPage
                phoneHeaderWidth={0.35}
                selectedPhone={selectedPhone}
                setSelectedPhone={setSelectedPhone}
                inputPhones={phones}
            >
                <PhoneDetailWindow
                    selectedPhone={selectedPhone}
                    EditPhoneName={EditPhoneName}
                />
            </PhoneBasedPage>
        </BasePage>
    );
}
export default PhoneDataPage;
