import parsePhoneNumber from 'libphonenumber-js';

function formatNumberInternational(phoneNumber?: string): string {
    if (phoneNumber == null) {
        return '';
    }
    if (phoneNumber.at(0) !== '+') {
        phoneNumber = '+' + phoneNumber;
    }
    let parsedNumber = parsePhoneNumber(phoneNumber);
    if (parsedNumber == null) {
        console.log('Error : Could not parse phone number');
        return phoneNumber;
    }

    return parsedNumber.formatInternational();
}

export { formatNumberInternational };
