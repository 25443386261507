import React from 'react';

// Material UI
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

// Common Functions
import { filterDownRoles } from 'Modules/Common/RoleFilter';

// User Management Components
import TableTeamSelectionAutocomplete from 'Modules/UserManagement/Components/TableTeamSelectionAutocomplete';
import TableRoleSelectionAutocomplete from 'Modules/UserManagement/Components/TableRoleSelectionAutocomplete';

// Models
import { User } from 'Modules/Models/User';
import { Team } from 'Modules/Models/Team';

// User Context
import { useUserContext } from 'Modules/UserContextProvider';
type UsersTableProps = {
    users: User[];
    teams: Team[];
    editUserTeam(userId: number, teamId?: number): Promise<boolean>;
    setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>;
};
function UsersTable({
    users,
    teams,
    editUserTeam,
    setSelectedUsers,
}: UsersTableProps) {
    const currentUser = useUserContext();
    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'User',
            minWidth: 200,
        },
        {
            field: 'teamName',
            headerName: 'Team',
            minWidth: 225,
            renderCell: (params) => {
                return (
                    <TableTeamSelectionAutocomplete
                        teams={teams}
                        renderCellParams={params}
                        editUserTeam={editUserTeam}
                    />
                );
            },
        },
        {
            field: 'userType',
            headerName: 'Role',
            minWidth: 230,
            renderCell: (params) => {
                return (
                    <TableRoleSelectionAutocomplete
                        defaultRole={params.row.userType}
                    />
                );
            },
        },
        {
            field: 'lastActivity',
            headerName: 'Last Activity',
            minWidth: 175,
        },
    ];
    return (
        <DataGridPro
            checkboxSelection
            disableSelectionOnClick
            columns={columns}
            rows={users}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'username', sort: 'asc' }],
                },
            }}
            isRowSelectable={(params: GridRowParams) =>
                params.row.username !== currentUser.username &&
                filterDownRoles(currentUser.userType, 'lessThan').includes(
                    params.row.userType
                )
            }
            onSelectionModelChange={(newSelection) => {
                setSelectedUsers(newSelection as number[]);
            }}
        />
    );
}
export default UsersTable;
