import axios from 'axios';

// Models
import { Phone } from 'Modules/Models/Phone';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets all phones of the current user through GET /api/Phone
 * @returns An array of phones or null if error
 */
async function getAllCurrentUsersPhonesAsync(): Promise<Phone[] | null> {
    let response = await FetchDataWrapperAsync<Phone[]>(() =>
        axios.get(`/api/Phone`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets all phones for the current users team GET /api/Phone/Team
 * @returns An array of phones or null if error
 */
async function getAllPhonesAssignedToTeamAsync(
    teamId?: number
): Promise<Phone[] | null> {
    let searchParams = new URLSearchParams();

    if (teamId) {
        searchParams.append('teamId', teamId.toString());
    }
    let response = await FetchDataWrapperAsync<Phone[]>(() =>
        axios.get(`/api/Phone/Team?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets all phones for the current users organization GET /api/Phone/Organization
 * @returns An array of phones or null if error
 */
async function getAllCurrentUsersOrganizationPhonesAsync(): Promise<
    Phone[] | null
> {
    let response = await FetchDataWrapperAsync<Phone[]>(() =>
        axios.get(`/api/Phone/Organization`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Updates a phones nickname through PUT /api/Phone/Name
 * @param nickname
 * @param phoneId
 * @returns True on success, false on error
 */
async function updatePhoneNicknameAsync(
    nickname: string,
    phoneId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('nickname', nickname.toString());
    searchParams.append('phoneId', phoneId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.put(`/api/Phone/Name?${searchParams}`, {}, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

/**
 * Updates a phones assigned team through PUT /api/Phone/Team
 * @param phoneId
 * @param teamId
 * @returns True on success, false on error
 */
async function updatePhoneTeamAsync(
    phoneId: number,
    teamId?: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', phoneId.toString());
    if (teamId != null) {
        searchParams.append('teamId', teamId.toString());
    }
    let response = await FetchDataWrapperAsync(() =>
        axios.put(`/api/Phone/Team?${searchParams}`, {}, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

/**
 * Updates a phones assigned user through PUT /api/Phone/User
 * @param phoneId
 * @param userId
 * @returns True on success, false on error
 */
async function updatePhoneUserAsync(
    phoneId: number,
    userId?: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', phoneId.toString());
    if (userId != null) {
        searchParams.append('userId', userId.toString());
    }
    let response = await FetchDataWrapperAsync(() =>
        axios.put(`/api/Phone/User?${searchParams}`, {}, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
export {
    getAllCurrentUsersPhonesAsync,
    getAllPhonesAssignedToTeamAsync,
    getAllCurrentUsersOrganizationPhonesAsync,
    updatePhoneTeamAsync,
    updatePhoneUserAsync,
    updatePhoneNicknameAsync,
};
