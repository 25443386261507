function downloadBlob(filename: string, blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
}

function downloadFile(filename: string, data: string) {
    downloadBlob(filename, new Blob([data]));
}

async function uploadFile({
    target,
}: React.ChangeEvent<HTMLInputElement>): Promise<string[]> {
    if (target.files == null) {
        return [];
    }
    try {
        let fileContent = await target.files[0].text();
        // Other Options for uploading files
        // const buffer = await target.files[0].arrayBuffer();
        // const fileContentStream = await target.files[0].stream();
        let name = target.files[0].name;
        return [name, fileContent];
    } catch (_e) {
        console.log('Failed To Upload File');
        return [];
    }
}

function handleCapture(callback: (fileName: string, content: string) => void) {
    return async (event: React.ChangeEvent<HTMLInputElement>) => {
        let [name, content] = await uploadFile(event);
        callback(name, content);
    };
}

function copyToClipboard(text: string) {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            console.log('done');
        })
        .catch((err) => {
            console.log(err);
        });
}

export { downloadFile, downloadBlob, copyToClipboard, handleCapture };
