import React from 'react';

// Material UI
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

// Common
import { DateToSortableString } from 'Modules/Common/DateTools';

// Models
import { Phone } from 'Modules/Models/Phone';
import { User } from 'Modules/Models/User';
import { Usage } from 'Modules/Models/Usage';

// Date Range
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

type ActivePhonesBarGraphProps = {
    active: number;
    total: number;
};
function ActivePhonesBarGraph({ active, total }: ActivePhonesBarGraphProps) {
    const theme = useTheme();
    const percentActive = active <= 0 ? 2 : (active / total) * 100;
    let graphColor = theme.palette.success;
    if (percentActive < 30) {
        graphColor = theme.palette.error;
    } else if (percentActive <= 70) {
        graphColor = theme.palette.warning;
    }
    return (
        <Box
            sx={{
                border: `1px solid black`,
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '80%',
                borderRadius: 0.5,
            }}
        >
            <Stack
                height="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
                sx={{ position: 'absolute' }}
            >
                <Typography
                    variant="inherit"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {active} / {total}
                </Typography>
            </Stack>
            <Box
                sx={{
                    height: '100%',
                    maxWidth: `${percentActive}%`,
                    backgroundColor: graphColor.light,
                }}
            />
        </Box>
    );
}

type TeamUsageTableProps = {
    phones: Phone[];
    teamUsers: User[];
    usage: Usage[];
    dateRange: DateRange<Dayjs>;
};
function TeamUsageTable({
    phones,
    teamUsers,
    usage,
    dateRange,
}: TeamUsageTableProps) {
    const columns: GridColDef[] = [
        {
            field: 'user',
            headerName: 'User',
            flex: 1,
        },
        {
            field: 'lastActive',
            headerName: 'Last Active',
            flex: 1,
        },
        {
            field: 'sms',
            headerName: 'Total SMS',
            flex: 1,
        },
        {
            field: 'minutes',
            headerName: 'Total Minutes',
            flex: 1,
        },
        {
            field: 'assignedPhones',
            headerName: 'Assigned Phones',
            flex: 1,
        },
        {
            field: 'activePhones',
            headerName: 'Active Phones',
            flex: 1,
        },
        {
            field: 'ActiveVsTotalPhones',
            headerName: 'Active / Total Phones',
            flex: 1,
            valueGetter: (params) =>
                params.row.assignedPhones > 0
                    ? params.row.activePhones / params.row.assignedPhones
                    : 0,
            renderCell: (params) => {
                return (
                    <ActivePhonesBarGraph
                        active={params.row.activePhones}
                        total={params.row.assignedPhones}
                    />
                );
            },
        },
    ];

    const getActivePhoneCount = (
        phones: Phone[],
        dateRange: DateRange<Dayjs>
    ) => {
        let phonesInDateRange = 0;
        for (let phone of phones) {
            if (phone.lastUsed) {
                let lastUsed = new Date(phone.lastUsed);
                let startDate = dateRange[0]
                    ? dateRange[0].toDate()
                    : new Date(0);
                let endDate = dateRange[1] ? dateRange[1].toDate() : new Date();
                if (lastUsed >= startDate && lastUsed <= endDate) {
                    phonesInDateRange++;
                }
            }
        }
        return phonesInDateRange;
    };
    let userUsageTableData = teamUsers.map((user, index) => ({
        id: index,
        user: user.username,
        lastActive: user.lastActivity
            ? DateToSortableString(new Date(user.lastActivity))
            : null,

        sms: usage.find((u) => u.usersId === user?.id)?.sumOfAllMessages,
        minutes: usage.find((u) => u.usersId === user?.id)?.sumOfAllCallMinutes,
        assignedPhones: phones.filter((phone) => phone.userId === user.id)
            .length,
        activePhones: getActivePhoneCount(
            phones.filter((phone) => phone.userId === user.id),
            dateRange
        ),
    }));
    return (
        <DataGridPro
            density="compact"
            disableSelectionOnClick
            columns={columns}
            rows={userUsageTableData}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'user', sort: 'asc' }],
                },
            }}
        />
    );
}
export default TeamUsageTable;
