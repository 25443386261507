import React, { useEffect, ReactNode, createContext, useContext } from 'react';
import { User } from 'Modules/Models/User';
import { getLoggedInUserAsync } from 'Modules/Http/UserInterface';

export const UserContext = createContext<User | null>(null);

export const useUserContext = () => {
    const userContext = useContext(UserContext);
    if (!userContext) {
        throw new Error('No user');
    }
    return userContext;
};

type UserContextProviderProps = {
    children: ReactNode;
};
function UserContextProvider({ children }: UserContextProviderProps) {
    const [user, setUser] = React.useState<User | null>(null);

    async function getUser() {
        setUser(await getLoggedInUserAsync());
    }
    useEffect(() => {
        getUser();
    }, []);
    return (
        <UserContext.Provider value={user}>
            {user == null ? null : children}
        </UserContext.Provider>
    );
}
export default UserContextProvider;
