// Material UI Components
import { styled, Theme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

type DrawerProps = {
    open: boolean;
};

/**
 * Creates a Custom Drawer Component
 * @component
 * @category Common
 * @subcategory UI
 * @param {*} openWidth Width in pixels When Open
 * @param {*} closedWidth Width in pixels when Closed
 * @returns
 */
function createCustomDrawer(openWidth: number, closedWidth: number) {
    const openedMixin = (theme: Theme) => ({
        minWidth: openWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    });

    const closedMixin = (theme: Theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: `calc(${theme.spacing(closedWidth)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(closedWidth)} + 1px)`,
        },
    });

    return styled(Paper, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<DrawerProps>(({ theme, open }) => ({
        flexShrink: 0,
        position: 'relative',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: '100%',
        background: theme?.palette?.neutral?.main,

        ...(open && {
            ...openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
        }),
    }));
}

export { createCustomDrawer };
