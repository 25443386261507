import React from 'react';
// Material UI
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
type TitleWithExitButtonProps = {
    title: string;
    exitAction(): void;
};
function TitleWithExitButton({ title, exitAction }: TitleWithExitButtonProps) {
    return (
        <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{title}</Box>
            <Box>
                <IconButton onClick={exitAction}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    );
}
export default TitleWithExitButton;
