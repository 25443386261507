import { useState } from 'react';

/**
 * Contains Logic for Handling Right Clicks
 * @function
 * @param {function} setTarget Callback used to get target of Click
 * @returns { handleClick, getPosition, close }
 */
function useRightClickState(setTarget: (target: Element) => void) {
    const [clickPosition, setClickPosition] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    function handleClick(event: MouseEvent | React.MouseEvent) {
        event.preventDefault();
        if (event.currentTarget && event.currentTarget instanceof Element) {
            setTarget(event.currentTarget);
            setClickPosition(
                clickPosition == null
                    ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                    : null
            );
        }
    }

    function getPosition() {
        return clickPosition;
    }

    function close() {
        setClickPosition(null);
    }

    return { handleClick, getPosition, close };
}

export { useRightClickState };
