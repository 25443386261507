import React, { ReactNode, useEffect, useState } from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/system/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';

//API Interface
import {
    getAllOrganizationsSuperAdmin,
    postPhoneSuperAdmin,
} from 'Modules/Http/SuperAdminInterface';
import { getServiceProviders } from 'Modules/Http/MessageInterface';

// Model
import { Organization } from 'Modules/Models/Organization';

// Common Components
import TitleWithExitButton from 'Modules/Common/Components/TitleWithExitButton';

type AddPhoneDialogProps = {
    children?: ReactNode;
    isOpen: boolean;
    handleDialogClose(): void;
};
function AddPhoneDialog({
    children,
    isOpen,
    handleDialogClose,
}: AddPhoneDialogProps) {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [phoneNumber, setPhoneNumber] = useState<string | null>();
    const [networkProvider, setNetworkProvider] = useState<string | null>(null);
    const [providers, setProviders] = useState<string[]>([]);
    const [organizationId, setOrganizationId] = useState<number | undefined>();
    const [inboundSmsCapable, setInboundSmsCapable] = useState<boolean>(false);
    const [outboundSmsCapable, setOutboundSmsCapable] =
        useState<boolean>(false);
    const [inboundVoiceCapable, setInboundVoiceCapable] =
        useState<boolean>(false);
    const [outboundVoiceCapable, setOutboundVoiceCapable] =
        useState<boolean>(false);
    async function fetchData() {
        setOrganizations(await getAllOrganizationsSuperAdmin());
        setProviders(await getServiceProviders());
    }
    const [errorAlert, setErrorAlert] = useState<boolean>(false);
    async function handleAdd() {
        try {
            if (phoneNumber && networkProvider) {
                await postPhoneSuperAdmin(
                    phoneNumber,
                    networkProvider,
                    organizationId,
                    inboundSmsCapable,
                    outboundSmsCapable,
                    inboundVoiceCapable,
                    outboundVoiceCapable
                );
                handleDialogClose();
            }
        } catch (e: any) {
            setErrorAlert(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                <TitleWithExitButton
                    title="Add Phone"
                    exitAction={handleDialogClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction={'column'} spacing={2}>
                    <TextField
                        label="Phone Number"
                        onChange={(value) => setPhoneNumber(value.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        options={providers}
                        renderInput={(params) => (
                            <TextField {...params} label="Network Provider" />
                        )}
                        onChange={(event, value: string | null) => {
                            setNetworkProvider(value);
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        options={organizations}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Assigned Organization"
                            />
                        )}
                        onChange={(event, value: Organization | null) =>
                            setOrganizationId(value?.id)
                        }
                    />

                    <Box sx={{ display: 'flex' }}>
                        <FormControl
                            sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Inbound SMS Capable"
                                    onChange={(event, newValue: boolean) => {
                                        setInboundSmsCapable(newValue);
                                    }}
                                />

                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Outbound SMS Capable"
                                    onChange={(event, newValue: boolean) => {
                                        setOutboundSmsCapable(newValue);
                                    }}
                                />

                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Inbound Voice Capable"
                                    onChange={(event, newValue: boolean) => {
                                        setInboundVoiceCapable(newValue);
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Outbound Voice Capable"
                                    onChange={(event, newValue: boolean) => {
                                        setOutboundVoiceCapable(newValue);
                                    }}
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    {errorAlert && (
                        <Alert severity="error">Unable to add phone</Alert>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleAdd();
                    }}
                    variant="contained"
                    disabled={phoneNumber && networkProvider ? false : true}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default AddPhoneDialog;
