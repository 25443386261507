import Keycloak from 'keycloak-js';

// KeyCloak Config from /public/keycloak.json
const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => any) => {
    _kc.init({
        onLoad: 'login-required',
    })
        .then((authenticated: any) => {
            if (!authenticated) {
                console.log('user is not authenticated..!');
            }
            onAuthenticatedCallback();
        })
        .catch(console.error);

    _kc.onTokenExpired = () => {
        console.log('token expired');
        _kc.updateToken(30)
            .then(() => {
                console.log('successfully get a new token', _kc.token);
            })
            .catch(() => {
                console.log('Error updating Token');
            });
    };
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => any) =>
    _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: string[]) =>
    roles.some((role: string) => _kc.hasRealmRole(role));

const AuthService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
};

export default AuthService;
