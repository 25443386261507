import React, { useState, useEffect, useRef } from 'react';

// Material UI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

// Hammer Template
import {
    downloadFile,
    handleCapture,
} from 'HammerTemplate/CommonFunctions/DownloadUpload';

// Message Components
import ContactDialog from 'Modules/Messages/Components/ContactDialog';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Contact } from 'Modules/Models/Contact';

// Http
import {
    getContactsAsync,
    deleteContactAsync,
    deleteAllPhoneContactsAsync,
    exportContactsAsync,
    importContactAsync,
} from 'Modules/Http/ContactInterface';

// Phone Management Components
import PhoneDetailTable from './PhoneDetailTable';
import Tooltip from '@mui/material/Tooltip';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

type PhoneDetailWindowProps = {
    selectedPhone: Phone | null;
    EditPhoneName(newPhoneName: string): Promise<void>;
};
function PhoneDetailWindow({
    selectedPhone,
    EditPhoneName,
}: PhoneDetailWindowProps) {
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [contactSearchFilter, setContactSearchFilter] = useState<string>('');
    const [phoneName, setPhoneName] = useState<string>(
        selectedPhone?.nickname ?? ''
    );
    const [editPhoneNameFieldProps, setEditPhoneNameFieldProps] = useState<{
        border: string;
        readOnly: boolean;
    }>({ border: 'none', readOnly: true });

    const [isContactDialogOpen, setIsContactDialogOpen] =
        useState<boolean>(false);

    const [contactToEdit, setContactToEdit] = useState<Contact | null>(null);

    let textInput = useRef<HTMLDivElement>(null);

    function handleContactDialogClose(updateContacts: boolean) {
        setIsContactDialogOpen(false);
        setContactToEdit(null);
        if (updateContacts) {
            getContacts();
        }
    }

    function handlePhoneNameEditDisplay(display: boolean) {
        if (display) {
            setEditPhoneNameFieldProps({ border: '', readOnly: false });
            if (textInput.current != null) {
                textInput.current.focus();
            }
        } else {
            setEditPhoneNameFieldProps({
                border: 'none',
                readOnly: true,
            });
        }
    }

    function phoneEditTextFieldOnEnterPress() {
        if (phoneName != null) {
            EditPhoneName(phoneName);
        }
        if (textInput.current != null) {
            textInput.current.blur();
        }
    }

    async function removeContactAsync(contactId: number) {
        let res = await deleteContactAsync(contactId);
        if (res) {
            const newContacts = contacts.filter((c) => c.id !== contactId);
            setContacts(newContacts);
        }
    }
    async function removeAllPhoneContactsAsync() {
        if (selectedPhone != null) {
            let res = await deleteAllPhoneContactsAsync(selectedPhone.id);
            if (res) {
                setContacts([]);
            }
        }
    }
    function filterContacts(): Contact[] {
        let filteredContacts = contacts.filter(
            (c) =>
                c.name
                    .toLowerCase()
                    .includes(contactSearchFilter.toLowerCase()) ||
                c.phoneNumber.includes(contactSearchFilter)
        );
        return filteredContacts;
    }
    async function exportContactsButtonPress() {
        let ids: number[] = filterContacts().map((c) => c.id);
        let res = await exportContactsAsync(ids);
        if (res != null) {
            downloadFile('contactsExport.csv', res);
        }
    }
    async function importContacts(fileContent: string) {
        if (selectedPhone == null) {
            return;
        }
        let res = await importContactAsync(fileContent, selectedPhone.id);
        if (res) {
            getContacts();
        }
    }
    function handleEditContact(contact: Contact) {
        setContactToEdit(contact);
        setIsContactDialogOpen(true);
    }
    async function getContacts() {
        if (selectedPhone != null) {
            let contacts = await getContactsAsync(selectedPhone.id);
            if (contacts != null) {
                setContacts(contacts);
            }
        }
    }
    useEffect(() => {
        getContacts();
        if (selectedPhone !== null) {
            setPhoneName(
                selectedPhone.nickname.length === 0
                    ? selectedPhone.phoneNumber + '*'
                    : selectedPhone.nickname
            );
        }
    }, [selectedPhone]);

    return (
        <React.Fragment>
            {selectedPhone == null ? (
                <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                >
                    <Typography variant="h5">No details available</Typography>
                    <Typography>Select a phone</Typography>
                </Stack>
            ) : (
                <Box width="100%" height="100%">
                    {isContactDialogOpen && (
                        <ContactDialog
                            isOpen={isContactDialogOpen}
                            handleContactDialogClose={handleContactDialogClose}
                            contact={contactToEdit}
                            selectedPhoneNumber={
                                contactToEdit?.phoneNumber ?? ''
                            }
                            selectedPhoneIndex={selectedPhone?.id}
                            disablePhoneNumberField={
                                contactToEdit ? true : false
                            }
                        />
                    )}
                    <Grid2 container width="100%" height="15%">
                        <Grid2 xs={12}>
                            <TextField
                                onChange={(input) => {
                                    setPhoneName(input.target.value);
                                }}
                                value={phoneName}
                                size="small"
                                variant="outlined"
                                inputProps={{
                                    style: {
                                        fontSize: '2rem',
                                    },
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        phoneEditTextFieldOnEnterPress();
                                    }
                                }}
                                onBlur={(event: any) => {
                                    handlePhoneNameEditDisplay(false);
                                    if (
                                        event.nativeEvent.sourceCapabilities !=
                                        null
                                    ) {
                                        if (selectedPhone != null) {
                                            setPhoneName(
                                                selectedPhone.nickname
                                                    .length === 0
                                                    ? selectedPhone.phoneNumber +
                                                          '*'
                                                    : selectedPhone.nickname
                                            );
                                        }
                                    }
                                }}
                                InputProps={{
                                    readOnly: editPhoneNameFieldProps.readOnly,
                                    startAdornment: (
                                        <IconButton
                                            onClick={() =>
                                                handlePhoneNameEditDisplay(true)
                                            }
                                            sx={{ color: 'black' }}
                                        >
                                            <EditIcon fontSize="large" />
                                        </IconButton>
                                    ),
                                }}
                                sx={{
                                    '& fieldset': {
                                        border: editPhoneNameFieldProps.border,
                                    },
                                }}
                                inputRef={textInput}
                            />
                        </Grid2>

                        <Grid2 xs={12}>
                            <Typography pl="6%" variant="h6">
                                {formatNumberInternational(
                                    selectedPhone?.phoneNumber
                                )}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2
                        container
                        width="90%"
                        height="85%"
                        boxShadow={1}
                        borderRadius={1}
                        margin="auto"
                    >
                        <Grid2
                            xs={12}
                            height="12%"
                            bgcolor="neutral.main"
                            borderTop={1}
                            borderRight={1}
                            borderLeft={1}
                            p={1}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                            }}
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                direction="row"
                                spacing={2}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box width="50%">
                                    <TextField
                                        onChange={(event) =>
                                            setContactSearchFilter(
                                                event.target.value
                                            )
                                        }
                                        value={contactSearchFilter}
                                        size="small"
                                        label="Search Contacts"
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: 1,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={() =>
                                                        setContactSearchFilter(
                                                            ''
                                                        )
                                                    }
                                                >
                                                    {contactSearchFilter.length >
                                                    0 ? (
                                                        <ClearIcon />
                                                    ) : (
                                                        ''
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Box>
                                <IconButton
                                    onClick={() => setIsContactDialogOpen(true)}
                                >
                                    <Tooltip title="Create Contact">
                                        <AddBoxIcon sx={{ color: 'black' }} />
                                    </Tooltip>
                                </IconButton>
                            </Stack>
                        </Grid2>

                        <Grid2 xs={12} height="78%">
                            <PhoneDetailTable
                                contacts={filterContacts()}
                                removeContactAsync={removeContactAsync}
                                handleEditContact={handleEditContact}
                            />
                        </Grid2>
                        <Grid2
                            height="10%"
                            xs={12}
                            borderLeft={1}
                            borderRight={1}
                            borderBottom={1}
                            p={1}
                            sx={{
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                            }}
                        >
                            <Stack
                                direction="row"
                                width="100%"
                                height="100%"
                                alignItems="center"
                            >
                                <Box width="100%">
                                    <Button
                                        variant="contained"
                                        onClick={removeAllPhoneContactsAsync}
                                    >
                                        Delete All
                                    </Button>
                                </Box>
                                <Box
                                    width="100%"
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                >
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        Import
                                        <input
                                            type="file"
                                            hidden
                                            onChange={handleCapture(
                                                (
                                                    fileName: string,
                                                    content: string
                                                ) => {
                                                    importContacts(content);
                                                }
                                            )}
                                            onClick={(event) => {
                                                (
                                                    event.target as HTMLInputElement
                                                ).value = '';
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={exportContactsButtonPress}
                                    >
                                        Export
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid2>
                    </Grid2>
                </Box>
            )}
        </React.Fragment>
    );
}
export default PhoneDetailWindow;
