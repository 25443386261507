import React, { useState } from 'react';

// Types
import { PageGroup } from 'HammerTemplate/HammerTemplateTypes';

// Routing
import { useNavigate, useLocation } from 'react-router-dom';

import { createCustomDrawer } from 'HammerTemplate/Layout/components/Drawer';

// Authentication
import AuthService from 'HammerTemplate/Authentication/AuthService';

// Material UI
import { styled, lighten } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

// Icons
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

const Drawer = createCustomDrawer(240, 0);

const StylizedList = styled(List, {
    shouldForwardProp: () => true,
})(({ theme }) => ({
    '& .MuiListItemButton-root.Mui-selected': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.5),
    },
})) as typeof List;

function SideBarGroup({
    pageGroup,
    collapsibleGroups = false,
    defaultOpen = true,
}: {
    pageGroup: PageGroup;
    collapsibleGroups?: boolean;
    defaultOpen?: boolean;
}) {
    const [open, setOpen] = useState<boolean>(
        collapsibleGroups ? defaultOpen : true
    );
    let navigate = useNavigate();
    let location = useLocation().pathname;

    const handleClick = () => {
        if ('pages' in pageGroup && collapsibleGroups) {
            setOpen(!open);
        }
        if ('page' in pageGroup) {
            navigate(pageGroup.route);
        }
    };

    let Icon = pageGroup.Icon;
    let Indicator = pageGroup.indicator;
    return (
        <React.Fragment key={pageGroup.title}>
            <ListItemButton
                dense
                onClick={handleClick}
                selected={
                    'page' in pageGroup && location.includes(pageGroup.route)
                }
            >
                {Icon && (
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                )}
                <ListItemText
                    primary={pageGroup.title}
                    primaryTypographyProps={{ variant: 'h6' }}
                />
                {'pages' in pageGroup &&
                    collapsibleGroups &&
                    (open ? <ExpandLess /> : <ExpandMore />)}
                {Indicator && <Indicator />}
            </ListItemButton>
            <Divider sx={{ marginX: '1rem' }} />
            {'pages' in pageGroup && (
                <Collapse in={open}>
                    <StylizedList component="div" disablePadding>
                        {pageGroup.pages.map((page) => {
                            let PageIcon = page.Icon;
                            return (
                                <ListItemButton
                                    selected={location.includes(
                                        pageGroup.route + page.route
                                    )}
                                    sx={{ pl: 4 }}
                                    key={page.title}
                                    onClick={() =>
                                        navigate(pageGroup.route + page.route)
                                    }
                                >
                                    {PageIcon && (
                                        <ListItemIcon>
                                            <PageIcon />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={page.title} />
                                </ListItemButton>
                            );
                        })}
                    </StylizedList>
                </Collapse>
            )}
        </React.Fragment>
    );
}

export type SideBarGroupProps = {
    collapsibleGroups?: boolean;
    defaultGroupsOpen?: boolean;
};

function SideBar({
    drawerOpen,
    pageGroups,
    sideBarGroupProps,
    version,
}: {
    drawerOpen: boolean;
    pageGroups: PageGroup[];
    sideBarGroupProps?: SideBarGroupProps;
    version?: string;
}) {
    return (
        <Drawer open={drawerOpen}>
            <Stack
                sx={{
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                }}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <StylizedList sx={{ width: '100%', overflow: 'auto' }}>
                    {pageGroups.map((group) => (
                        <SideBarGroup
                            key={group.route}
                            pageGroup={group}
                            collapsibleGroups={
                                sideBarGroupProps?.collapsibleGroups
                            }
                            defaultOpen={sideBarGroupProps?.defaultGroupsOpen}
                        />
                    ))}
                </StylizedList>
                <Stack sx={{ width: '100%' }}>
                    <Divider />
                    <Button
                        fullWidth
                        color="inherit"
                        size="large"
                        variant="text"
                        endIcon={<LogoutIcon />}
                        onClick={() => AuthService.doLogout()}
                    >
                        Sign Out
                    </Button>
                    {version && (
                        <React.Fragment>
                            <Divider />
                            <Typography variant="caption" align="center">
                                Version: {version}
                            </Typography>
                        </React.Fragment>
                    )}
                </Stack>
            </Stack>
        </Drawer>
    );
}

export default SideBar;
