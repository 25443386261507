import React from 'react';

// Routing
import { useNavigate } from 'react-router';

// Material UI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// Icons
import MenuIcon from '@mui/icons-material/Menu';

function HeaderBar({
    toggleDrawer,
    appTitle,
    HeaderBarAddOn,
}: {
    toggleDrawer?: () => void;
    appTitle: string;
    HeaderBarAddOn: React.FC;
}) {
    let navigate = useNavigate();
    return (
        <AppBar
            position="sticky"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar>
                {toggleDrawer && (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Typography
                    variant="h4"
                    component="div"
                    onClick={() => navigate('/')}
                >
                    {appTitle}
                </Typography>
                <Box sx={{ flexGrow: 1, paddingLeft: '3rem' }}>
                    <HeaderBarAddOn />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
HeaderBar.defaultProps = {
    HeaderBarAddOn: React.Fragment,
};

export default HeaderBar;
