import React, { useState } from 'react';

// Material UI
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Models
import { Team } from 'Modules/Models/Team';

type TableTeamsAutocompleteProps = {
    teams: Team[];
    renderCellParams: GridRenderCellParams<any, any, any>;
    editPhoneAssignedTeam(phoneId: number, userId?: number): Promise<boolean>;
};
function TableTeamsAutocomplete({
    teams,
    renderCellParams,
    editPhoneAssignedTeam,
}: TableTeamsAutocompleteProps) {
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(
        renderCellParams.row.currentTeam
    );
    async function autoCompleteChange(
        teamId: number | undefined,
        team: Team | null
    ) {
        let res = await editPhoneAssignedTeam(
            parseInt(renderCellParams.row.id),
            teamId
        );
        if (res) {
            setSelectedTeam(team);
        }
    }
    return (
        <Autocomplete<Team, undefined, boolean, boolean>
            sx={{ width: '95%' }}
            value={selectedTeam}
            options={teams}
            blurOnSelect
            onChange={(event, value) => {
                let userId = null;
                if (typeof value == 'string') {
                    return;
                } else {
                    userId = value?.id;
                }
                autoCompleteChange(userId, value);
            }}
            getOptionLabel={(option) => {
                if (typeof option !== 'string') {
                    return option.name;
                }
                return option;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            )}
        />
    );
}
export default TableTeamsAutocomplete;
