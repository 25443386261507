import React, { ReactNode, useState } from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/system/Stack';
import Alert from '@mui/material/Alert';

//API Interface
import { postOrganizationSuperAdmin } from 'Modules/Http/SuperAdminInterface';

// Common Components
import TitleWithExitButton from 'Modules/Common/Components/TitleWithExitButton';

type AddPhoneDialogProps = {
    children?: ReactNode;
    isOpen: boolean;
    handleDialogClose(): void;
};
function AddOrganizationDialog({
    children,
    isOpen,
    handleDialogClose,
}: AddPhoneDialogProps) {
    const [organizationName, setOrganizationName] = useState<
        String | undefined
    >();
    const [adminName, setAdminName] = useState<String | undefined>();
    const [adminPassword, setAdminPassword] = useState<String | undefined>();
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    async function handleAdd() {
        try {
            await postOrganizationSuperAdmin(
                String(organizationName),
                String(adminName),
                String(adminPassword)
            );
            handleDialogClose();
        } catch (e: any) {
            setErrorAlert(true);
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                <TitleWithExitButton
                    title="Add Organization"
                    exitAction={handleDialogClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction={'column'} spacing={2}>
                    <TextField
                        label="Organization Name"
                        onChange={(value) =>
                            setOrganizationName(value.target.value)
                        }
                    />
                    <TextField
                        label="Admin Name"
                        onChange={(value) => setAdminName(value.target.value)}
                    />
                    <TextField
                        label="Admin Password"
                        onChange={(value) =>
                            setAdminPassword(value.target.value)
                        }
                        type="password"
                    />
                    {errorAlert && (
                        <Alert severity="error">
                            Unable to add organization
                        </Alert>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleAdd();
                    }}
                    variant="contained"
                    disabled={
                        organizationName &&
                        adminName &&
                        adminPassword &&
                        organizationName.length > 0 &&
                        adminName.length > 0 &&
                        adminPassword.length > 0
                            ? false
                            : true
                    }
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default AddOrganizationDialog;
