import React, { useState } from 'react';

// Hammer Template
import { useRightClickState } from 'HammerTemplate/CommonFunctions/RightClick';

// Models
import { Message, MessageStatus } from 'Modules/Models/Message';

// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';

type MessageDisplayProps = {
    message: Message;
    deleteMessage: (messageId: number) => Promise<void>;
};
function MessageDisplay({ message, deleteMessage }: MessageDisplayProps) {
    function messageStatusHandler(messageStatus: MessageStatus) {
        if (messageStatus === MessageStatus.Delivered) {
            return (
                <Tooltip title="Delivered">
                    <DoneIcon sx={{ fontSize: 15 }} />
                </Tooltip>
            );
        }
        if (messageStatus === MessageStatus.Sent) {
            return (
                <Tooltip title="Sent">
                    <KeyboardDoubleArrowRightIcon sx={{ fontSize: 15 }} />
                </Tooltip>
            );
        }
        if (messageStatus === MessageStatus.Sending) {
            return (
                <Tooltip title="Sending">
                    <CircularProgress size={13} color="inherit" />
                </Tooltip>
            );
        }
        if (messageStatus === MessageStatus.FailedToSend) {
            return (
                <Tooltip title="Failed">
                    <ErrorOutlineIcon sx={{ fontSize: 15 }} />
                </Tooltip>
            );
        } else {
            return null;
        }
    }
    // Format date for display
    function dateFormatHandler(date: Date): string {
        let newDate = new Date(date.toString());
        return newDate.toLocaleString();
    }
    // Right click on message
    const [clickPosition, setClickPosition] = useState<Element | null>(null);
    const {
        handleClick,
        getPosition,
        close: closeContextMenu,
    } = useRightClickState(setClickPosition);
    const [rightClickedMessage, setRightClickedMessage] = useState<number>(-1);

    const position = getPosition();
    return (
        <Grid
            container
            justifyContent={message.isSender ? 'flex-end' : 'flex-start'}
            sx={{ marginBottom: 1 }}
        >
            <Box
                onContextMenu={(event) => {
                    handleClick(event);
                    setRightClickedMessage(message.id);
                }}
                sx={{
                    color: 'white',
                    bgcolor: message.isSender ? '#1982FC' : 'neutral.main',
                    maxWidth: 400,
                    p: 0.9,
                    boxShadow: 4,
                    borderRadius: '18px',
                    alignItems: 'right',
                    wordWrap: 'break-word',
                }}
            >
                <Menu
                    anchorReference="anchorPosition"
                    open={position != null}
                    onClose={closeContextMenu}
                    anchorPosition={
                        position !== null
                            ? {
                                  top: position.mouseY,
                                  left: position.mouseX,
                              }
                            : undefined
                    }
                >
                    <MenuItem
                        onClick={() => {
                            deleteMessage(rightClickedMessage);
                            closeContextMenu();
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        Delete
                    </MenuItem>
                </Menu>
                {!message.isDeleted ? (
                    <Typography color={message.isSender ? 'white' : 'black'}>
                        {message.messageContent}
                    </Typography>
                ) : (
                    <Typography
                        fontSize={12}
                        fontStyle="italic"
                        color={message.isSender ? 'white' : 'black'}
                    >
                        {message.messageContent}
                    </Typography>
                )}
                <Box>
                    <Stack
                        direction="row"
                        sx={message.isSender ? { float: 'right' } : {}}
                    >
                        <Typography
                            fontSize={10}
                            color={message.isSender ? 'white' : 'black'}
                        >
                            {dateFormatHandler(message.timestamp)}
                        </Typography>
                        {messageStatusHandler(message.messageStatus)}
                    </Stack>
                </Box>
            </Box>
        </Grid>
    );
}
export default MessageDisplay;
