import React, { createContext, ReactNode, useEffect, useState } from 'react';

// SignalR
import { useSignalR } from 'HammerTemplate/CommonFunctions/SignalRProvider';

// Models
import { Message } from 'Modules/Models/Message';
import { Call, CallType } from 'Modules/Models/Call';

// Http
import { getAllCurrentUsersPhonesAsync } from 'Modules/Http/PhoneInterface';

type UnreadContentContextType = {
    unreadSms: number;
    /**Sets the conversationId the user is on. 0 means either no conversation is selected or the message page is not loaded */
    setSelectedConversationId: React.Dispatch<React.SetStateAction<number>>;
    missedCalls: number;
    /**Sets the selectedPhoneId of the calls page. 0 means either no phone is selected or the phone page is not loaded */
    setSelectedPhonePageId: React.Dispatch<React.SetStateAction<number>>;
};

export const UnreadContentContext = createContext<UnreadContentContextType>(
    {} as UnreadContentContextType
);

type UnreadContentContextProviderProps = {
    children: ReactNode;
};

function UnreadContentContextProvider({
    children,
}: UnreadContentContextProviderProps) {
    const [unreadSms, setUnreadSms] = useState<number>(0);
    const [missedCalls, setMissedCalls] = useState<number>(0);
    const [selectedConversationId, setSelectedConversationId] =
        useState<number>(0);
    const [selectedPhonePageId, setSelectedPhonePageId] = useState<number>(0);

    async function setUnreadCounts() {
        let phones = await getAllCurrentUsersPhonesAsync();
        if (phones != null) {
            let unreadSmsCount: number = 0;
            let missedCallCount: number = 0;
            phones.forEach((phone) => {
                unreadSmsCount += phone.unreadSmsCount;
                missedCallCount += phone.unseenPhoneCallsCount;
            });
            setUnreadSms(unreadSmsCount);
            setMissedCalls(missedCallCount);
        }
    }
    useEffect(() => {
        setUnreadCounts();
    }, []);
    const signalR: any = useSignalR();
    useEffect(() => {
        async function MessagesRead(numUnreadSms: number) {
            setUnreadSms(numUnreadSms);
        }
        async function InboundSms(message: Message) {
            if (selectedConversationId !== message.conversationId) {
                setUnreadSms((numUnreadSms) => numUnreadSms + 1);
            }
        }

        async function CallsSeen(numUnseenCalls: number) {
            setMissedCalls(numUnseenCalls);
        }
        async function NewCDR(call: Call) {
            if (
                selectedPhonePageId !== call.phoneId &&
                call.callType === CallType.Missed
            ) {
                setMissedCalls((numMissedCalls) => numMissedCalls + 1);
            }
        }
        // Keys for signalR messages
        const MessageHandlers = [
            {
                key: 'MessagesRead',
                func: MessagesRead,
            },
            {
                key: 'InboundSms',
                func: InboundSms,
            },
            {
                key: 'CallsSeen',
                func: CallsSeen,
            },
            {
                key: 'NewCDR',
                func: NewCDR,
            },
        ];
        let cleanup = MessageHandlers.map(
            (entry) => {
                return signalR.subscribe(entry.key, (message: any) =>
                    entry.func(message)
                );
            },
            [signalR]
        );
        return () => {
            cleanup.forEach((clean) => clean());
        };
    }, [signalR, selectedConversationId, selectedPhonePageId]);
    return (
        <UnreadContentContext.Provider
            value={{
                unreadSms,
                setSelectedConversationId,
                missedCalls,
                setSelectedPhonePageId,
            }}
        >
            {children}
        </UnreadContentContext.Provider>
    );
}
export default UnreadContentContextProvider;
