import React, { useCallback, useContext, useEffect } from 'react';

// Voice Context
import { CallStatus, VoiceContext } from 'Modules/VoiceContextProvider';

// Unread Context
import { UnreadContentContext } from 'Modules/UnreadContentContextProvider';

const TITLE = document.title;
const INCOMING_CALL_STRING = 'Incoming Call!';

function DocumentTitleHandler() {
    const voiceContext = useContext(VoiceContext);
    const unreadContext = useContext(UnreadContentContext);

    const handleSmsCountDisplay = useCallback(() => {
        if (unreadContext.unreadSms !== 0) {
            return `(${unreadContext.unreadSms})`;
        } else {
            return '';
        }
    }, [unreadContext.unreadSms]);

    useEffect(() => {
        document.title = TITLE + handleSmsCountDisplay();
    }, [unreadContext.unreadSms, handleSmsCountDisplay]);

    useEffect(() => {
        if (voiceContext.currentCallStatus === CallStatus.IncomingCall) {
            const interval = setInterval(() => {
                if (document.title !== INCOMING_CALL_STRING) {
                    document.title = INCOMING_CALL_STRING;
                } else {
                    document.title = TITLE + handleSmsCountDisplay();
                }
            }, 1000);
            return () => {
                document.title = TITLE + handleSmsCountDisplay();
                clearInterval(interval);
            };
        }
    }, [voiceContext.currentCallStatus, handleSmsCountDisplay]);

    return <React.Fragment />;
}
export default DocumentTitleHandler;
