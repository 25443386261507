import React, { useContext } from 'react';

// Material UI
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Fab from '@mui/material/Fab';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Typography from '@mui/material/Typography';
import CallEndIcon from '@mui/icons-material/CallEnd';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Context
import { CallStatus, VoiceContext } from 'Modules/VoiceContextProvider';

function BlankFab() {
    return (
        <Fab
            disabled
            sx={{
                '&.Mui-disabled': {
                    background: 'transparent',
                },
            }}
        />
    );
}

type NumberFabProps = {
    displayValue: number | string;
    altDisplayValue?: number | string;
    updateDialedNumber(newInput: number | string): void;
};
function NumberFab({
    displayValue,
    altDisplayValue = '',
    updateDialedNumber,
}: NumberFabProps) {
    return (
        <Fab color="inherit" onClick={() => updateDialedNumber(displayValue)}>
            <Stack width="100%" height="100%" justifyContent="center">
                <Box height="40%">
                    <Typography variant="h5">{displayValue}</Typography>
                </Box>
                <Box height="40%">
                    <Typography fontSize={10} variant="caption">
                        {altDisplayValue}
                    </Typography>
                </Box>
            </Stack>
        </Fab>
    );
}
type DialPadProps = {
    placeCall(): void;
    mode?: 'call';
    displayNumberFabs?: boolean;
    setDialedNumber: React.Dispatch<React.SetStateAction<string>>;
    disabled?: boolean;
    rowGap?: number;
};
function DialPad({
    placeCall,
    mode,
    displayNumberFabs = true,
    setDialedNumber,
    disabled = false,
    rowGap = 0,
}: DialPadProps) {
    const phone = useContext(VoiceContext);
    function updateDialedNumber(newInput: number | string) {
        setDialedNumber((dialedNumber) => dialedNumber + newInput);
    }
    function handleCallButtonClick() {
        placeCall();
        setDialedNumber('');
    }
    const callMode = (
        <React.Fragment>
            <BlankFab />
            <Fab
                disabled={
                    phone.currentCallStatus !== CallStatus.Standby || disabled
                }
                color="success"
                onClick={handleCallButtonClick}
            >
                <LocalPhoneIcon fontSize="large" />
            </Fab>
            <Fab
                color="inherit"
                onClick={() =>
                    setDialedNumber((dialedNumber) => dialedNumber.slice(0, -1))
                }
            >
                <BackspaceIcon />
            </Fab>
        </React.Fragment>
    );
    return (
        <Grid2
            container
            width="100%"
            height="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            rowGap={rowGap}
        >
            {displayNumberFabs && (
                <React.Fragment>
                    <Grid2
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <NumberFab
                            displayValue={1}
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={2}
                            altDisplayValue="abc"
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={3}
                            altDisplayValue="def"
                            updateDialedNumber={updateDialedNumber}
                        />
                    </Grid2>
                    <Grid2
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <NumberFab
                            displayValue={4}
                            altDisplayValue="ghi"
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={5}
                            altDisplayValue="jkl"
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={6}
                            altDisplayValue="mno"
                            updateDialedNumber={updateDialedNumber}
                        />
                    </Grid2>
                    <Grid2
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <NumberFab
                            displayValue={7}
                            altDisplayValue="pqrs"
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={8}
                            altDisplayValue="tuv"
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={9}
                            altDisplayValue="wxyz"
                            updateDialedNumber={updateDialedNumber}
                        />
                    </Grid2>
                    <Grid2
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <NumberFab
                            displayValue={'*'}
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={0}
                            altDisplayValue="+"
                            updateDialedNumber={updateDialedNumber}
                        />
                        <NumberFab
                            displayValue={'#'}
                            updateDialedNumber={updateDialedNumber}
                        />
                    </Grid2>
                </React.Fragment>
            )}
            <Grid2
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                {mode === 'call' && <React.Fragment>{callMode}</React.Fragment>}
            </Grid2>
        </Grid2>
    );
}
export default DialPad;
