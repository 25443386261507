import React, { useContext } from 'react';

import RoundBoxNumDisplay from 'Modules/Common/Components/RoundBoxNumDisplay';
import { UnreadContentContext } from 'Modules/UnreadContentContextProvider';

function SideBarCallsIndicator() {
    const unreadHandler = useContext(UnreadContentContext);
    return (
        <RoundBoxNumDisplay
            displayValue={unreadHandler.missedCalls}
            size={1.8}
        />
    );
}

export default SideBarCallsIndicator;
