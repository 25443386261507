import React, { useEffect, useState } from 'react';

// Hammer
import BasePage from 'HammerTemplate/PageTemplates/BasePage';

// Phone Management Components
import PhoneAssignmentTable from 'Modules/Phone Management/Components/PhoneAssignmentTable';
import PhoneHorizontalBarGraph from 'Modules/Phone Management/Components/PhoneHorizontalBarGraph';

import { useUserContext } from 'Modules/UserContextProvider';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

// Http
import {
    getAllCurrentUsersOrganizationPhonesAsync,
    getAllPhonesAssignedToTeamAsync,
    updatePhoneTeamAsync,
    updatePhoneUserAsync,
} from 'Modules/Http/PhoneInterface';
import {
    getAllUsersInOrganizationAsync,
    getAllUsersInTeamAsync,
} from 'Modules/Http/UserInterface';

// Models
import { Phone } from 'Modules/Models/Phone';
import { User, UserType } from 'Modules/Models/User';
import { Team } from 'Modules/Models/Team';
import { getAllTeamsInOrganizationAsync } from 'Modules/Http/TeamInterface';
import { Typography } from '@mui/material';
import PhoneTeamsHorizontalBarGraph from './Components/PhoneTeamsHorizontalBarGraph';
function PhoneAssignmentPage() {
    const currentUser = useUserContext();
    const [phones, setPhones] = useState<Phone[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [teams, setTeams] = useState<Team[] | null>(null);
    const [showDistribution, setShowDistribution] = useState<boolean>(false);
    const [gridString, setGridString] = useState<string>('100%');
    const [graphLayoutHeight, setGraghLayoutHeight] = useState<string>(
        currentUser.userType === UserType.OrganizationAdmin ||
            currentUser.userType === UserType.SuperAdmin
            ? '48.5%'
            : '100%'
    );
    async function editPhoneAssignedUser(
        phoneId: number,
        userId?: number
    ): Promise<boolean> {
        let res = await updatePhoneUserAsync(phoneId, userId);
        await fetchInitialValues();
        return res;
    }
    async function editPhoneAssignedTeam(
        phoneId: number,
        teamId?: number
    ): Promise<boolean> {
        let res = await updatePhoneTeamAsync(phoneId, teamId);
        await fetchInitialValues();
        return res;
    }
    const fetchInitialValues = async () => {
        let getPhones: Promise<Phone[] | null>;
        let getUsers: Promise<User[] | null>;
        let getTeams: Promise<Team[] | null> | null = null;
        if (
            currentUser.userType === UserType.OrganizationAdmin ||
            currentUser.userType === UserType.SuperAdmin
        ) {
            getPhones = getAllCurrentUsersOrganizationPhonesAsync();
            getUsers = getAllUsersInOrganizationAsync();
            getTeams = getAllTeamsInOrganizationAsync();
        } else if (currentUser.userType === UserType.TeamAdmin) {
            getPhones = getAllPhonesAssignedToTeamAsync();
            getUsers = getAllUsersInTeamAsync();
        } else {
            return;
        }
        let phones = await getPhones;
        let users = await getUsers;
        let teams = await getTeams;
        if (teams != null) {
            setTeams(teams);
        }
        if (phones != null) {
            setPhones(phones);
        }
        if (users != null) {
            setUsers(users);
        }
    };

    useEffect(() => {
        fetchInitialValues();
    }, []);

    function handleDistributionButtonClick(showGraph: boolean) {
        setShowDistribution(showGraph);
        if (showGraph) {
            setGridString('65% 2% 33%');
        } else {
            setGridString('100%');
        }
    }
    function distributionButton() {
        if (showDistribution) {
            return (
                <Button
                    onClick={() => handleDistributionButtonClick(false)}
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                >
                    <b>hide distribution</b>
                </Button>
            );
        } else {
            return (
                <Button
                    onClick={() => handleDistributionButtonClick(true)}
                    endIcon={<KeyboardDoubleArrowLeftIcon />}
                >
                    <b>Show distribution</b>
                </Button>
            );
        }
    }
    return (
        <BasePage pageName="Phone Assignments">
            <Box height="5%" width="100%" pr={5} mt={2}>
                <Stack
                    component="span"
                    justifyContent="flex-end"
                    direction="row"
                >
                    {distributionButton()}
                </Stack>
            </Box>
            <Box
                width="100%"
                height="95%"
                display="grid"
                pr={5}
                pl={5}
                gridTemplateColumns={gridString}
                gridTemplateRows="95%"
            >
                <Box height="100%" width="100%">
                    <PhoneAssignmentTable
                        users={users}
                        phones={phones}
                        editPhoneAssignedUser={editPhoneAssignedUser}
                        teams={teams}
                        editPhoneAssignedTeam={editPhoneAssignedTeam}
                    />
                </Box>
                {showDistribution && <Box />}
                {showDistribution && (
                    <Stack alignItems="center" justifyContent="space-between">
                        <Box
                            height={graphLayoutHeight}
                            width="100%"
                            border={1}
                            borderRadius={1}
                            borderColor="grey.500"
                            boxShadow={5}
                            p={2}
                        >
                            <Stack
                                width="100%"
                                height="100%"
                                alignItems="center"
                            >
                                <Typography variant="h5">
                                    <b>Phones per user</b>
                                </Typography>
                                <PhoneHorizontalBarGraph
                                    users={users}
                                    phones={phones}
                                />
                            </Stack>
                        </Box>
                        {teams != null && (
                            <Box
                                height={graphLayoutHeight}
                                width="100%"
                                border={1}
                                borderRadius={1}
                                borderColor="grey.500"
                                boxShadow={5}
                                p={2}
                            >
                                <Stack
                                    width="100%"
                                    height="100%"
                                    alignItems="center"
                                >
                                    <Typography variant="h5">
                                        <b>Phones per team</b>
                                    </Typography>
                                    <PhoneTeamsHorizontalBarGraph
                                        teams={teams}
                                        phones={phones}
                                    />
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                )}
            </Box>
        </BasePage>
    );
}
export default PhoneAssignmentPage;
