import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Tooltip from '@mui/material/Tooltip/Tooltip';

// Phone Management Components
import PhoneDataManagementList from 'Modules/Phone Management/Components/PhoneDataManagementList';

// Models
import { Phone } from 'Modules/Models/Phone';

// Http
import { getAllCurrentUsersPhonesAsync } from 'Modules/Http/PhoneInterface';

enum AlphaStates {
    Unsorted = 0,
    Ascending = 1,
    Descending = 2,
}

type PhoneBasedPageProps = {
    children?: ReactNode;
    /** Width of search bar from 0 to 1*/
    phoneHeaderWidth: number;
    /** Phones to display. If none specified component will manage its own phones */
    inputPhones?: Phone[];
    /** Selected phone */
    selectedPhone: Phone | null;
    /** function to set selected phone */
    setSelectedPhone: React.Dispatch<React.SetStateAction<Phone | null>>;
    /** Determine what is displayed inside the phone list */
    displayType?: 'call' | 'message' | 'none';
};
const FULL_WIDTH = 16;

function PhoneBasedPage({
    children,
    phoneHeaderWidth,
    inputPhones,
    selectedPhone,
    setSelectedPhone,
    displayType = 'none',
}: PhoneBasedPageProps) {
    const state = useLocation().state;

    const phoneListColumnWidth = FULL_WIDTH * (phoneHeaderWidth + 0.02);
    const bodyColumnWidth = FULL_WIDTH - phoneListColumnWidth - 0.2;
    const [phones, setPhones] = useState<Phone[]>(inputPhones ?? []);
    const [phoneSearchFilter, setPhoneSearchFilter] = useState<string>('');
    const [alphaSort, setAlphaSort] = useState<AlphaStates>(
        AlphaStates.Unsorted
    );

    function selectNewPhone(phone: Phone) {
        setSelectedPhone(phone);
    }

    function toggleAlphaSort() {
        let newAlpha = alphaSort + 1;
        if (newAlpha > 2) {
            setAlphaSort(AlphaStates.Unsorted);
        } else {
            setAlphaSort(newAlpha);
        }
    }

    function filterPhones(): Phone[] {
        let filteredPhones = phones.filter(
            (p) =>
                p.nickname
                    .toLowerCase()
                    .includes(phoneSearchFilter.toLowerCase()) ||
                p.phoneNumber.includes(phoneSearchFilter)
        );

        if (alphaSort === AlphaStates.Ascending) {
            filteredPhones.sort((a, b) => a.nickname.localeCompare(b.nickname));
        }
        if (alphaSort === AlphaStates.Descending) {
            filteredPhones.sort(
                (a, b) => -1 * a.nickname.localeCompare(b.nickname)
            );
        }
        return filteredPhones;
    }
    const fetchPhones = async (resetSelected: boolean) => {
        let fetchPhones = await getAllCurrentUsersPhonesAsync();
        if (fetchPhones != null) {
            if (displayType === 'call') {
                fetchPhones = fetchPhones.filter(
                    (p) => p.inboundVoiceCapable || p.outboundVoiceCapable
                );
            }
            if (displayType === 'message') {
                fetchPhones = fetchPhones.filter(
                    (p) => p.inboundSmsCapable || p.outboundSmsCapable
                );
            }
            setPhones(fetchPhones);
            if (fetchPhones.length > 0 && resetSelected) {
                if (
                    state?.phone != null &&
                    fetchPhones.find((p) => p.id === state?.phone.id)
                ) {
                    selectNewPhone(state.phone);
                } else {
                    selectNewPhone(fetchPhones[0]);
                }
            } else {
                let phone = fetchPhones.find((p) => p.id === selectedPhone?.id);
                if (phone != null) {
                    selectNewPhone(phone);
                }
            }
        }
    };

    useEffect(() => {
        if (inputPhones != null) {
            setPhones(inputPhones);
        } else {
            fetchPhones(true);
        }
    }, [inputPhones]);

    return (
        <Grid2
            pr={5}
            pl={5}
            pb={2}
            pt={2}
            container
            width="100%"
            height="100%"
            overflow="auto"
            columns={FULL_WIDTH}
        >
            <Grid2
                height="15%"
                xs={FULL_WIDTH}
                bgcolor="neutral.main"
                border={1}
                p={3}
                sx={{
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    borderBottomRightRadius: 5,
                }}
            >
                <Stack
                    width={phoneHeaderWidth}
                    height="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                >
                    <Typography variant="h5">
                        <b>Phones</b>
                    </Typography>
                    <Box width="100%">
                        <TextField
                            onChange={(event) =>
                                setPhoneSearchFilter(event.target.value)
                            }
                            value={phoneSearchFilter}
                            label="Search Phone"
                            size="small"
                            fullWidth
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: 1,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={() => setPhoneSearchFilter('')}
                                    >
                                        {phoneSearchFilter.length > 0 ? (
                                            <ClearIcon />
                                        ) : (
                                            ''
                                        )}
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                    <IconButton
                        onClick={() => toggleAlphaSort()}
                        sx={{ color: 'black' }}
                    >
                        <Tooltip title={AlphaStates[alphaSort]}>
                            <SortByAlphaIcon />
                        </Tooltip>
                    </IconButton>
                </Stack>
            </Grid2>
            <Grid2
                xs={FULL_WIDTH * (phoneHeaderWidth + 0.02)}
                borderBottom={1}
                borderLeft={1}
                height="85%"
                overflow="auto"
                sx={{ borderBottomLeftRadius: 5 }}
            >
                <PhoneDataManagementList
                    phones={filterPhones()}
                    selectedPhone={selectedPhone}
                    selectNewPhone={selectNewPhone}
                    displayType={displayType}
                />
            </Grid2>
            <Grid2
                height="calc(85% + 4rem)"
                xs={bodyColumnWidth}
                marginTop="-4rem"
                border={1}
                bgcolor="white"
                p={3}
                sx={{
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    borderBottomRightRadius: 5,
                }}
            >
                {children}
            </Grid2>
        </Grid2>
    );
}

export default PhoneBasedPage;
