import React from 'react';

// Material UI
import Box from '@mui/material/Box';

// Hammer Template
import BasePage from 'HammerTemplate/PageTemplates/BasePage';
import ChangePasswordForm from 'HammerTemplate/CommonFunctions/ChangePasswordForm';

// Http
import { updateUserPasswordAsync } from 'Modules/Http/UserInterface';

// UserContext
import { useUserContext } from 'Modules/UserContextProvider';

function ChangePasswordPage() {
    const currentUser = useUserContext();

    const changePassword = async (
        currentPassword: string,
        newPassword: string
    ): Promise<boolean> => {
        let res = await updateUserPasswordAsync(
            currentPassword,
            newPassword,
            currentUser.id
        );
        return res;
    };
    function passwordValidator(
        currentPassword: string,
        passwordField1: string,
        passwordField2: string
    ): { isPasswordValid: boolean; errorMessage: string } {
        let minPasswordLength = 8;
        let maxPasswordLength = 32;

        let isPasswordValid = false;
        let errorMessage = '';

        if (passwordField1 !== passwordField2) {
            errorMessage = 'New password does not match reentry';
        } else if (
            passwordField1.length < minPasswordLength ||
            passwordField1.length > maxPasswordLength ||
            passwordField2.length < minPasswordLength ||
            passwordField2.length > maxPasswordLength
        ) {
            errorMessage = `New password doesn't meet length Criteria (Min length ${minPasswordLength}, Max Length ${maxPasswordLength})`;
        } else if (currentPassword.length === 0) {
            errorMessage = 'Please enter your current password';
        } else {
            isPasswordValid = true;
        }
        return { isPasswordValid, errorMessage };
    }

    return (
        <BasePage pageName="Change Password">
            <Box width="50%" height="100%" p="1rem">
                <ChangePasswordForm
                    submitRequestAsync={changePassword}
                    passwordValidator={passwordValidator}
                ></ChangePasswordForm>
            </Box>
        </BasePage>
    );
}
export default ChangePasswordPage;
