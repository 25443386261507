import React, { useState, useEffect } from 'react';

// Material UI
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Models
import { User } from 'Modules/Models/User';

type TableAutocompleteProps = {
    teamUsers: User[];
    renderCellParams: GridRenderCellParams<any, any, any>;
    editPhoneAssignedUser(phoneId: number, userId?: number): Promise<boolean>;
};
function TableAutocomplete({
    teamUsers,
    renderCellParams,
    editPhoneAssignedUser,
}: TableAutocompleteProps) {
    const [selectedUser, setSelectedUser] = useState<User | null>(
        renderCellParams.row.currentUser
    );
    async function autoCompleteChange(
        userId: number | undefined,
        user: User | null
    ) {
        let res = await editPhoneAssignedUser(
            parseInt(renderCellParams.row.id),
            userId
        );
        if (res) {
            setSelectedUser(user);
        }
    }

    useEffect(() => {
        setSelectedUser(renderCellParams.row.currentUser);
    }, [renderCellParams.row.currentUser]);
    return (
        <Autocomplete<User, undefined, boolean, boolean>
            sx={{ width: '95%' }}
            value={selectedUser}
            options={teamUsers}
            blurOnSelect
            onChange={(event, value) => {
                let userId = null;
                if (typeof value == 'string') {
                    return;
                } else {
                    userId = value?.id;
                }
                autoCompleteChange(userId, value);
            }}
            getOptionLabel={(option) => {
                if (typeof option !== 'string') {
                    return option.username;
                }
                return option;
            }}
            filterOptions={(options) =>
                options.filter(
                    (o) => o.teamId === renderCellParams.row.currentTeam?.id
                )
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            )}
        />
    );
}
export default TableAutocomplete;
