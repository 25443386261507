import axios from 'axios';

// Models
import { Usage, UsageType } from 'Modules/Models/Usage';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets usage for a user through GET /api/Usage/User
 * @param userId?
 * @param usageRangeStart?
 * @param usageRangeEnd?
 * @returns A usage or null if error
 */
async function getUsersUsageAsync(
    userId?: number,
    usageRangeStart?: Date,
    usageRangeEnd?: Date
): Promise<Usage | null> {
    let searchParams = new URLSearchParams();
    if (userId) {
        searchParams.append('userId', userId.toString());
    }
    if (usageRangeStart) {
        searchParams.append('startDate', usageRangeStart.toISOString());
    }
    if (usageRangeEnd) {
        searchParams.append('endDate', usageRangeEnd.toISOString());
    }

    let response = await FetchDataWrapperAsync<Usage | null>(() =>
        axios.get(`/api/Usage/User?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets total usage of a team through GET /api/Usage/Team
 * @param teamId
 * @param usageRangeStart?
 * @param usageRangeEnd?
 * @returns A usage or null if error
 */
async function getTeamsUsage(
    teamId: number,
    usageRangeStart?: Date,
    usageRangeEnd?: Date
): Promise<Usage | null> {
    let searchParams = new URLSearchParams();

    searchParams.append('teamId', teamId.toString());

    if (usageRangeStart) {
        searchParams.append('startDate', usageRangeStart.toISOString());
    }
    if (usageRangeEnd) {
        searchParams.append('endDate', usageRangeEnd.toISOString());
    }

    let response = await FetchDataWrapperAsync<Usage | null>(() =>
        axios.get(`/api/Usage/Team?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets usage for each user of a team through GET /api/Usage/Team/Users
 * @param teamId
 * @param usageRangeStart?
 * @param usageRangeEnd?
 * @returns A usage or null if error
 */
async function getAllUsersOfTeamUsage(
    teamId: number,
    usageRangeStart?: Date,
    usageRangeEnd?: Date
): Promise<Usage[] | null> {
    let searchParams = new URLSearchParams();

    searchParams.append('teamId', teamId.toString());

    if (usageRangeStart) {
        searchParams.append('startDate', usageRangeStart.toISOString());
    }
    if (usageRangeEnd) {
        searchParams.append('endDate', usageRangeEnd.toISOString());
    }

    let response = await FetchDataWrapperAsync<Usage[] | null>(() =>
        axios.get(
            `/api/Usage/Team/Users?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets usage for a list of phones through GET /api/Usage/Phones
 * @param userId?
 * @param usageRangeStart?
 * @param usageRangeEnd?
 * @returns A list of usage or null if error
 */
async function getPhonesUsageAsync(
    phoneIds: number[],
    usageRangeStart?: Date,
    usageRangeEnd?: Date
): Promise<Usage[] | null> {
    let searchParams = new URLSearchParams();
    phoneIds.forEach((entry) => {
        searchParams.append('phoneIds', entry.toString());
    });
    if (usageRangeStart) {
        searchParams.append('startDate', usageRangeStart.toISOString());
    }
    if (usageRangeEnd) {
        searchParams.append('endDate', usageRangeEnd.toISOString());
    }

    let response = await FetchDataWrapperAsync<Usage[] | null>(() =>
        axios.get(
            `/api/Usage/Phones?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets usage for a phone over a date range through GET /api/Usage/PhoneRange
 * @param userId?
 * @param usageRangeStart?
 * @param usageRangeEnd?
 * @returns A list of usage usage or null if error
 */
async function getPhoneUsageOverDateRange(
    phoneId: number,
    usageType: UsageType,
    usageRangeStart?: Date,
    usageRangeEnd?: Date
): Promise<Usage[] | null> {
    let searchParams = new URLSearchParams();

    searchParams.append('phoneId', phoneId.toString());
    searchParams.append('usageType', usageType.toString());

    if (usageRangeStart) {
        searchParams.append('startDate', usageRangeStart.toISOString());
    }
    if (usageRangeEnd) {
        searchParams.append('endDate', usageRangeEnd.toISOString());
    }

    let response = await FetchDataWrapperAsync<Usage[] | null>(() =>
        axios.get(
            `/api/Usage/PhoneRange?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets usage for a phone over a date range through GET /api/Usage/PhoneRange
 * @param userId?
 * @param usageRangeStart?
 * @param usageRangeEnd?
 * @returns A list of usage usage or null if error
 */
async function getTeamUsageOverDateRange(
    teamId: number,
    usageType: string,
    usageRangeStart?: Date,
    usageRangeEnd?: Date
): Promise<Usage[] | null> {
    let searchParams = new URLSearchParams();

    searchParams.append('teamId', teamId.toString());
    searchParams.append('usageType', usageType.toString());

    if (usageRangeStart) {
        searchParams.append('startDate', usageRangeStart.toISOString());
    }
    if (usageRangeEnd) {
        searchParams.append('endDate', usageRangeEnd.toISOString());
    }

    let response = await FetchDataWrapperAsync<Usage[] | null>(() =>
        axios.get(
            `/api/Usage/TeamRange?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

export {
    getUsersUsageAsync,
    getPhonesUsageAsync,
    getPhoneUsageOverDateRange,
    getTeamUsageOverDateRange,
    getAllUsersOfTeamUsage,
    getTeamsUsage,
};
