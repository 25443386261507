import React, { ReactNode, FC } from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export type BasePageProps = {
    children?: ReactNode;
    pageName: string | ReactNode;
    helperInfo?: string;
    RightPanel?: FC;
};

function BasePage({
    children,
    pageName,
    helperInfo,
    RightPanel,
}: BasePageProps) {
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{ padding: '1rem' }}
            >
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="h5">{pageName}</Typography>

                    {helperInfo && (
                        <Tooltip
                            title={<Typography>{helperInfo}</Typography>}
                            placement="right"
                        >
                            <IconButton>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                {RightPanel && <RightPanel />}
            </Stack>
            <Divider />
            {children}
        </Stack>
    );
}

export default BasePage;
