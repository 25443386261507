// Validate a free form entry string so it is a continuos 9-17 digits
function validatePhoneNumber(phoneNumber: string): boolean {
    const MIN_DIGITS: number = 9;
    const MAX_DIGITS: number = 17;

    // Verify string is proper length
    if (phoneNumber.length < MIN_DIGITS || phoneNumber.length > MAX_DIGITS) {
        return false;
    }
    // ensure all string characters are digits
    return /^\d+$/.test(phoneNumber);
}
export { validatePhoneNumber };
