import axios from 'axios';

// Models
import { Call, InboundCallInfo } from 'Modules/Models/Call';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets inbound call info for a call through GET /api/Call/InboundInfo
 * @param id
 * @returns An array of calls or null if error
 */
async function getInboundCallInfoAsync(
    phoneNumber: string,
    otherParty: string
): Promise<InboundCallInfo | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneNumber', phoneNumber);
    searchParams.append('otherParty', otherParty);

    let response = await FetchDataWrapperAsync<InboundCallInfo>(() =>
        axios.get(
            `/api/Call/InboundInfo?${searchParams.toString()}`,
            getAuthHeader()
        )
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Gets calls for a phone through GET /api/Call
 * @param id
 * @returns An array of calls or null if error
 */
async function getCallsAsync(id: number): Promise<Call[] | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', id.toString());

    let response = await FetchDataWrapperAsync<Call[]>(() =>
        axios.get(`/api/Call?${searchParams.toString()}`, getAuthHeader())
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Deletes a call through DELETE /api/Call
 * @param callId
 * @returns True on success, false on error
 */
async function deleteCallAsync(callId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('callId', callId.toString());

    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/Call?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
export { getInboundCallInfoAsync, getCallsAsync, deleteCallAsync };
