import React from 'react';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Team } from 'Modules/Models/Team';

// Rechart
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell,
    Text,
    LabelList,
} from 'recharts';

type PhoneTeamsHorizontalBarGraphProps = {
    teams: Team[];
    phones: Phone[];
};
function PhoneTeamsHorizontalBarGraph({
    phones,
    teams,
}: PhoneTeamsHorizontalBarGraphProps) {
    let data = teams.map((team, i) => ({
        category: team.name,
        Phones: phones.filter((p) => p.teamId === team.id).length,
    }));
    data.push({
        category: 'Unassigned',
        Phones: phones.filter((p) => p.teamId == null).length,
    });
    const CustomYAxisTick = ({ x, y, payload }: any) => {
        const tickFormatter = (value: string) => {
            const limit = 15;
            if (value.length < limit) return value;
            return `${value.substring(0, limit)}...`;
        };

        if (payload && payload.value) {
            return (
                <Text
                    fontSize={'.85rem'}
                    x={x}
                    y={y}
                    textAnchor="end"
                    verticalAnchor="middle"
                >
                    {tickFormatter(payload.value)}
                </Text>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 5,
                    left: 75,
                    bottom: 5,
                }}
                layout="vertical"
            >
                <CartesianGrid horizontal={false} />
                <XAxis
                    type="number"
                    allowDecimals={false}
                    axisLine={false}
                    hide
                />
                <YAxis
                    width={70}
                    dataKey="category"
                    type="category"
                    tick={<CustomYAxisTick />}
                />
                <Tooltip />
                <Bar dataKey="Phones">
                    {data.map((entry, key) => (
                        <Cell
                            key={key}
                            fill={
                                entry.category === 'Unassigned'
                                    ? '#63c2d2'
                                    : '#3d9a8b'
                            }
                        ></Cell>
                    ))}
                    <LabelList dataKey="Phones" position="right" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default PhoneTeamsHorizontalBarGraph;
