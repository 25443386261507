import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Usage } from 'Modules/Models/Usage';

// Material UI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TimelineIcon from '@mui/icons-material/Timeline';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';

import UsageGraph from 'Modules/Usage/Components/UsageGraph';

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

type PhoneUsageCardProps = {
    phone: Phone;
    usage: Usage;
    usageDateRange: DateRange<Dayjs>;
};

function PhoneUsageCard({ phone, usage, usageDateRange }: PhoneUsageCardProps) {
    const [graphOpen, setGraphOpen] = useState(false);
    const handleGraphOpen = () => {
        setGraphOpen(true);
    };
    let navigate = useNavigate();

    const handleRedirectToMessages = () => {
        navigate('/messages', {
            state: { phoneId: phone.id },
        });
    };
    const handleRedirectToCalls = () => {
        navigate('/calls', {
            state: { phone: phone },
        });
    };
    const handleGraphClose = () => {
        setGraphOpen(false);
    };
    let lastUsed = '';
    if (phone.lastUsed) {
        lastUsed =
            new Date(phone.lastUsed).toDateString() +
            ' - ' +
            new Date(phone.lastUsed).toLocaleTimeString();
    }
    return (
        <Box sx={{ width: 350 }}>
            <Card variant="outlined" sx={{ backgroundColor: '#f5f5f5' }}>
                <CardContent sx={{ mt: -1 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ pb: 2 }}
                    >
                        <Stack>
                            <Typography variant="h6" component="div">
                                {phone.nickname !== ''
                                    ? phone.nickname
                                    : formatNumberInternational(
                                          phone.phoneNumber
                                      )}
                            </Typography>
                            <Typography variant="subtitle2" component="div">
                                {formatNumberInternational(phone.phoneNumber)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Stack alignItems={'flex-end'}>
                            <Typography variant="subtitle2" component="div">
                                Minutes:
                            </Typography>
                            <Typography variant="subtitle2" component="div">
                                SMS:
                            </Typography>
                            <Typography variant="subtitle2" component="div">
                                Last Used:
                            </Typography>
                        </Stack>
                        <Stack alignItems={'flex-start'}>
                            <Typography variant="subtitle2" component="div">
                                {usage.sumOfAllCallMinutes.toFixed(0)}
                            </Typography>
                            <Typography variant="subtitle2" component="div">
                                {usage.sumOfAllMessages.toFixed(0)}
                            </Typography>
                            <Typography variant="subtitle2" component="div">
                                {lastUsed}
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <Divider />
                <CardActions disableSpacing>
                    <Tooltip title="Show usage graph">
                        <IconButton onClick={handleGraphOpen}>
                            <TimelineIcon />
                        </IconButton>
                    </Tooltip>
                    <Dialog
                        open={graphOpen}
                        onClose={handleGraphClose}
                        fullWidth={true}
                        maxWidth={'lg'}
                    >
                        <UsageGraph
                            phone={phone}
                            initialDateRange={usageDateRange}
                        />
                        <DialogActions>
                            <Button onClick={handleGraphClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Tooltip title="Jump to messages">
                        <IconButton
                            onClick={handleRedirectToMessages}
                            sx={{ marginLeft: 'auto' }}
                        >
                            <MessageIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Jump to calls">
                        <IconButton onClick={handleRedirectToCalls}>
                            <PhoneIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        </Box>
    );
}

export default PhoneUsageCard;
