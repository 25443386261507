import React, { useState, useEffect, useRef } from 'react';

import { Waypoint } from 'react-waypoint';

import { DEFAULT_NUM_TO_FETCH } from 'Modules/Messages/MessagesPage';
// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

// Message Components
import MessageDisplay from 'Modules/Messages/Components/MessageDisplay';

// Models
import { Message } from 'Modules/Models/Message';

// Http
import { getConversationMessagesAsync } from 'Modules/Http/ConversationInterface';

type MessageBodyProps = {
    messages: Message[];
    deleteMessage: (messageId: number) => Promise<void>;
    displayNewConversation: boolean;
    conversationId: number | undefined;
};
function MessageBody({
    messages,
    deleteMessage,
    displayNewConversation,
    conversationId,
}: MessageBodyProps) {
    // Messages that are being displayed
    const [messagesToDisplay, setMessagesToDisplay] =
        useState<Message[]>(messages);

    // Message scroll behavior
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    // fetch more messages append to list
    const fetchAdditionalMessages = async () => {
        if (conversationId == null) {
            return;
        }
        let fetchMessages = await getConversationMessagesAsync(
            messagesToDisplay.length,
            DEFAULT_NUM_TO_FETCH,
            conversationId
        );
        if (fetchMessages != null) {
            let newMessages = fetchMessages;
            setMessagesToDisplay((messagesToDisplay) => [
                ...messagesToDisplay,
                ...newMessages,
            ]);
        }
    };

    useEffect(() => {
        scrollToBottom();
        setMessagesToDisplay(messages);
    }, [messages]);

    return (
        <React.Fragment>
            {messages.length === 0 && displayNewConversation ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                >
                    <Stack alignItems="center">
                        <b>You're starting a new conversation</b>
                        Type your first message below
                        <ChatBubbleOutlineIcon sx={{ fontSize: 50 }} />
                    </Stack>
                </Box>
            ) : null}
            <Box
                height="100%"
                p={0.5}
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                {messagesToDisplay.map((message, i) => (
                    <React.Fragment key={i}>
                        <MessageDisplay
                            message={message}
                            deleteMessage={deleteMessage}
                        />
                        {i === messagesToDisplay.length - 1 && (
                            <Waypoint
                                onEnter={() => {
                                    fetchAdditionalMessages();
                                }}
                            />
                        )}
                        {i === 0 && (
                            <div id="messageEndDiv" ref={messagesEndRef} />
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </React.Fragment>
    );
}
export default MessageBody;
