import axios from 'axios';

// Models
import { Team } from 'Modules/Models/Team';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets all teams in an organization through GET /api/Team/Organization
 * @returns An array of teams or null if error
 */
async function getAllTeamsInOrganizationAsync(): Promise<Team[] | null> {
    let response = await FetchDataWrapperAsync<Team[]>(() =>
        axios.get(`/api/Team/Organization`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets a team by id though GET /api/Team
 * @returns A single team or null if error
 */
async function getTeamsAsync(teamIds: number[]): Promise<Team[] | null> {
    let searchParams = new URLSearchParams();
    teamIds.forEach((entry) => {
        searchParams.append('teamIds', entry.toString());
    });
    let response = await FetchDataWrapperAsync<Team[]>(() =>
        axios.get(`/api/Team?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Add a team through POST /api/Team
 * @param name
 * @param organizationId
 * @returns Id of created team or null if error
 */
async function addTeamForOrganizationAsync(
    name: string,
    organizationId: number | null
): Promise<number | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('name', name);
    if (organizationId != null) {
        searchParams.append('organizationId', organizationId.toString());
    }
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.post(`/api/Team?${searchParams.toString()}`, {}, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    if (response?.error) {
        throw response.data;
    }
    return null;
}

/**
 * Deletes teams through DELETE /api/Team
 * @param teamIds
 * @returns True on success, false on error
 */
async function deleteTeamsAsync(teamIds: number[]): Promise<boolean> {
    let searchParams = new URLSearchParams();
    teamIds.forEach((teamId) => {
        searchParams.append('teamIds', teamId.toString());
    });
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.delete(`/api/Team?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    if (response?.error) {
        throw response.data;
    }
    return false;
}

/**
 * Updates a team name through PUT /api/Team
 * @param name
 * @param teamId
 * @returns True on success, false on error
 */
async function updateTeamNameAsync(
    name: string,
    teamId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('name', name.toString());
    searchParams.append('teamId', teamId.toString());
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.put(
            `/api/Team/Name?${searchParams.toString()}`,
            {},
            getAuthHeader()
        )
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

export {
    getAllTeamsInOrganizationAsync,
    getTeamsAsync,
    addTeamForOrganizationAsync,
    deleteTeamsAsync,
    updateTeamNameAsync,
};
