import React from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';

export type UsageSummaryEntry = {
    value: number;
    label: string;
};

type UsageSummaryCardProps = {
    title: string;
    Icon?: React.ElementType;
    entries: UsageSummaryEntry[];
    Actions?: React.ReactNode[];
};

function UsageSummaryCard({
    title,
    Icon,
    entries,
    Actions,
}: UsageSummaryCardProps) {
    return (
        <Box sx={{ width: 275 }}>
            <Card variant="outlined" sx={{ backgroundColor: '#f5f5f5' }}>
                <CardContent sx={{ mt: -1 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" component="div">
                            {title}
                        </Typography>
                        {Icon ? <Icon /> : null}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        {entries.map((entry, index) => {
                            return (
                                <Stack alignItems="center" key={index}>
                                    <Typography variant="h4">
                                        {entry.value}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {entry.label}
                                    </Typography>
                                </Stack>
                            );
                        })}
                    </Stack>
                </CardContent>
                <Divider />
                {Actions && (
                    <CardActions disableSpacing>
                        {Actions.map((Action) => {
                            return Action;
                        })}
                    </CardActions>
                )}
            </Card>
        </Box>
    );
}

export default UsageSummaryCard;
