import React, { useState } from 'react';

// Common helper function
import { validatePhoneNumber } from 'Modules/Common/ValidatePhoneNumber';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';

// Models
import { Contact } from 'Modules/Models/Contact';

// Http
import {
    addContactAsync,
    updateContactAsync,
} from 'Modules/Http/ContactInterface';

type ContactMenuProps = {
    isOpen: boolean;
    handleContactDialogClose(updateContacts?: boolean): void;
    contact: Contact | null | undefined;
    selectedPhoneNumber: string | undefined;
    selectedPhoneIndex: number | undefined;
    disablePhoneNumberField?: boolean;
};
function ContactDialog({
    isOpen,
    handleContactDialogClose,
    contact,
    selectedPhoneNumber,
    selectedPhoneIndex,
    disablePhoneNumberField = true,
}: ContactMenuProps) {
    const [contactName, setContactName] = useState<string>(contact?.name ?? '');
    const [contactNumber, setContactNumber] = useState<string | undefined>(
        selectedPhoneNumber
    );
    const [contactNotes, setContactNotes] = useState<string>(
        contact?.notes ?? ''
    );
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    // Add a contact for a conversation without a contact
    async function createContactAsync() {
        if (contactNumber != null && selectedPhoneIndex != null) {
            try {
                let id = await addContactAsync(
                    contactNumber,
                    contactName,
                    contactNotes,
                    selectedPhoneIndex
                );
                if (id != null) {
                    handleContactDialogClose(true);
                }
            } catch (e: any) {
                setErrorAlert(true);
            }
        }
    }

    // Edit a contact for a conversation with an existing contact
    async function editContactAsync() {
        if (contact != null) {
            let editContact: Contact = contact;
            editContact.name = contactName;
            editContact.notes = contactNotes;
            let editResult = await updateContactAsync(editContact);
            if (editResult) {
                handleContactDialogClose();
            }
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={() => handleContactDialogClose(false)}
        >
            <DialogTitle marginBottom={-1}>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                >
                    {contact == null ? 'Add Contact' : 'Edit Contact'}
                    <IconButton onClick={() => handleContactDialogClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <Box p={2} marginTop={-1}>
                <FormGroup>
                    <TextField
                        value={contactName}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => setContactName(event.target.value)}
                        margin="normal"
                        label="Name"
                        variant="outlined"
                    />
                    <TextField
                        disabled={disablePhoneNumberField}
                        margin="normal"
                        label="Phone Number"
                        variant="outlined"
                        value={contactNumber}
                        helperText={
                            contactNumber != null &&
                            validatePhoneNumber(contactNumber)
                                ? ''
                                : 'Phone number must be between 9 and 17 digits'
                        }
                        onChange={(event) =>
                            setContactNumber(event.target.value)
                        }
                        InputProps={{
                            readOnly: disablePhoneNumberField,
                            startAdornment: (
                                <InputAdornment position="start">
                                    +
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        value={contactNotes}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => setContactNotes(event.target.value)}
                        margin="normal"
                        label="Notes"
                        variant="outlined"
                        multiline
                    />
                </FormGroup>
                {errorAlert && (
                    <Alert severity="error">Unable to add contact</Alert>
                )}
            </Box>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={
                        contact == null ? createContactAsync : editContactAsync
                    }
                >
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default ContactDialog;
