import { useState, useEffect } from 'react';

import BasePage from 'HammerTemplate/PageTemplates/BasePage';

import {
    deleteOrganizationSuperAdmin,
    getAllOrganizationsDtoSuperAdmin,
} from 'Modules/Http/SuperAdminInterface';
import ConfirmDeleteDialog from 'Modules/Common/Components/ConfirmDeleteDialog';
import AddOrganizationDialog from 'Modules/SuperAdmin/Components/SuperAdminAddOrganizationDialog';
import { OrganizationDto } from 'Modules/Models/SuperAdminApi/OrganizationDto';
// Material UI
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

//Models

function SuperAdminOrganizationPage() {
    async function getAdminData() {
        let fetchedOrganizationDto: OrganizationDto[] | null =
            await getAllOrganizationsDtoSuperAdmin();
        setOrganizationDto(
            fetchedOrganizationDto ? fetchedOrganizationDto : []
        );
    }

    const [selectedOrganizationIds, setSelectedOrganizationIds] = useState<
        number[]
    >([]);

    const [organizationDto, setOrganizationDto] = useState<OrganizationDto[]>(
        []
    );
    const [isCreateOrganizationDialogOpen, setIsCreateOrganizationDialogOpen] =
        useState<boolean>(false);
    const [isDeleteOrganizationDialogOpen, setIsDeleteOrganizationDialogOpen] =
        useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        getAdminData();
    }, [isCreateOrganizationDialogOpen, isDeleteOrganizationDialogOpen]);

    const columns: GridColDef[] = [
        {
            field: 'organizationName',
            headerName: 'Organization Name',
            flex: 0.3,
            width: 150,
        },
        {
            field: 'admin',
            headerName: 'Admin',
            flex: 0.3,
            width: 150,
            valueGetter: (params) => {
                return params.row?.admin?.username;
            },
        },
        {
            field: 'phoneCount',
            headerName: 'Phone Count',
            flex: 0.3,
            width: 150,
        },
    ];

    return (
        <BasePage pageName="Organization Management">
            {isDeleteOrganizationDialogOpen && (
                <ConfirmDeleteDialog
                    isOpen={isDeleteOrganizationDialogOpen}
                    handleDialogClose={() =>
                        setIsDeleteOrganizationDialogOpen(false)
                    }
                    handleDelete={async () => {
                        try {
                            await deleteOrganizationSuperAdmin(
                                selectedOrganizationIds
                            );
                            setIsDeleteOrganizationDialogOpen(false);
                        } catch (e: any) {
                            setErrorAlert(true);
                        }
                    }}
                >
                    <Typography variant="subtitle1">
                        Are you sure you want to delete this organization?
                    </Typography>
                    {errorAlert && (
                        <Alert severity="error">
                            Unable to delete organization
                        </Alert>
                    )}
                </ConfirmDeleteDialog>
            )}

            {isCreateOrganizationDialogOpen && (
                <AddOrganizationDialog
                    isOpen={isCreateOrganizationDialogOpen}
                    handleDialogClose={() => {
                        setIsCreateOrganizationDialogOpen(false);
                    }}
                ></AddOrganizationDialog>
            )}
            <Box height="5%" width="100%" pr={5} mt={2}>
                <Stack
                    component="span"
                    justifyContent="flex-end"
                    direction="row"
                >
                    <IconButton
                        onClick={() => setIsDeleteOrganizationDialogOpen(true)}
                        disabled={selectedOrganizationIds.length === 0}
                    >
                        <Tooltip title="Delete">
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        onClick={() => setIsCreateOrganizationDialogOpen(true)}
                    >
                        <Tooltip title="Create Organization">
                            <AddBoxIcon />
                        </Tooltip>
                    </IconButton>{' '}
                </Stack>
            </Box>
            <Box
                width="100%"
                height="95%"
                display="grid"
                pr={5}
                pl={5}
                gridTemplateRows="95%"
            >
                <Box height="100%" width="100%">
                    <DataGridPro
                        rows={organizationDto}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={(newSelection) => {
                            setSelectedOrganizationIds(
                                newSelection as number[]
                            );
                        }}
                    />
                </Box>
            </Box>
        </BasePage>
    );
}

export default SuperAdminOrganizationPage;
