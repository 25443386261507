import React from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Message Components
import MessageBody from 'Modules/Messages/Components/MessageBody';
import SendMessageWindow from 'Modules/Messages/Components/SendMessageWindow';

// Models
import { Message } from 'Modules/Models/Message';

// Http
import { Contact } from 'Modules/Models/Contact';
import ContactDialog from 'Modules/Messages/Components/ContactDialog';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';
type MessageWindowProps = {
    messages: Message[];
    selectedConversationId: number;
    sendSmsMessageAsync(messageToString: string): Promise<void>;
    deleteMessage: (messageId: number) => Promise<void>;
    currentOtherParty: string | undefined;
    selectedPhoneIndex: number | undefined;
    contact: Contact | undefined | null;
    isContactDialogOpen: boolean;
    handleContactDialogClose: () => Promise<void>;
    handleContactDialogOpen: () => void;
    disableSendMessageView: boolean;
};
function MessageWindow({
    messages,
    selectedConversationId,
    sendSmsMessageAsync,
    deleteMessage,
    currentOtherParty,
    selectedPhoneIndex,
    contact,
    isContactDialogOpen,
    handleContactDialogClose,
    handleContactDialogOpen,
    disableSendMessageView,
}: MessageWindowProps) {
    // Contact Dialog

    // Display either Contact name and number or just number
    function contactDisplay() {
        if (contact == null) {
            return (
                <Typography fontSize={18}>
                    <b>{formatNumberInternational(currentOtherParty)}</b>
                </Typography>
            );
        } else {
            return (
                <React.Fragment>
                    <Typography fontSize={18}>
                        <b>{contact.name}</b>
                    </Typography>
                    {formatNumberInternational(currentOtherParty)}
                </React.Fragment>
            );
        }
    }
    // Contact menu
    const [menuPosition, setMenuPosition] = React.useState<null | HTMLElement>(
        null
    );
    const open = Boolean(menuPosition);
    const menuButtonHandleClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setMenuPosition(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuPosition(null);
    };
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                minWidth: 0,
            }}
        >
            <Menu
                id="basic-menu"
                anchorEl={menuPosition}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {contact === null ? (
                    <MenuItem
                        onClick={() => {
                            handleContactDialogOpen();
                            handleMenuClose();
                        }}
                    >
                        Add Contact
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={() => {
                            handleContactDialogOpen();
                            handleMenuClose();
                        }}
                    >
                        Edit Contact
                    </MenuItem>
                )}
            </Menu>
            {isContactDialogOpen && (
                <ContactDialog
                    isOpen={isContactDialogOpen}
                    handleContactDialogClose={handleContactDialogClose}
                    contact={contact}
                    selectedPhoneNumber={currentOtherParty}
                    selectedPhoneIndex={selectedPhoneIndex}
                />
            )}
            <Stack height="100%" divider={<Divider flexItem />}>
                <Box height="10%">
                    <Grid container>
                        <Grid item xs={6}>
                            <Stack p={1}>{contactDisplay()}</Stack>
                        </Grid>
                        <Grid item paddingTop={1} paddingBottom={1} xs={6}>
                            <IconButton
                                disabled={disableSendMessageView}
                                sx={{ float: 'right' }}
                                onClick={menuButtonHandleClick}
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <MessageBody
                    messages={messages}
                    displayNewConversation={false}
                    deleteMessage={deleteMessage}
                    conversationId={selectedConversationId}
                />
                <SendMessageWindow
                    sendSmsMessageAsync={sendSmsMessageAsync}
                    disabled={disableSendMessageView}
                />
            </Stack>
        </Box>
    );
}

export default MessageWindow;
