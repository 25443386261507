import React, { useState, useEffect } from 'react';

import { DEFAULT_NUM_TO_FETCH } from 'Modules/Messages/MessagesPage';

// Common
import { validatePhoneNumber } from 'Modules/Common/ValidatePhoneNumber';

// Material UI
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';

// Http
import { getContactsAsync } from 'Modules/Http/ContactInterface';
import {
    getConversationMessagesAsync,
    getPhoneConversationsAsync,
} from 'Modules/Http/ConversationInterface';

//Models
import { Contact } from 'Modules/Models/Contact';
import { Conversation } from 'Modules/Models/Conversation';
import { Phone } from 'Modules/Models/Phone';
import { Message, MessageStatus } from 'Modules/Models/Message';

// Message Components
import MessageBody from 'Modules/Messages/Components/MessageBody';
import SendMessageWindow from 'Modules/Messages/Components/SendMessageWindow';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

type CreateConversationProps = {
    open: boolean;
    handleClose: () => void;
    phones: Phone[];
    defaultSelectedPhoneId: number;
    startConversationSendSmsAsync(
        messageToSend: string,
        senderPhone: Phone,
        receiverPhone: string
    ): Promise<boolean>;
};

function CreateConversation({
    open,
    handleClose,
    phones,
    defaultSelectedPhoneId,
    startConversationSendSmsAsync,
}: CreateConversationProps) {
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [localMessages, setLocalMessages] = useState<Message[]>([]);
    const [localConversations, setLocalConversations] = useState<
        Conversation[]
    >([]);
    const [selectedPhone, setSelectedPhone] = useState<Phone | null>(null);
    const [selectedContact, setSelectedContact] = useState<Contact | null>(
        null
    );
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    // Load messages for a selected conversation
    const loadMessages = async (phoneNumber: string) => {
        let conversationToLoad: Conversation | undefined =
            localConversations.find((c) => c.otherParty === phoneNumber);
        if (conversationToLoad !== undefined) {
            let fetchMessages = await getConversationMessagesAsync(
                0,
                DEFAULT_NUM_TO_FETCH,
                conversationToLoad.id
            );
            if (fetchMessages != null) {
                setLocalMessages(fetchMessages);
            }
        } else {
            setLocalMessages([]);
        }
    };

    const sendSmsMessageAsync = async (messageToSend: string) => {
        if (
            selectedPhone?.phoneNumber != null &&
            selectedContact?.phoneNumber != null
        ) {
            if (messageToSend.length === 0) {
                return;
            }
            let newMessage: Message = {
                id: -1,
                timestamp: new Date(),
                messageContent: messageToSend,
                isSender: true,
                phoneNumber: selectedContact.phoneNumber,
                otherParty: selectedContact.phoneNumber,
                isRead: true,
                isDeleted: false,
                messageStatus: MessageStatus.Sending,
                phoneId: -1,
            };
            setLocalMessages((localMessages) => [newMessage, ...localMessages]);
            let didSendSucceed = await startConversationSendSmsAsync(
                messageToSend,
                selectedPhone,
                selectedContact?.phoneNumber
            );
            if (!didSendSucceed) {
                await loadMessages(selectedContact.phoneNumber);
            }
        }
    };

    // Get contacts for selected phone
    const fetchContactsForPhone = async (phoneId: number) => {
        let fetchContacts = await getContactsAsync(phoneId);
        if (fetchContacts != null) {
            setContacts(fetchContacts);
        }
    };

    // Get conversations for selected phone
    const loadConversations = async (phoneId: number) => {
        let fetchConversations = await getPhoneConversationsAsync(
            0,
            DEFAULT_NUM_TO_FETCH,
            phoneId
        );
        if (fetchConversations !== null) {
            setLocalConversations(fetchConversations);
        }
    };

    useEffect(() => {
        if (selectedContact === null) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [selectedContact]);

    useEffect(() => {
        const fetchContacts = async () => {
            let fetchContacts = await getContactsAsync(defaultSelectedPhoneId);
            if (fetchContacts != null) {
                setContacts(fetchContacts);
            }
        };
        fetchContacts();
        let phone = phones.find((p) => p.id === defaultSelectedPhoneId);
        if (phone !== undefined) {
            setSelectedPhone(phone);
        }
        loadConversations(defaultSelectedPhoneId);
    }, [defaultSelectedPhoneId, phones]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            disableRestoreFocus
            maxWidth="md"
            PaperProps={{ sx: { height: '100%' } }}
        >
            <DialogTitle>
                Start Conversation
                <IconButton onClick={handleClose} sx={{ float: 'right' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack
                    overflow="hidden"
                    spacing={1}
                    height="100%"
                    border={1}
                    borderRadius={3}
                    borderColor="grey.500"
                >
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        justifyContent="space-around"
                        paddingTop={'2%'}
                        spacing={0}
                    >
                        <Box width="40%">
                            <Autocomplete<Phone, undefined, boolean, boolean>
                                value={selectedPhone}
                                disableClearable
                                onChange={(
                                    event: any,
                                    value: Phone | null | string
                                ) => {
                                    if (typeof value !== 'string') {
                                        setSelectedPhone(value);
                                        setLocalMessages([]);
                                        setSelectedContact(null);
                                        if (value != null) {
                                            fetchContactsForPhone(value.id);
                                            loadConversations(value.id);
                                        }
                                    }
                                }}
                                options={phones.filter(
                                    (p) => p.outboundSmsCapable
                                )}
                                getOptionLabel={(option) => {
                                    if (typeof option !== 'string') {
                                        return (
                                            option.nickname +
                                            ' (' +
                                            formatNumberInternational(
                                                option.phoneNumber
                                            ) +
                                            ')'
                                        );
                                    }
                                    return option;
                                }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.nickname +
                                            ' (' +
                                            formatNumberInternational(
                                                option.phoneNumber
                                            ) +
                                            ')'}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="From"
                                    />
                                )}
                            />
                        </Box>
                        <Box width="40%">
                            <Autocomplete<Contact, undefined, boolean, boolean>
                                value={selectedContact}
                                clearOnBlur
                                onChange={(
                                    event: any,
                                    value: Contact | null | string
                                ) => {
                                    if (typeof value !== 'string') {
                                        setSelectedContact(value);
                                        if (value !== null) {
                                            loadMessages(value.phoneNumber);
                                        } else {
                                            setLocalMessages([]);
                                        }
                                    }
                                }}
                                onInputChange={(event: any, value: string) => {
                                    // Don't update any state if this is a click event
                                    if (
                                        event !== null &&
                                        event._reactName === 'onClick'
                                    ) {
                                        return;
                                    }
                                    if (validatePhoneNumber(value)) {
                                        let newValue: Contact = {
                                            id: -1,
                                            name: '',
                                            notes: '',
                                            phoneNumber: value,
                                            phoneId: -1,
                                        };
                                        setSelectedContact(newValue);
                                    } else {
                                        setIsDisabled(true);
                                    }
                                }}
                                freeSolo
                                options={contacts}
                                getOptionLabel={(option) => {
                                    if (typeof option !== 'string') {
                                        if (option.id === -1) {
                                            return option.phoneNumber;
                                        }
                                        return (
                                            option.name +
                                            ' (' +
                                            formatNumberInternational(
                                                option.phoneNumber
                                            ) +
                                            ')'
                                        );
                                    }
                                    return option;
                                }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.name +
                                            ' (' +
                                            formatNumberInternational(
                                                option.phoneNumber
                                            ) +
                                            ')'}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {(selectedContact?.id ===
                                                        -1 ||
                                                        !selectedContact) &&
                                                        '+'}
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="To"
                                        fullWidth
                                        helperText={
                                            isDisabled
                                                ? 'Phone number must be between 9 and 17 digits'
                                                : ''
                                        }
                                    />
                                )}
                            />
                        </Box>
                    </Stack>
                    <Divider />
                    <MessageBody
                        messages={localMessages}
                        displayNewConversation={true}
                        deleteMessage={async () => {}}
                        conversationId={
                            localConversations.find(
                                (c) =>
                                    c.otherParty ===
                                    selectedContact?.phoneNumber
                            )?.id
                        }
                    />
                    <Divider />
                    <SendMessageWindow
                        sendSmsMessageAsync={sendSmsMessageAsync}
                        disabled={isDisabled}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
export default CreateConversation;
