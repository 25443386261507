export enum MessageStatus {
    Received = 'Received',
    Sending = 'Sending',
    Sent = 'Sent',
    Delivered = 'Delivered',
    FailedToSend = 'FailedToSend',
}
export type Message = {
    id: number;
    timestamp: Date;
    messageContent: string;
    phoneNumber: string;
    otherParty: string;
    isRead: boolean;
    isSender: boolean;
    isDeleted: boolean;
    messageStatus: MessageStatus;
    phoneId: number;
    conversationId?: number;
    userId?: number;
    teamId?: number;
    organizationId?: number;
};
