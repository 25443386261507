import React, { useState, useMemo } from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

type changePasswordProps = {
    submitRequestAsync: (
        currentPassword: string,
        newPassword: string
    ) => Promise<boolean>;
    passwordValidator: (
        currentPassword: string,
        passwordField1: string,
        passwordField2: string
    ) => { isPasswordValid: boolean; errorMessage: string };
};

async function onSubmit(
    setRequestResult: Function,
    submitRequestAsync: Function,
    currentPassword: string,
    newPassword: string
) {
    setRequestResult(await submitRequestAsync(currentPassword, newPassword));
}

function ChangePasswordForm({
    submitRequestAsync,
    passwordValidator,
}: changePasswordProps) {
    const [requestResult, setRequestResult] = useState<boolean | null>(null);
    const [newPassword, setNewPassword] = useState<string>('');
    const [reentryNewPassword, setReentryNewPassword] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('');

    const {
        isPasswordValid,
        errorMessage,
    }: { isPasswordValid: boolean; errorMessage: string } = useMemo(() => {
        return passwordValidator(
            currentPassword,
            newPassword,
            reentryNewPassword
        );
    }, [newPassword, reentryNewPassword, currentPassword, passwordValidator]);

    return (
        <Stack direction={'column'} spacing={2} width="100%" height="100%">
            <TextField
                fullWidth
                type="password"
                label="Current Password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentPassword(event.target.value);
                }}
            />
            <TextField
                fullWidth
                type="password"
                label="New Password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewPassword(event.target.value);
                }}
            />
            <TextField
                fullWidth
                type="password"
                label="Re-enter New Password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setReentryNewPassword(event.target.value);
                }}
            />
            <Stack direction="row" spacing={2}>
                <Button
                    variant="contained"
                    disabled={!isPasswordValid}
                    onClick={() => {
                        onSubmit(
                            setRequestResult,
                            submitRequestAsync,
                            currentPassword,
                            newPassword
                        );
                    }}
                >
                    Submit
                </Button>

                {errorMessage.length !== 0 && (
                    <Alert severity="warning">{errorMessage}</Alert>
                )}
                {requestResult != null &&
                    (requestResult ? (
                        <Alert
                            onClose={() => setRequestResult(null)}
                            severity="success"
                        >
                            Password Change Request Sent Successfully
                        </Alert>
                    ) : (
                        <Alert
                            onClose={() => setRequestResult(null)}
                            severity="error"
                        >
                            There was an error with the request.
                        </Alert>
                    ))}
            </Stack>
        </Stack>
    );
}

export default ChangePasswordForm;
