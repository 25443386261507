import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    ReactNode,
    useRef,
} from 'react';

import {
    HubConnectionState,
    HubConnectionBuilder,
    IHttpConnectionOptions,
    HubConnection,
} from '@microsoft/signalr';

import AuthService from 'HammerTemplate/Authentication/AuthService';

import Alert from '@mui/material/Alert';

const SignalRContext = createContext({});

function useSignalR() {
    return useContext(SignalRContext);
}

type SignalRProviderProps = {
    children?: ReactNode;
    url: string;
};

function SignalRProvider({ children, url }: SignalRProviderProps) {
    const [connectionTried, setConnectionTried] = useState<boolean>(false);
    const [connected, setConnected] = useState<boolean>(false);
    const connection = useRef<null | HubConnection>(null);

    useEffect(() => {
        // Adding Lock to Prevent Browser from Sleeping
        // https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#bsleep
        let lockResolver: (
            value: Lock | PromiseLike<Lock | null> | null
        ) => void;

        if (navigator && navigator.locks && navigator.locks.request) {
            const promise = new Promise<Lock | null>((res) => {
                lockResolver = res;
            });

            navigator.locks.request(
                'tab',
                { mode: 'shared' },
                (lock: Lock | null) => promise
            );
        }

        return () => {
            lockResolver(null);
        };
    }, []);

    // On Mount
    useEffect(() => {
        // Event Function
        function onSignalRClose(_error: Error | undefined) {
            setConnected(false);
            ConnectSignalR();
        }

        function onSignalRReconnecting(_connectionID: Error | undefined) {
            setConnected(false);
        }

        function onSignalRReconnected(_connectionID: string | undefined) {
            setConnected(true);
        }

        function getToken(): string {
            let token = AuthService.getToken();
            return token ? token : '';
        }
        const options: IHttpConnectionOptions = {
            accessTokenFactory: getToken,
        };

        async function ConnectSignalR() {
            let connect = new HubConnectionBuilder()
                .withUrl(url, options)
                .withAutomaticReconnect()
                //.configureLogging(LogLevel.Information)
                .build();

            connect?.onclose(onSignalRClose);
            connect?.onreconnecting(onSignalRReconnecting);
            connect?.onreconnected(onSignalRReconnected);

            async function start() {
                while (true) {
                    try {
                        await connect.start();
                        console.log('SignalR Connected.');
                        break;
                    } catch (err) {
                        console.log(err);
                    }
                }
            }

            await start();
            console.assert(connect.state === HubConnectionState.Connected);
            connection.current = connect;
            setConnected(true);
            setConnectionTried(true);

            return () => {
                connect?.stop();
                connection.current = null;
                setConnected(false);
            };
        }
        if (connection.current == null) {
            ConnectSignalR();
        }
    }, [url]);

    function subscribe(key: string, func: (...args: any[]) => void) {
        connection.current?.on(key, func);
        // Adding function to off ensures only one subscribed object is removed not all
        function cleanup() {
            connection.current?.off(key, func);
        }
        return cleanup;
    }

    return (
        <SignalRContext.Provider value={{ connection, subscribe }}>
            {!connected &&
                (connectionTried ? (
                    <Alert severity="error">
                        Network Connection Error, Please Check Connection
                    </Alert>
                ) : (
                    <Alert severity="info">Establishing Connection</Alert>
                ))}
            <React.Fragment>{children}</React.Fragment>
        </SignalRContext.Provider>
    );
}

export default SignalRProvider;
export { useSignalR };
