import React, { useEffect, useState } from 'react';

// Material UI
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Models
import { Organization } from 'Modules/Models/Organization';

type TableAutocompleteProps = {
    organizations: Organization[];
    renderCellParams: GridRenderCellParams<any, any, any>;

    editPhoneAssignedOrganization(
        phoneId: number | undefined,
        organizationId: number | undefined
    ): Promise<boolean>;
};
function SuperAdminTableAutocomplete({
    organizations,
    renderCellParams,
    editPhoneAssignedOrganization,
}: TableAutocompleteProps) {
    const [selectedOrganization, setSelectedOrganization] =
        useState<Organization | null>(null);

    async function autoCompleteChange(
        phoneId: number | undefined,
        organization: Organization | null
    ) {
        let res = await editPhoneAssignedOrganization(
            phoneId,
            organization?.id
        );
        if (res) {
            setSelectedOrganization(organization);
        }
    }

    useEffect(() => {
        let defaultOrganization: Organization | undefined = organizations.find(
            ({ id }) => id === renderCellParams.row.organizationId
        );
        setSelectedOrganization(defaultOrganization ?? null);
    }, [organizations, renderCellParams.row.organizationId]);

    return (
        <Autocomplete<Organization, undefined, boolean, boolean>
            sx={{ width: '95%' }}
            value={selectedOrganization}
            options={organizations}
            blurOnSelect
            onChange={(event, value) => {
                let userId = null;
                if (typeof value == 'string') {
                    return;
                } else {
                    userId = renderCellParams.row.id;
                }
                autoCompleteChange(userId, value);
            }}
            getOptionLabel={(option) => {
                if (typeof option !== 'string') {
                    return option.name;
                }
                return option;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            )}
        />
    );
}
export default SuperAdminTableAutocomplete;
