import React from 'react';

// Material UI
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

// Phone Management Components
import TableAutocomplete from 'Modules/Phone Management/Components/TableAutocomplete';
import TableTeamsAutocomplete from 'Modules/Phone Management/Components/TableTeamsAutocomplete';

// Models
import { Phone } from 'Modules/Models/Phone';
import { User } from 'Modules/Models/User';
import { Team } from 'Modules/Models/Team';

// Common
import { DateToSortableString } from 'Modules/Common/DateTools';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';
type PhoneAssignmentTableProps = {
    phones: Phone[];
    users: User[];
    editPhoneAssignedUser(phoneId: number, userId?: number): Promise<boolean>;
    teams: Team[] | null;
    editPhoneAssignedTeam(phoneId: number, userId?: number): Promise<boolean>;
};
function PhoneAssignmentTable({
    phones,
    users,
    editPhoneAssignedUser,
    teams,
    editPhoneAssignedTeam,
}: PhoneAssignmentTableProps) {
    const teamColumn: GridColDef = {
        field: 'assignedTeam',
        headerName: 'Assigned Team',
        flex: 1,
        valueGetter: (params) => {
            return params.row?.currentTeam?.name ?? null;
        },
        sortComparator: (v1: string, v2: string) => {
            if (v2 == null) {
                return -1;
            }
            return v2.localeCompare(v1);
        },
        renderCell: (params) => {
            return (
                <TableTeamsAutocomplete
                    teams={teams ?? []}
                    renderCellParams={params}
                    editPhoneAssignedTeam={editPhoneAssignedTeam}
                />
            );
        },
    };

    const columns: GridColDef[] = [
        {
            field: 'nickname',
            headerName: 'Phone Name',
            flex: 0.75,
            renderCell(params) {
                return (
                    <>
                        {params.row.nickname === ''
                            ? formatNumberInternational(params.row.phoneNumber)
                            : params.row.nickname}
                    </>
                );
            },
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            flex: 0.75,
            valueFormatter: (params) => {
                return formatNumberInternational(params.value);
            },
        },
        {
            field: 'assignedUser',
            headerName: 'Assigned User',
            flex: 1,
            valueGetter: (params) => {
                return params.row?.currentUser?.username ?? null;
            },
            sortComparator: (v1: string, v2: string) => {
                if (v2 == null) {
                    return -1;
                }
                return v2.localeCompare(v1);
            },
            renderCell: (params) => {
                return (
                    <TableAutocomplete
                        teamUsers={users}
                        renderCellParams={params}
                        editPhoneAssignedUser={editPhoneAssignedUser}
                    />
                );
            },
        },
        {
            field: 'lastUsed',
            headerName: 'Last Used',
            flex: 0.75,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }
                return DateToSortableString(new Date(params.value));
            },
        },
    ];

    if (teams != null) {
        columns.splice(2, 0, teamColumn);
    }

    return (
        <DataGridPro
            disableSelectionOnClick
            columns={columns}
            rows={phones}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'phoneNumber', sort: 'asc' }],
                },
            }}
        />
    );
}
export default PhoneAssignmentTable;
