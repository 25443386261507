import React, { useState } from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';

// Common Components
import TitleWithExitButton from 'Modules/Common/Components/TitleWithExitButton';
import { filterDownRoles } from 'Modules/Common/RoleFilter';
// Models
import { UserType, User } from 'Modules/Models/User';
import { Team } from 'Modules/Models/Team';

// User Context
import { useUserContext } from 'Modules/UserContextProvider';

type CreateUserDialogProps = {
    isOpen: boolean;
    handleDialogClose(): void;
    teams: Team[];
    users: User[];
    createNewUser(
        username: string,
        password: string,
        role: UserType | string,
        team: Team | null
    ): Promise<void>;
};
function CreateUserDialog({
    isOpen,
    handleDialogClose,
    teams,
    users,
    createNewUser,
}: CreateUserDialogProps) {
    const currentUser = useUserContext();
    const [selectUserType, setSelectUserType] = useState<UserType>(
        UserType.User
    );
    const [selectTeam, setSelectTeam] = useState<Team | null>(
        currentUser.userType === UserType.OrganizationAdmin ||
            currentUser.userType === UserType.SuperAdmin
            ? null
            : currentUser.currentTeam
    );
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    async function handleCreateButtonClick() {
        try {
            await createNewUser(username, password, selectUserType, selectTeam);
        } catch (e: any) {
            setErrorAlert(true);
        }
    }
    function isValidUsername(): boolean {
        if (users.some((u) => u.username === username)) {
            return false;
        }
        return true;
    }
    function isCreateButtonDisabled(): boolean {
        if (
            username.length !== 0 &&
            password.length !== 0 &&
            selectUserType.length !== 0 &&
            isValidUsername()
        ) {
            return false;
        }
        return true;
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                <TitleWithExitButton
                    title="Create User"
                    exitAction={handleDialogClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    value={username}
                    error={!isValidUsername()}
                    helperText={
                        isValidUsername() ? '' : 'Username is unavailable'
                    }
                    onChange={(event) => setUsername(event.target.value)}
                    margin="dense"
                    required
                    fullWidth
                    variant="outlined"
                    label="Username"
                />
                <TextField
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    margin="dense"
                    required
                    fullWidth
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <VisibilityIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )}
                            </IconButton>
                        ),
                    }}
                />

                <TextField
                    label="Role"
                    value={selectUserType}
                    onChange={(event) =>
                        setSelectUserType(event.target.value as UserType)
                    }
                    fullWidth
                    margin="dense"
                    required
                    select
                >
                    {filterDownRoles(
                        currentUser.userType,
                        'lessThanOrEqual'
                    ).map((userType) => (
                        <MenuItem key={userType} value={userType}>
                            {userType}
                        </MenuItem>
                    ))}
                </TextField>
                <Autocomplete<Team, undefined, boolean, boolean>
                    options={teams}
                    disabled={
                        currentUser.userType !== UserType.OrganizationAdmin &&
                        currentUser.userType !== UserType.SuperAdmin
                    }
                    value={selectTeam}
                    onChange={(event, value) => {
                        if (typeof value !== 'string') {
                            setSelectTeam(value);
                        }
                    }}
                    getOptionLabel={(option) => {
                        if (typeof option !== 'string') {
                            return option.name;
                        }
                        return option;
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={(params) => (
                        <TextField
                            label="Team (optional)"
                            margin="dense"
                            {...params}
                        />
                    )}
                />
                {errorAlert && (
                    <Alert severity="error">Unable to add user</Alert>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                <Button
                    disabled={isCreateButtonDisabled()}
                    fullWidth
                    variant="contained"
                    onClick={handleCreateButtonClick}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default CreateUserDialog;
