import React from 'react';

// Material UI
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';

// Models
import { Contact } from 'Modules/Models/Contact';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

type PhoneDetailTableProps = {
    contacts: Contact[];
    removeContactAsync(contactId: number): Promise<void>;
    handleEditContact(contact: Contact): void;
};
function PhoneDetailTable({
    contacts,
    removeContactAsync,
    handleEditContact,
}: PhoneDetailTableProps) {
    const [menuPosition, setMenuPosition] = React.useState<null | HTMLElement>(
        null
    );
    const open = Boolean(menuPosition);

    const [selectedMenuContact, setSelectedMenuContact] =
        React.useState<Contact | null>(null);
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            valueFormatter: (params) => {
                return formatNumberInternational(params.value);
            },
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'null',
            headerName: '',
            flex: 0.25,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                            setMenuPosition(event.currentTarget);
                            setSelectedMenuContact(params.row as Contact);
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            {open && (
                <Menu
                    anchorEl={menuPosition}
                    open={open}
                    onClose={() => setMenuPosition(null)}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem
                        onClick={() => {
                            if (selectedMenuContact != null) {
                                handleEditContact(selectedMenuContact);
                            }
                            setMenuPosition(null);
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            if (selectedMenuContact != null) {
                                removeContactAsync(selectedMenuContact.id);
                            }
                            setMenuPosition(null);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        Delete
                    </MenuItem>
                </Menu>
            )}
            <DataGridPro
                disableSelectionOnClick
                columns={columns}
                rows={contacts}
                sx={{ borderRadius: 0, borderColor: 'black', boxShadow: 0 }}
            />
        </React.Fragment>
    );
}

export default PhoneDetailTable;
