import React, { useEffect, useState } from 'react';
import BasePage from 'HammerTemplate/PageTemplates/BasePage';

// Http
import {
    getUsersUsageAsync,
    getPhonesUsageAsync,
} from 'Modules/Http/UsageInterface';
import { getAllCurrentUsersPhonesAsync } from 'Modules/Http/PhoneInterface';

// Models
import { Usage } from 'Modules/Models/Usage';
import { Phone } from 'Modules/Models/Phone';

// Usage components
import UsageSummaryCard from 'Modules/Usage/Components/UsageSummaryCard';
import PhoneUsageCard from 'Modules/Usage/Components/PhoneUsageCard';
import UsageDateRangePicker from 'Modules/Usage/Components/UsageDateRangePicker';

// Common
import {
    removeTimeFromDateRange,
    UsageDateRangeOptions,
} from 'Modules/Common/DateTools';

// Material UI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import MessageIcon from '@mui/icons-material/Message';
import DialpadIcon from '@mui/icons-material/Dialpad';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function UsageSummaryPage() {
    const initialUsageRange = UsageDateRangeOptions.Last30Days;

    const initialDateRange: DateRange<Dayjs> = [
        dayjs().subtract(initialUsageRange, 'day'),
        dayjs(),
    ];
    const [usageDateRange, setUsageDateRange] =
        useState<DateRange<Dayjs>>(initialDateRange);

    const [usage, setUsage] = useState<Usage>({
        receivedMessagesTotal: 0,
        sentMessagesTotal: 0,
        sumOfAllMessages: 0,
        sumOfAllCallMinutes: 0,
        callsTotal: 0,
    });
    const [phonesUsage, setPhonesUsage] = useState<Usage[]>([]);
    const [phones, setPhones] = useState<Phone[]>([]);
    const [phoneIds, setPhoneIds] = useState<number[]>([]);

    const [sortRecentlyUsedFirst, setSortRecentlyUsedFirst] =
        useState<boolean>(true);

    const handleUsageDateRangeChange = (dateRange: DateRange<Dayjs>) => {
        setUsageDateRange(dateRange);
        fetchUsage(dateRange);
        fetchPhonesUsage(phoneIds, dateRange);
    };

    const handleSortRecentlyUsedFirstChange = (
        sortRecentlyUsedFirst: boolean
    ) => {
        setSortRecentlyUsedFirst(sortRecentlyUsedFirst);
        setPhones(sortPhoneList(phones, sortRecentlyUsedFirst));
    };

    const fetchUsage = async (dateRange: DateRange<Dayjs>) => {
        dateRange = removeTimeFromDateRange(dateRange);
        let fetchUsage = await getUsersUsageAsync(
            undefined,
            dateRange[0]?.toDate(),
            dateRange[1]?.toDate()
        );
        if (fetchUsage !== null) {
            setUsage(fetchUsage);
        }
    };

    const fetchPhonesUsage = async (
        phoneIds: number[],
        dateRange: DateRange<Dayjs>
    ) => {
        dateRange = removeTimeFromDateRange(dateRange);
        let fetchPhonesUsage = await getPhonesUsageAsync(
            phoneIds,
            dateRange[0]?.toDate(),
            dateRange[1]?.toDate()
        );
        if (fetchPhonesUsage !== null) {
            setPhonesUsage(fetchPhonesUsage);
        }
    };

    const sortPhoneList = (
        phoneList: Phone[],
        sortRecentlyUsedFirst: boolean
    ) => {
        if (sortRecentlyUsedFirst) {
            phoneList = phoneList.sort((a, b) =>
                new Date(a.lastUsed ?? 0).getTime() <=
                new Date(b.lastUsed ?? 0).getTime()
                    ? 1
                    : -1
            );
        } else {
            phoneList = phoneList.sort((a, b) =>
                new Date(a.lastUsed ?? 0).getTime() >=
                new Date(b.lastUsed ?? 0).getTime()
                    ? 1
                    : -1
            );
        }
        return phoneList;
    };

    // Load initial usage and phones on page load
    useEffect(() => {
        const fetchPhonesAndPhoneUsage = async () => {
            let fetchPhones = await getAllCurrentUsersPhonesAsync();
            if (fetchPhones !== null) {
                fetchPhones = sortPhoneList(fetchPhones, sortRecentlyUsedFirst);
                setPhones(fetchPhones);
                let ids: number[] = fetchPhones.map((phone) => phone.id);
                setPhoneIds(ids);
                fetchPhonesUsage(ids, initialDateRange);
            }
        };
        fetchPhonesAndPhoneUsage();
        fetchUsage(initialDateRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePage pageName="Your Usage">
            <Stack height="100%">
                <Box p={1} mx={4}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Stack direction="row" spacing={6}>
                            <UsageSummaryCard
                                title="SMS Usage"
                                Icon={MessageIcon}
                                entries={[
                                    {
                                        value: usage.receivedMessagesTotal,
                                        label: 'Received',
                                    },
                                    {
                                        value: usage.sentMessagesTotal,
                                        label: 'Sent',
                                    },
                                ]}
                            />
                            <UsageSummaryCard
                                title="Voice Usage"
                                Icon={DialpadIcon}
                                entries={[
                                    {
                                        value: usage.sumOfAllCallMinutes,
                                        label: 'Minutes',
                                    },
                                    { value: usage.callsTotal, label: 'Calls' },
                                ]}
                            />
                        </Stack>
                        <UsageDateRangePicker
                            dateRange={usageDateRange}
                            initialSelection={initialUsageRange}
                            handleUsageDateRangeChange={
                                handleUsageDateRangeChange
                            }
                        />
                    </Stack>
                </Box>
                <Box px={5} mt={1}>
                    <Stack direction="row" alignItems="flex-end" spacing={10}>
                        <Stack>
                            <Typography variant="h5">
                                Phone Usage Summary
                            </Typography>
                            <Typography variant="subtitle1">
                                {phones.length} Phones
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="body1">
                                Recently Used
                            </Typography>
                            <IconButton
                                onClick={() =>
                                    handleSortRecentlyUsedFirstChange(
                                        !sortRecentlyUsedFirst
                                    )
                                }
                            >
                                {sortRecentlyUsedFirst ? (
                                    <ArrowUpwardIcon fontSize="small" />
                                ) : (
                                    <ArrowDownwardIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        overflowY: 'auto',
                    }}
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                    px={5}
                    mb={8}
                >
                    <Grid container spacing={3} pb={2}>
                        {phones.map((phone, index) => (
                            <Grid item xs={'auto'} key={index}>
                                <PhoneUsageCard
                                    phone={phone}
                                    usage={
                                        phonesUsage.find(
                                            (i) => i.phonesId === phone.id
                                        ) ?? {
                                            sentMessagesTotal: 0,
                                            receivedMessagesTotal: 0,
                                            sumOfAllMessages: 0,
                                            sumOfAllCallMinutes: 0,
                                            callsTotal: 0,
                                        }
                                    }
                                    usageDateRange={usageDateRange}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Stack>
        </BasePage>
    );
}

export default UsageSummaryPage;
