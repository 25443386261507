import { Contact } from 'Modules/Models/Contact';
import { Phone } from 'Modules/Models/Phone';

export enum CallType {
    Missed = 'Missed',
    Inbound = 'Inbound',
    Outbound = 'Outbound',
}

export type Call = {
    id: number;
    timestamp: Date;
    seconds: number;
    otherParty: string;
    callType: CallType;
    contact?: Contact;
    isDeleted: boolean;
    isSeen: boolean;
    phoneId: number;
};

export type InboundCallInfo = {
    phone: Phone;
    contact?: Contact;
};
