// The usage of a phone in relation to an individual user, team, or organization

export type Usage = {
    phonesId?: number;
    usersId?: number;
    usageDay?: Date;
    sentMessagesTotal: number;
    receivedMessagesTotal: number;
    callsTotal: number;
    sumOfAllCallMinutes: number;
    sumOfAllMessages: number;
    usersTeamId?: number;
    usersOrganizationId?: number;
};

export enum UsageType {
    Call = 'Call',
    SMS = 'SMS',
}
