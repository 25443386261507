// Template Models
import { PageGroup } from 'HammerTemplate/HammerTemplateTypes';

// Pages
import HomePage from 'Modules/Home/HomePage';
import CallsPage from 'Modules/Calls/CallsPage';
import MessagesPage from 'Modules/Messages/MessagesPage';
import UsageSummaryPage from 'Modules/Usage/UsageSummaryPage';
import TeamUsagePage from 'Modules/Usage/TeamUsagePage';
import PhoneDataPage from 'Modules/Phone Management/PhoneDataPage';
import PhoneAssignmentPage from 'Modules/Phone Management/PhoneAssignmentPage';
import UserManagementPage from 'Modules/UserManagement/UserManagementPage';
import ChangePasswordPage from 'Modules/AccountManagement/ChangePasswordPage';
import SuperAdminPhoneManagementPage from 'Modules/SuperAdmin/SuperAdminPhoneManagementPage';
import SuperAdminOrganizationPage from 'Modules/SuperAdmin/SuperAdminManageOrganizationPage';
import SuperAdminTestingPairPage from 'Modules/SuperAdmin/SuperAdminTestingPairPage';

// Material UI
import HomeIcon from '@mui/icons-material/Home';
import DialpadIcon from '@mui/icons-material/Dialpad';
import MessageIcon from '@mui/icons-material/Message';

// Models
import { UserType } from 'Modules/Models/User';

// Common Components
import SideBarMessagesIndicator from 'Modules/Common/Components/SideBarMessagesIndicator';
import SideBarCallsIndicator from 'Modules/Common/Components/SideBarCallsIndicator';

function pageSetGenerator(currentUserType: UserType): Array<PageGroup> {
    // Pages for everyone
    let pageGroups: Array<PageGroup> = [
        {
            title: 'Home',
            route: '/home',
            page: HomePage,
            Icon: HomeIcon,
        },
        {
            title: 'Calls',
            route: '/calls',
            page: CallsPage,
            Icon: DialpadIcon,
            indicator: SideBarCallsIndicator,
        },
        {
            title: 'Messages',
            route: '/messages',
            page: MessagesPage,
            Icon: MessageIcon,
            indicator: SideBarMessagesIndicator,
        },
    ];

    // Management group
    let managementPages: PageGroup = {
        title: 'Manage',
        route: '/manage',
        pages: [],
    };
    // Management pages
    let phoneDataPage = {
        title: 'Phone Data',
        route: '/phoneData',
        page: PhoneDataPage,
    };
    let phoneAssignmentPage = {
        title: 'Phone Assignments',
        route: '/phoneAssignments',
        page: PhoneAssignmentPage,
    };
    let userAndTeamsPage = {
        title: 'Users and Teams',
        route: '/userManagement',
        page: UserManagementPage,
    };

    // Usage group
    let usagePages: PageGroup = {
        title: 'Usage',
        route: '/usage',
        pages: [],
    };
    // Usage summary page
    let usageSummaryPage = {
        title: 'Summary',
        route: '/summary',
        page: UsageSummaryPage,
    };
    // Usage team page
    let usageTeamPage = {
        title: 'Team',
        route: '/team',
        page: TeamUsagePage,
    };

    // Account group
    let accountPages: PageGroup = {
        title: 'Account',
        route: '/account',
        pages: [],
    };
    // Account pages
    let resetPasswordPage = {
        title: 'Change Password',
        route: '/password',
        page: ChangePasswordPage,
    };
    // Super Admin Group
    let superAdminPages: PageGroup = {
        title: 'Super Admin',
        route: '/superAdmin',
        pages: [],
    };
    // Super User pages
    let superAdminPhoneManagementPage = {
        title: 'Phone Management',
        route: '/phoneManagement',
        page: SuperAdminPhoneManagementPage,
    };
    let superAdminOrganizationPage = {
        title: 'Organizations',
        route: '/organization',
        page: SuperAdminOrganizationPage,
    };
    let superAdminTestingPairPage = {
        title: 'Testing Pairs',
        route: '/testingPair',
        page: SuperAdminTestingPairPage,
    };

    if (currentUserType === UserType.SuperAdmin) {
        managementPages.pages.push(
            phoneDataPage,
            phoneAssignmentPage,
            userAndTeamsPage
        );
        // Account group
        accountPages.pages.push(resetPasswordPage);
        // Super User Pages
        superAdminPages.pages.push(
            superAdminPhoneManagementPage,
            superAdminOrganizationPage,
            superAdminTestingPairPage
        );
        // Add page groups
        pageGroups.push(managementPages);
        pageGroups.push(accountPages);
        pageGroups.push(superAdminPages);
    }

    // Add pages for Organization Admin
    else if (currentUserType === UserType.OrganizationAdmin) {
        // Management group
        managementPages.pages.push(
            phoneDataPage,
            phoneAssignmentPage,
            userAndTeamsPage
        );
        // Account group
        accountPages.pages.push(resetPasswordPage);

        // Usage group
        usagePages.pages.push(usageSummaryPage, usageTeamPage);

        // Add page groups
        pageGroups.push(usagePages);
        pageGroups.push(managementPages);
        pageGroups.push(accountPages);
    }
    // Add pages for Team Admin
    else if (currentUserType === UserType.TeamAdmin) {
        // Management group
        managementPages.pages.push(
            phoneDataPage,
            phoneAssignmentPage,
            userAndTeamsPage
        );
        // Account group
        accountPages.pages.push(resetPasswordPage);

        // Usage group
        usagePages.pages.push(usageSummaryPage, usageTeamPage);

        // Add page groups
        pageGroups.push(usagePages);
        pageGroups.push(managementPages);
        pageGroups.push(accountPages);
    }
    // Add pages for User
    else if (currentUserType === UserType.User) {
        // Management group
        managementPages.pages.push(phoneDataPage);
        // Account group
        accountPages.pages.push(resetPasswordPage);
        // Usage group
        usagePages.pages.push(usageSummaryPage);
        // Add page groups
        pageGroups.push(usagePages);
        pageGroups.push(managementPages);
        pageGroups.push(accountPages);
    }
    return pageGroups;
}

export default pageSetGenerator;
