import React from 'react';

// Material UI
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

// Models
import { Team } from 'Modules/Models/Team';

type TeamsTableProps = {
    teams: Team[];
    setSelectedTeams: React.Dispatch<React.SetStateAction<number[]>>;
};
function TeamsTable({ teams, setSelectedTeams }: TeamsTableProps) {
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Team',
            minWidth: 200,
        },
        {
            field: 'teamLead',
            headerName: 'Lead',
            minWidth: 200,
        },
        {
            field: 'userCount',
            headerName: 'User Count',
            minWidth: 150,
        },
    ];
    return (
        <DataGridPro
            density="compact"
            checkboxSelection
            disableSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                },
            }}
            columns={columns}
            rows={teams}
            onSelectionModelChange={(newSelection) => {
                setSelectedTeams(newSelection as number[]);
            }}
        />
    );
}
export default TeamsTable;
