import React, { useState, useContext } from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Fab from '@mui/material/Fab';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// Calls Components
import DialPad from 'Modules/Calls/Components/DialPad';

// Common
import ContactDialog from 'Modules/Messages/Components/ContactDialog';

// Models
import { Contact } from 'Modules/Models/Contact';
import { Phone } from 'Modules/Models/Phone';

// Context
import { VoiceContext, CallStatus } from 'Modules/VoiceContextProvider';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

enum AlphaStates {
    Unsorted = 0,
    Ascending = 1,
    Descending = 2,
}
enum CallWindowViewOptions {
    Dialer,
    Contacts,
}
const ONLY_NUMBER_REGEX = /^[0-9\b]+$/;
type CallWindowProps = {
    contacts: Contact[];
    selectedPhone: Phone;
    getCallData(): Promise<void>;
};
function CallWindow({ contacts, selectedPhone, getCallData }: CallWindowProps) {
    const phone = useContext(VoiceContext);
    const [displaySelector, setDisplaySelector] =
        useState<CallWindowViewOptions>(CallWindowViewOptions.Dialer);
    const [dialedNumber, setDialedNumber] = useState<string>('');
    const [contactFilter, setContactFilter] = useState<string>('');
    const [alphaSort, setAlphaSort] = useState<AlphaStates>(
        AlphaStates.Unsorted
    );

    const [contactMenuOpen, setContactMenuOpen] = useState<boolean>(false);
    async function handleContactDialogClose(updateContacts?: boolean) {
        setContactMenuOpen(false);
        if (updateContacts) {
            await getCallData();
        }
    }

    function toggleAlphaSort() {
        let newAlpha = alphaSort + 1;
        if (newAlpha > 2) {
            setAlphaSort(AlphaStates.Unsorted);
        } else {
            setAlphaSort(newAlpha);
        }
    }
    function filterContacts(): Contact[] {
        let filteredContacts = contacts.filter(
            (c) =>
                c.name.toLowerCase().includes(contactFilter.toLowerCase()) ||
                c.phoneNumber.includes(contactFilter)
        );
        if (alphaSort === AlphaStates.Ascending) {
            filteredContacts.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (alphaSort === AlphaStates.Descending) {
            filteredContacts.sort((a, b) => -1 * a.name.localeCompare(b.name));
        }
        return filteredContacts;
    }
    function handleContactCallButtonClick(contact: Contact) {
        phone.callUser(contact, selectedPhone);
    }
    function placeCall() {
        let contactMatch = contacts.find(
            (contact) => contact.phoneNumber === dialedNumber
        );
        if (contactMatch == null) {
            phone.callUser(dialedNumber, selectedPhone);
        } else {
            phone.callUser(contactMatch, selectedPhone);
        }
    }
    function freeFormContact(): string {
        let contactMatch = contacts.find(
            (contact) => contact.phoneNumber === dialedNumber
        );
        if (contactMatch == null) {
            return '';
        } else {
            return `Contact: ${contactMatch.name}`;
        }
    }

    return (
        <React.Fragment>
            {contactMenuOpen && (
                <ContactDialog
                    isOpen={contactMenuOpen}
                    handleContactDialogClose={handleContactDialogClose}
                    selectedPhoneNumber={''}
                    selectedPhoneIndex={selectedPhone?.id}
                    contact={null}
                    disablePhoneNumberField={false}
                />
            )}

            <Grid2 width="100%" height="100%" container>
                <Grid2
                    xs={12}
                    height="12%"
                    borderBottom={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={displaySelector}
                        exclusive
                        onChange={(
                            event,
                            newAlignment: CallWindowViewOptions
                        ) => setDisplaySelector(newAlignment)}
                    >
                        <ToggleButton value={CallWindowViewOptions.Dialer}>
                            Dialer
                        </ToggleButton>
                        <ToggleButton value={CallWindowViewOptions.Contacts}>
                            Contacts
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid2>
                <Grid2 xs={12} height="88%">
                    {displaySelector === CallWindowViewOptions.Dialer && (
                        <Stack
                            width="100%"
                            height="100%"
                            display="flex"
                            alignItems="center"
                        >
                            <Box
                                width="75%"
                                height="25%"
                                display="flex"
                                alignItems="center"
                            >
                                <TextField
                                    fullWidth
                                    label="Dialed Number"
                                    value={dialedNumber}
                                    helperText={freeFormContact()}
                                    onChange={(event) => {
                                        if (
                                            event.target.value === '' ||
                                            ONLY_NUMBER_REGEX.test(
                                                event.target.value
                                            )
                                        ) {
                                            setDialedNumber(event.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                +
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box width="60%" height="60%">
                                <DialPad
                                    placeCall={placeCall}
                                    mode="call"
                                    setDialedNumber={setDialedNumber}
                                    disabled={
                                        !selectedPhone?.outboundVoiceCapable
                                    }
                                />
                            </Box>
                        </Stack>
                    )}
                    {displaySelector === CallWindowViewOptions.Contacts && (
                        <Stack width="100%" height="100%">
                            <Stack
                                width="100%"
                                height="15%"
                                pl={2}
                                pr={2}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-around"
                                borderBottom={1}
                            >
                                <TextField
                                    size="small"
                                    onChange={(event) =>
                                        setContactFilter(event.target.value)
                                    }
                                    value={contactFilter}
                                    label="Search Contacts"
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() =>
                                                    setContactFilter('')
                                                }
                                            >
                                                {contactFilter.length > 0 ? (
                                                    <ClearIcon />
                                                ) : (
                                                    ''
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />

                                <IconButton onClick={() => toggleAlphaSort()}>
                                    <Tooltip title={AlphaStates[alphaSort]}>
                                        <SortByAlphaIcon />
                                    </Tooltip>
                                </IconButton>
                                <Tooltip title="Add Contact">
                                    <IconButton
                                        onClick={() => setContactMenuOpen(true)}
                                    >
                                        <PersonAddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Box height="85%" overflow="auto">
                                {contacts.length === 0 ? (
                                    <Box
                                        width="100%"
                                        height="80%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant="h6">
                                            This phone has no contacts
                                        </Typography>
                                    </Box>
                                ) : (
                                    <List dense>
                                        {filterContacts().map((contact) => (
                                            <ListItem key={contact.id}>
                                                <Stack
                                                    p={1}
                                                    height="100%"
                                                    width="100%"
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    borderBottom={1}
                                                    borderColor="grey.500"
                                                >
                                                    <Typography
                                                        width="35%"
                                                        variant="subtitle1"
                                                    >
                                                        {contact.name}
                                                    </Typography>
                                                    <Typography
                                                        width="40%"
                                                        variant="subtitle1"
                                                    >
                                                        {formatNumberInternational(
                                                            contact.phoneNumber
                                                        )}
                                                    </Typography>
                                                    <Fab
                                                        disabled={
                                                            phone.currentCallStatus !==
                                                                CallStatus.Standby ||
                                                            !selectedPhone?.outboundVoiceCapable
                                                        }
                                                        size="small"
                                                        color="success"
                                                        onClick={() =>
                                                            handleContactCallButtonClick(
                                                                contact
                                                            )
                                                        }
                                                    >
                                                        <LocalPhoneIcon />
                                                    </Fab>
                                                </Stack>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </Box>
                        </Stack>
                    )}
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}
export default CallWindow;
