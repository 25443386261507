import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Authentication
import AuthService from 'HammerTemplate/Authentication/AuthService';

// DataGrid License
import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(
    '68bdd843a9c3c36bf87aa38c89c420fbT1JERVI6NDEyMzMsRVhQSVJZPTE2ODA4MDUyMTIwMDAsS0VZVkVSU0lPTj0x'
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const renderApp = () =>
    root.render(
        <BrowserRouter basename={'/'}>
            <App />
        </BrowserRouter>
    );

// Setup Auth
AuthService.initKeycloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
