import React, { useEffect, useState } from 'react';
import BasePage from 'HammerTemplate/PageTemplates/BasePage';

// Usage components
import UsageSummaryCard from 'Modules/Usage/Components/UsageSummaryCard';
import UsageDateRangePicker from 'Modules/Usage/Components/UsageDateRangePicker';
import TeamUsageTable from 'Modules/Usage/Components/TeamUsageTable';
import UsageGraph from 'Modules/Usage/Components/UsageGraph';

// Models
import { Usage } from 'Modules/Models/Usage';
import { Team } from 'Modules/Models/Team';
import { User, UserType } from 'Modules/Models/User';
import { Phone } from 'Modules/Models/Phone';

// Common
import {
    removeTimeFromDateRange,
    UsageDateRangeOptions,
} from 'Modules/Common/DateTools';

// Http
import {
    getTeamsAsync,
    getAllTeamsInOrganizationAsync,
} from 'Modules/Http/TeamInterface';
import { getAllUsersInTeamAsync } from 'Modules/Http/UserInterface';
import { getAllPhonesAssignedToTeamAsync } from 'Modules/Http/PhoneInterface';
import {
    getTeamsUsage,
    getAllUsersOfTeamUsage,
} from 'Modules/Http/UsageInterface';

// Context
import { useUserContext } from 'Modules/UserContextProvider';

// Material UI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import MessageIcon from '@mui/icons-material/Message';
import DialpadIcon from '@mui/icons-material/Dialpad';

type TeamAndUsersUsage = {
    teamUsage: Usage;
    teamUsersUsage: Usage[];
};

type TeamsUsersAndPhones = {
    team?: Team;
    users?: User[];
    phones?: Phone[];
};

async function fetchTeamAndUsersUsage(
    teamId: number,
    dateRange: DateRange<Dayjs>,
    setTeamAndUsersUsage: (input: TeamAndUsersUsage) => void,
    current: TeamAndUsersUsage
) {
    dateRange = removeTimeFromDateRange(dateRange);
    Promise.all([
        getTeamsUsage(teamId, dateRange[0]?.toDate(), dateRange[1]?.toDate()),
        getAllUsersOfTeamUsage(
            teamId,
            dateRange[0]?.toDate(),
            dateRange[1]?.toDate()
        ),
    ]).then((results) => {
        setTeamAndUsersUsage({
            teamUsage: results[0] ?? current.teamUsage,
            teamUsersUsage: results[1] ?? current.teamUsersUsage,
        });
    });
}

async function fetchTeamsUsersAndPhones(
    teamId: number,
    setTeamUsersAndPhones: (input: TeamsUsersAndPhones) => void,
    current?: TeamsUsersAndPhones
) {
    Promise.all([
        getTeamsAsync([teamId]),
        getAllUsersInTeamAsync(teamId),
        getAllPhonesAssignedToTeamAsync(teamId),
    ]).then((results) => {
        setTeamUsersAndPhones({
            team: results[0]?.[0] ?? current?.team,
            users: results[1] ?? current?.users,
            phones: results[2] ?? current?.phones,
        });
    });
}

function TeamUsagePage() {
    const currentUser = useUserContext();

    const initialUsageRange = UsageDateRangeOptions.Last30Days;

    const initialDateRange: DateRange<Dayjs> = [
        dayjs().subtract(initialUsageRange, 'day'),
        dayjs(),
    ];

    const [usageDateRange, setUsageDateRange] =
        useState<DateRange<Dayjs>>(initialDateRange);

    const handleUsageDateRangeChange = (dateRange: DateRange<Dayjs>) => {
        setUsageDateRange(dateRange);
        if (selectedTeam) {
            fetchTeamAndUsersUsage(
                selectedTeam.id,
                dateRange,
                setTeamAndUsersUsage,
                teamAndUsersUsage
            );
        }
    };

    const handleSelectedTeamChange = (team: Team) => {
        setSelectedTeam(team);
        fetchTeamsUsersAndPhones(
            team.id,
            setTeamUsersAndPhones,
            teamUsersAndPhones
        );
        fetchTeamAndUsersUsage(
            team.id,
            usageDateRange,
            setTeamAndUsersUsage,
            teamAndUsersUsage
        );
    };

    const [graphOpen, setGraphOpen] = useState(false);

    const handleGraphOpen = () => {
        setGraphOpen(true);
    };
    const handleGraphClose = () => {
        setGraphOpen(false);
    };

    const [teams, setTeams] = useState<Team[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(
        currentUser.currentTeam
    );

    const [teamUsersAndPhones, setTeamUsersAndPhones] =
        useState<TeamsUsersAndPhones>();

    const [teamAndUsersUsage, setTeamAndUsersUsage] =
        useState<TeamAndUsersUsage>({
            teamUsage: {
                receivedMessagesTotal: 0,
                sentMessagesTotal: 0,
                sumOfAllMessages: 0,
                sumOfAllCallMinutes: 0,
                callsTotal: 0,
            },
            teamUsersUsage: [],
        });

    // Load initial usage and teams on page load
    useEffect(() => {
        if (selectedTeam) {
            fetchTeamsUsersAndPhones(
                selectedTeam?.id,
                setTeamUsersAndPhones,
                teamUsersAndPhones
            );
            fetchTeamAndUsersUsage(
                selectedTeam?.id,
                initialDateRange,
                setTeamAndUsersUsage,
                teamAndUsersUsage
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchTeams() {
            let teams = await getAllTeamsInOrganizationAsync();
            if (teams != null && teams.length > 0) {
                setTeams(teams);
                handleSelectedTeamChange(teams[0]);
            }
        }

        if (
            currentUser.userType === UserType.OrganizationAdmin ||
            currentUser.userType === UserType.SuperAdmin
        ) {
            fetchTeams();
        }
    }, [currentUser]);

    return (
        <BasePage
            pageName={
                currentUser.userType === UserType.OrganizationAdmin ||
                currentUser.userType === UserType.SuperAdmin ? (
                    <Stack direction="row" spacing={3} alignItems="center">
                        <Typography variant="inherit">
                            {`Team Usage `}
                        </Typography>
                        <Autocomplete
                            size="small"
                            disableClearable={selectedTeam != null}
                            value={selectedTeam}
                            onChange={(event: any, newValue: Team | null) => {
                                if (newValue) {
                                    handleSelectedTeamChange(newValue);
                                }
                            }}
                            options={teams ?? []}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => {
                                if (typeof option !== 'string') {
                                    return option.name;
                                }
                                return option;
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Team" />
                            )}
                        />
                    </Stack>
                ) : (
                    `Team Usage - ${selectedTeam?.name} `
                )
            }
        >
            <Stack height="100%" p={1} mx={2}>
                <Stack alignItems="flex-start" spacing={2}>
                    <Stack
                        direction="row"
                        spacing={6}
                        alignItems="center"
                        sx={{ ml: 1 }}
                    >
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Activity Range:</Typography>
                            <UsageDateRangePicker
                                dateRange={usageDateRange}
                                initialSelection={initialUsageRange}
                                handleUsageDateRangeChange={
                                    handleUsageDateRangeChange
                                }
                            />
                        </Stack>
                        <Button
                            onClick={handleGraphOpen}
                            variant="contained"
                            sx={{ height: '70%' }}
                        >
                            View Report
                        </Button>
                        <Dialog
                            open={graphOpen}
                            onClose={handleGraphClose}
                            fullWidth={true}
                            maxWidth={'lg'}
                        >
                            <UsageGraph
                                team={teamUsersAndPhones?.team}
                                initialDateRange={usageDateRange}
                            />
                            <DialogActions>
                                <Button onClick={handleGraphClose}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                    <Stack direction="row" spacing={6}>
                        <UsageSummaryCard
                            title="Summary"
                            entries={[
                                {
                                    value:
                                        teamUsersAndPhones?.users?.length ?? 0,
                                    label: 'Total Users',
                                },
                                {
                                    value:
                                        teamUsersAndPhones?.phones?.length ?? 0,
                                    label: 'Total Phones',
                                },
                            ]}
                        />
                        <UsageSummaryCard
                            title="SMS Usage"
                            Icon={MessageIcon}
                            entries={[
                                {
                                    value: teamAndUsersUsage.teamUsage
                                        .receivedMessagesTotal,
                                    label: 'Received',
                                },
                                {
                                    value: teamAndUsersUsage.teamUsage
                                        .sentMessagesTotal,
                                    label: 'Sent',
                                },
                            ]}
                        />
                        <UsageSummaryCard
                            title="Voice Usage"
                            Icon={DialpadIcon}
                            entries={[
                                {
                                    value: teamAndUsersUsage.teamUsage
                                        .sumOfAllCallMinutes,
                                    label: 'Minutes',
                                },
                                {
                                    value: teamAndUsersUsage.teamUsage
                                        .callsTotal,
                                    label: 'Calls',
                                },
                            ]}
                        />
                    </Stack>
                </Stack>

                <Box mt={1}>
                    <Stack>
                        <Typography variant="h5">User Usage Summary</Typography>
                    </Stack>
                </Box>
                <Box
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    mt={1}
                >
                    <TeamUsageTable
                        phones={teamUsersAndPhones?.phones ?? []}
                        teamUsers={teamUsersAndPhones?.users ?? []}
                        usage={teamAndUsersUsage.teamUsersUsage}
                        dateRange={usageDateRange}
                    />
                </Box>
            </Stack>
        </BasePage>
    );
}

export default TeamUsagePage;
