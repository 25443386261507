import React, { Fragment, ReactNode } from 'react';

// Authentication
import AuthService from './AuthService';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

/**
 * Ensures no page is shown without being authenticated
 * @component
 * @category Authentication
 * @SubCategory UI
 */
function RenderAuthenticated({ children }: { children: ReactNode }) {
    // Check that the user is logged in
    if (AuthService.isLoggedIn()) {
        return <Fragment>{children}</Fragment>;
    } else {
        // Should never reach here
        // Fallback to login button
        return (
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: '100vh',
                    width: '100%',
                    backgroundColor: '#D3D3D3',
                }}
            >
                <Button
                    variant="contained"
                    title="Login"
                    onClick={() => AuthService.doLogin()}
                    size="large"
                    endIcon={<LoginIcon />}
                >
                    Login
                </Button>
            </Stack>
        );
    }
}

export default RenderAuthenticated;
