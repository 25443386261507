import React, { ReactNode } from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Common Components
import TitleWithExitButton from './TitleWithExitButton';

type ConfirmDeleteDialogProps = {
    children?: ReactNode;
    isOpen: boolean;
    handleDialogClose(): void;
    handleDelete(): Promise<void>;
};
function ConfirmDeleteDialog({
    children,
    isOpen,
    handleDialogClose,
    handleDelete,
}: ConfirmDeleteDialogProps) {
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                <TitleWithExitButton
                    title="Confirm Delete"
                    exitAction={handleDialogClose}
                />
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={handleDelete} variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmDeleteDialog;
