import axios from 'axios';

// Models
import { User, UserType, VoiceCredentials } from 'Modules/Models/User';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets all users in a team through GET /api/User/Team
 * @returns An array of users or null if error
 */
async function getAllUsersInTeamAsync(teamId?: number): Promise<User[] | null> {
    let searchParams = new URLSearchParams();
    if (teamId != null) {
        searchParams.append('teamId', teamId.toString());
    }
    let response = await FetchDataWrapperAsync<User[]>(() =>
        axios.get(`/api/User/Team?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Gets all users in a organization through GET /api/User/Organization
 * @returns An array of users or null if error
 */
async function getAllUsersInOrganizationAsync(
    orgId?: number
): Promise<User[] | null> {
    let searchParams = new URLSearchParams();
    if (orgId != null) {
        searchParams.append('orgId', orgId.toString());
    }
    let response = await FetchDataWrapperAsync<User[]>(() =>
        axios.get(`/api/User/Organization?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Update a users password through /api/User/Password
 * @param password
 * @param userId
 * @returns True on success, false on error
 */
async function updateUserPasswordAsync(
    oldPassword: string,
    newPassword: string,
    userId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('oldPassword', oldPassword);
    searchParams.append('newPassword', newPassword);
    searchParams.append('userId', userId.toString());

    let response = await FetchDataWrapperAsync(() =>
        axios.put(`/api/User/Password?${searchParams}`, {}, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

/**
 * Adds a user through POST /api/User
 * @param username
 * @param password
 * @param userType
 * @param teamId
 * @param organizationId
 * @returns Id of created user or null if error
 */
async function addUserAsync(
    username: string,
    password: string,
    userType: UserType | string,
    teamId: number | null,
    organizationId: number | null
): Promise<number | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('username', username);
    searchParams.append('password', password);
    searchParams.append('userType', userType.toString());
    if (teamId != null) {
        searchParams.append('teamId', teamId.toString());
    }
    if (organizationId != null) {
        searchParams.append('organizationId', organizationId.toString());
    }
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.post(`/api/User?${searchParams}`, {}, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    if (response?.error) {
        throw response.data;
    }
    return null;
}

/**
 * Deletes users through DELETE /api/User
 * @param userIds
 * @returns True on success, false on error
 */
async function deleteUsersAsync(userIds: number[]): Promise<boolean> {
    let searchParams = new URLSearchParams();

    userIds.forEach((userId) => {
        searchParams.append('userIds', userId.toString());
    });
    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/User?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    if (response?.error) {
        throw response.data;
    }
    return false;
}

/**
 * Assign a user to team through POST /api/User/Team
 * @param teamId
 * @param userId
 * @returns True on success, false on error
 */
async function assignUserToTeamAsync(
    teamId: number,
    userIds: number[]
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('teamId', teamId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.post(`/api/User/Team?${searchParams}`, userIds, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Remove a user from team through Delete /api/User/Team
 * @param userId
 * @returns True on success, false on error
 */
async function removeUserFromTeamAsync(userId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('userId', userId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/User/Team?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

/**
 * Gets current user through GET /api/User
 * @returns An array of users or null if error
 */
async function getLoggedInUserAsync(): Promise<User | null> {
    let response = await FetchDataWrapperAsync<User>(() =>
        axios.get(`/api/User`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

/**
 * Gets current users Voice Credentials
 * @returns A Voice Credential Object
 */
async function getVoiceCredentials(): Promise<VoiceCredentials | null> {
    let response = await FetchDataWrapperAsync<VoiceCredentials>(() =>
        axios.get(`/api/User/SIPCreds`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

export {
    updateUserPasswordAsync,
    addUserAsync,
    deleteUsersAsync,
    assignUserToTeamAsync,
    getAllUsersInTeamAsync,
    getAllUsersInOrganizationAsync,
    removeUserFromTeamAsync,
    getLoggedInUserAsync,
    getVoiceCredentials,
};
