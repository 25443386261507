import React, { useState, useEffect, useCallback, useContext } from 'react';

// Common Components
import BasePage from 'HammerTemplate/PageTemplates/BasePage';
import PhoneBasedPage from 'Modules/Common/Components/PhoneBasedPage';

// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Call Components
import CallHistory from 'Modules/Calls/Components/CallHistory';
import CallWindow from 'Modules/Calls/Components/CallWindow';

// Models
import { Call, CallType } from 'Modules/Models/Call';
import { Phone } from 'Modules/Models/Phone';
import { Contact } from 'Modules/Models/Contact';

// Http
import { deleteCallAsync, getCallsAsync } from 'Modules/Http/CallInterface';
import { getContactsAsync } from 'Modules/Http/ContactInterface';

// SignalR
import { useSignalR } from 'HammerTemplate/CommonFunctions/SignalRProvider';
import { UnreadContentContext } from 'Modules/UnreadContentContextProvider';
import { getAllCurrentUsersPhonesAsync } from 'Modules/Http/PhoneInterface';

function CallsPage() {
    const unreadContext = useContext(UnreadContentContext);
    const [phones, setPhones] = useState<Phone[]>([]);
    const [selectedPhone, setSelectedPhone] = useState<Phone | null>(null);
    const [calls, setCalls] = useState<Call[]>([]);
    const [contacts, setContacts] = useState<Contact[]>([]);

    async function deleteCall(callId: number) {
        let res = await deleteCallAsync(callId);
        if (res) {
            setCalls((calls) => {
                return calls.filter((c) => c.id !== callId);
            });
        }
    }

    const getCallData = useCallback(async () => {
        if (selectedPhone != null) {
            let fetchedCalls = await getCallsAsync(selectedPhone.id);
            if (fetchedCalls != null) {
                setCalls(fetchedCalls);
            }
            let fetchedContacts = await getContactsAsync(selectedPhone.id);
            if (fetchedContacts != null) {
                setContacts(fetchedContacts);
            }
        }
    }, [selectedPhone]);

    async function fetchPhones() {
        let fetchedPhones = await getAllCurrentUsersPhonesAsync();
        if (fetchedPhones != null) {
            fetchedPhones = fetchedPhones.filter(
                (p) => p.outboundVoiceCapable || p.inboundVoiceCapable
            );
            setPhones(fetchedPhones);
            if (fetchedPhones.length > 0) {
                setSelectedPhone(fetchedPhones[0]);
            }
        }
    }
    useEffect(() => {
        getCallData();
        setPhones((phones) =>
            phones.map((phone) => {
                if (phone.id === selectedPhone?.id) {
                    phone.unseenPhoneCallsCount = 0;
                    return phone;
                }
                return phone;
            })
        );
        unreadContext.setSelectedPhonePageId(selectedPhone?.id ?? 0);
    }, [selectedPhone, getCallData]);

    useEffect(() => {
        fetchPhones();
        return () => unreadContext.setSelectedPhonePageId(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signalR: any = useSignalR();
    useEffect(() => {
        // function called on NewCDR signalR message
        async function NewCDR(call: Call) {
            // If the call is to the selected phone refresh the call data
            if (selectedPhone?.id === call.phoneId) {
                getCallData();
            } else {
                // If we are not selected into the phone update the phones
                if (call.callType === CallType.Missed) {
                    fetchPhones();
                }
            }
        }

        // Keys for signalR messages
        const MessageHandlers = [
            {
                key: 'NewCDR',
                func: NewCDR,
            },
        ];
        let cleanup = MessageHandlers.map(
            (entry) => {
                return signalR.subscribe(entry.key, (message: any) =>
                    entry.func(message)
                );
            },
            [signalR]
        );
        return () => {
            cleanup.forEach((clean) => clean());
        };
    }, [signalR, selectedPhone, getCallData, unreadContext]);
    return (
        <BasePage pageName="Calls">
            <PhoneBasedPage
                inputPhones={phones}
                phoneHeaderWidth={0.25}
                selectedPhone={selectedPhone}
                setSelectedPhone={setSelectedPhone}
                displayType="call"
            >
                <Stack
                    width="100%"
                    height="100%"
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-evenly"
                    spacing={3}
                >
                    <Box border={1} borderRadius={1} width="60%">
                        <CallHistory
                            callHistory={calls}
                            selectedPhone={selectedPhone}
                            getCallData={getCallData}
                            deleteCall={deleteCall}
                        />
                    </Box>
                    <Box border={1} borderRadius={1} width="40%">
                        <CallWindow
                            contacts={contacts}
                            selectedPhone={selectedPhone as Phone}
                            getCallData={getCallData}
                        />
                    </Box>
                </Stack>
            </PhoneBasedPage>
        </BasePage>
    );
}

export default CallsPage;
