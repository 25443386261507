import axios from 'axios';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Organization } from 'Modules/Models/Organization';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets all phones though GET /api/Admin/Phone endpoint
 * @returns An array of phones or null if error
 */
async function getAllPhonesAsync(): Promise<Phone[] | null> {
    let response = await FetchDataWrapperAsync<Phone[]>(() =>
        axios.get(`/api/Admin/Phones`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Gets all phones in an organization through GET /api/Admin/Organization endpoint
 * @param organizationId
 * @returns An array of phones or null if error
 */
async function getAllPhonesInOrganizationAsync(
    organizationId: number
): Promise<Phone[] | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('organizationId', organizationId.toString());
    let response = await FetchDataWrapperAsync<Phone[]>(() =>
        axios.get(
            `/api/Admin/Organization/Phones?${searchParams}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Adds a phone though POST /api/Admin/Phone endpoint
 * @param phone
 * @param networkProvider
 * @returns Id of created phone or null if error
 */
async function addPhoneAsync(
    phone: Phone,
    networkProvider: string
): Promise<number | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('networkProvider', networkProvider);
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.post(`/api/Admin/Phone?${searchParams}`, phone, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Deletes a phone through DELETE /api/Admin/Phone
 * @param phoneId
 * @returns True on success, false on error
 */
async function deletePhoneAsync(phoneId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', phoneId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/Admin/Phone?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Updates which organization a phone belongs to through PUT /api/Admin/Phone/Organization
 * @param organizationId
 * @param phoneId
 * @returns True on success, false on error
 */
async function updatePhoneOrganization(
    organizationId: number,
    phoneId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('organizationId', organizationId.toString());
    searchParams.append('phoneId', phoneId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.put(
            `/api/Admin/Phone/Organization?${searchParams}`,
            {},
            getAuthHeader()
        )
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Gets all organization though GET /api/Admin/Organization
 * @returns An array of organizations or null if error
 */
async function getAllOrganizationsAsync(): Promise<Organization[] | null> {
    let response = await FetchDataWrapperAsync<Organization[]>(() =>
        axios.get(`/api/Admin/Organization`, getAuthHeader())
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Adds an organization through POST /api/Admin/Organization
 * @param name
 * @returns Id of created organization or null
 */
async function addOrganizationsAsync(name: string): Promise<number | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('name', name);
    let response = await FetchDataWrapperAsync<number>(() =>
        axios.post(
            `/api/Admin/Organization?${searchParams}`,
            {},
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Deletes an organization though DELETE /api/Admin/Organization
 * @param organizationId
 * @returns True on success, false on error
 */
async function deleteOrganizationAsync(
    organizationId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('organizationId', organizationId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/Admin/Organization?${searchParams}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
export {
    getAllPhonesAsync,
    getAllPhonesInOrganizationAsync,
    addPhoneAsync,
    deletePhoneAsync,
    updatePhoneOrganization,
    getAllOrganizationsAsync,
    addOrganizationsAsync,
    deleteOrganizationAsync,
};
