import axios from 'axios';

// Models
import { Contact } from 'Modules/Models/Contact';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';

/**
 * Gets contacts for a phone through GET /api/Contact
 * @param id
 * @returns An array of contacts or null if error
 */
async function getContactsAsync(id: number): Promise<Contact[] | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', id.toString());

    let response = await FetchDataWrapperAsync<Contact[]>(() =>
        axios.get(`/api/Contact?${searchParams.toString()}`, getAuthHeader())
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Adds a contact through POST /api/Contact
 * @param phoneNumber
 * @param name
 * @param phoneId
 * @returns Id of created contact or null if error
 */
async function addContactAsync(
    phoneNumber: string,
    name: string,
    notes: string,
    phoneId: number
): Promise<number | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneNumber', phoneNumber);
    searchParams.append('name', name);
    searchParams.append('notes', notes);
    searchParams.append('phoneId', phoneId.toString());

    let response = await FetchDataWrapperAsync<number>(() =>
        axios.post(
            `/api/Contact?${searchParams.toString()}`,
            {},
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    if (response?.error) {
        throw response.data;
    }
    return null;
}
/**
 * Update a contact through PUT /api/Contact
 * @param contact
 * @returns True on success, false on error
 */
async function updateContactAsync(contact: Contact): Promise<boolean> {
    let response = await FetchDataWrapperAsync(() =>
        axios.put(`/api/Contact`, contact, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Deletes a contact through DELETE /api/Contact
 * @param contactId
 * @returns True on success, false on error
 */
async function deleteContactAsync(contactId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('contactId', contactId.toString());

    let response = await FetchDataWrapperAsync(() =>
        axios.delete(`/api/Contact?${searchParams.toString()}`, getAuthHeader())
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Deletes all phone contacts through DELETE /api/Contact/Phone
 * @param phoneId
 * @returns True on success, false on error
 */
async function deleteAllPhoneContactsAsync(phoneId: number): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', phoneId.toString());

    let response = await FetchDataWrapperAsync(() =>
        axios.delete(
            `/api/Contact/Phone?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Exports contacts through GET /api/Contact/Export
 * @param contactIds
 * @returns A csv string or null if error
 */
async function exportContactsAsync(
    contactIds: number[]
): Promise<string | null> {
    let searchParams = new URLSearchParams();

    contactIds.forEach((id) => {
        searchParams.append('contactIds', id.toString());
    });

    let response = await FetchDataWrapperAsync<string>(() =>
        axios.get(
            `/api/Contact/Export?${searchParams.toString()}`,
            getAuthHeader()
        )
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Imports contacts for a phone through POST /api/Contact/Import
 * @param fileContent
 * @param phoneId
 * @returns True on success, false on error
 */
async function importContactAsync(
    fileContent: string,
    phoneId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('phoneId', phoneId.toString());

    let authorization = getAuthHeader().headers.Authorization;

    let response = await FetchDataWrapperAsync(() =>
        axios.post(
            `/api/Contact/Import?${searchParams.toString()}`,
            fileContent,
            {
                headers: {
                    authorization,
                    'Content-Type': 'application/json',
                },
            }
        )
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
export {
    getContactsAsync,
    addContactAsync,
    updateContactAsync,
    deleteContactAsync,
    deleteAllPhoneContactsAsync,
    exportContactsAsync,
    importContactAsync,
};
