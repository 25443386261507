import AuthService from 'HammerTemplate/Authentication/AuthService';


function getAuthHeader() {
    return { headers: { Authorization: `Bearer ${AuthService.getToken()}` } };
}


/**
 * Function that generates the needed headers for fetching with authentication
 * @category Interface
 * @subcategory HTTP
 * @param {*} toFetch Fetch function
 * @param {*} parameters Parameters for fetch function
 * @returns The result of the fetch function or null if invalid token
 */
async function authenticatedFetch(
    toFetch: (...x: any | undefined) => any,
    parameters: Array<any> = []
) {
    return toFetch(
        getAuthHeader(),
        ...parameters
    );
}

/**
 * Function that generates the needed headers for fetching with authentication
 * @category Interface
 * @subcategory HTTP
 * @param {*} toFetch Fetch function
 * @param {*} parameters Parameters for fetch function
 * @returns The result of the fetch function or null if invalid token
 */
async function authenticatedFetchWithTimeout(
    timeoutMs: number,
    toFetch: (...x: any | undefined) => any,
    parameters: Array<any> = []
) {
    return toFetch(
        {
            ...getAuthHeader(),
            timeout: timeoutMs,
        },
        ...parameters
    );
}

export { getAuthHeader, authenticatedFetch, authenticatedFetchWithTimeout };
