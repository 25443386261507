import React, { useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

type SendMessageWindowProps = {
    sendSmsMessageAsync: (message: string) => Promise<void>;
    disabled: boolean;
};
function SendMessageWindow({
    sendSmsMessageAsync,
    disabled,
}: SendMessageWindowProps) {
    const [messageToSend, setMessageToSend] = useState<string>('');

    // Send an sms message
    async function sendSmsLocal() {
        setMessageToSend('');
        await sendSmsMessageAsync(messageToSend);
    }

    return (
        <Box p={2}>
            <TextField
                disabled={disabled}
                fullWidth
                onChange={(event) => setMessageToSend(event.target.value)}
                multiline
                variant="outlined"
                placeholder="Start typing a message..."
                value={messageToSend}
                onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                        sendSmsLocal();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <Button
                            disabled={disabled}
                            variant="contained"
                            onClick={() => sendSmsLocal()}
                            endIcon={<SendIcon />}
                            color={'secondary'}
                        >
                            Send
                        </Button>
                    ),
                }}
            />
        </Box>
    );
}
export default SendMessageWindow;
