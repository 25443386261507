import React, { useState } from 'react';

// Common
import { UsageDateRangeOptions } from 'Modules/Common/DateTools';

// Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
    DateRangePicker,
    DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker';

type UsageDateRangePickerProps = {
    dateRange: DateRange<Dayjs>;
    initialSelection?: UsageDateRangeOptions;
    handleUsageDateRangeChange: (dateRange: DateRange<Dayjs>) => void;
};

function UsageDateRangePicker({
    dateRange,
    initialSelection,
    handleUsageDateRangeChange,
}: UsageDateRangePickerProps) {
    const [usageRange, setUsageRange] = useState(initialSelection ?? 30);

    const handleUsageRangeChange = (event: SelectChangeEvent) => {
        setUsageRange(parseInt(event.target.value));
        handleUsageDateRangeChange([
            dayjs().subtract(parseInt(event.target.value), 'day'),
            dayjs(),
        ]);
    };

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: '', end: '' }}
            >
                <DateRangePicker
                    value={dateRange}
                    onChange={(newValue) => {
                        handleUsageDateRangeChange(newValue);
                        // Set range dropdown to 'custom' option
                        setUsageRange(0);
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField
                                {...startProps}
                                size="small"
                                sx={{ width: '13ch' }}
                            />
                            <Box sx={{ mx: 1 }}> to </Box>
                            <TextField
                                {...endProps}
                                size="small"
                                sx={{ width: '13ch' }}
                            />
                        </React.Fragment>
                    )}
                />
            </LocalizationProvider>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Usage Range</InputLabel>
                <Select
                    label="Usage Range"
                    value={usageRange.toString()}
                    onChange={handleUsageRangeChange}
                >
                    <MenuItem style={{ display: 'none' }} value={0}>
                        Custom
                    </MenuItem>
                    <MenuItem value={7}>Last 7 Days</MenuItem>
                    <MenuItem value={30}>Last 30 Days</MenuItem>
                    <MenuItem value={60}>Last 60 Days</MenuItem>
                    <MenuItem value={120}>Last 120 Days</MenuItem>
                </Select>
            </FormControl>
        </Stack>
    );
}

export default UsageDateRangePicker;
