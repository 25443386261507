import { UserType } from 'Modules/Models/User';

// Filter down to an array of UserTypes <= the supplied UserType
function filterDownRoles(
    maxRole: UserType,
    filterType: 'lessThan' | 'lessThanOrEqual'
) {
    if (filterType === 'lessThanOrEqual') {
        if (maxRole === UserType.SuperAdmin) {
            return [
                UserType.User,
                UserType.TeamAdmin,
                UserType.OrganizationAdmin,
                UserType.SuperAdmin,
            ];
        } else if (maxRole === UserType.OrganizationAdmin) {
            return [
                UserType.User,
                UserType.TeamAdmin,
                UserType.OrganizationAdmin,
            ];
        } else if (maxRole === UserType.TeamAdmin) {
            return [UserType.User, UserType.TeamAdmin];
        } else {
            return [UserType.User];
        }
    } else {
        if (maxRole === UserType.SuperAdmin) {
            return [
                UserType.User,
                UserType.TeamAdmin,
                UserType.OrganizationAdmin,
            ];
        } else if (maxRole === UserType.OrganizationAdmin) {
            return [UserType.User, UserType.TeamAdmin];
        } else if (maxRole === UserType.TeamAdmin) {
            return [UserType.User];
        } else {
            return [];
        }
    }
}
export { filterDownRoles };
