import React, { useEffect, useState } from 'react';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Team } from 'Modules/Models/Team';
import { Usage, UsageType } from 'Modules/Models/Usage';

// Http
import {
    getPhoneUsageOverDateRange,
    getTeamUsageOverDateRange,
} from 'Modules/Http/UsageInterface';

// Common
import {
    removeTimeFromDateRange,
    dateRangeToUsageDateRangeOptions,
} from 'Modules/Common/DateTools';

// Material UI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Date Range
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import UsageDateRangePicker from 'Modules/Usage/Components/UsageDateRangePicker';

// Rechart
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

type UsageGraphProps = {
    phone?: Phone;
    team?: Team;
    initialDateRange: DateRange<Dayjs>;
};

function UsageGraph({ phone, team, initialDateRange }: UsageGraphProps) {
    const [graphType, setGraphType] = useState<UsageType>(UsageType.SMS);

    let graphName = '';
    if (phone) {
        graphName = phone.nickname + ' - Usage';
    } else if (team) {
        graphName = team.name + ' - Usage';
    }

    const handleGraphTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newGraphType: UsageType
    ) => {
        setGraphType(newGraphType);
    };

    const [usageDateRange, setUsageDateRange] = useState(initialDateRange);

    const [usage, setUsage] = useState<Usage[]>([]);

    const handleUsageDateRangeChange = (dateRange: DateRange<Dayjs>) => {
        setUsageDateRange(dateRange);
        fetchUsage(dateRange);
    };

    const fetchUsage = async (dateRange: DateRange<Dayjs>) => {
        dateRange = removeTimeFromDateRange(dateRange);
        let fetchUsage: Usage[] | null = [];
        if (phone) {
            fetchUsage = await getPhoneUsageOverDateRange(
                phone.id,
                graphType,
                dateRange[0]?.toDate(),
                dateRange[1]?.toDate()
            );
        } else if (team) {
            fetchUsage = await getTeamUsageOverDateRange(
                team.id,
                graphType,
                dateRange[0]?.toDate(),
                dateRange[1]?.toDate()
            );
        }
        if (fetchUsage !== null) {
            setUsage(fetchUsage);
        }
    };

    const usageToGraphData = (usageData: Usage[]) => {
        return usageData.map((usage, i) => ({
            date: usage.usageDay
                ? new Date(usage.usageDay).toLocaleDateString()
                : '',
            'SMS Sent': usage.sentMessagesTotal,
            'SMS Received': usage.receivedMessagesTotal,
            'Total Minutes': usage.sumOfAllCallMinutes,
        }));
    };
    // Load initial usage on page load
    useEffect(() => {
        fetchUsage([dayjs().subtract(30, 'day'), dayjs()]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchUsage([dayjs().subtract(30, 'day'), dayjs()]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphType]);
    return (
        <Box
            display="grid"
            height={'70vh'}
            gridTemplateColumns="100%"
            gridTemplateRows="20% 80%"
            p={3}
            mx={2}
        >
            <Stack spacing={2} alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle2" component="div">
                        Graph Usage Type:
                    </Typography>
                    <ToggleButtonGroup
                        size="small"
                        value={graphType}
                        exclusive
                        onChange={handleGraphTypeChange}
                    >
                        <ToggleButton value={UsageType.Call}>
                            Voice
                        </ToggleButton>
                        <ToggleButton value={UsageType.SMS}>SMS</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle2" component="div">
                        Usage Time-frame:
                    </Typography>
                    <UsageDateRangePicker
                        dateRange={usageDateRange}
                        initialSelection={dateRangeToUsageDateRangeOptions(
                            usageDateRange
                        )}
                        handleUsageDateRangeChange={handleUsageDateRangeChange}
                    />
                </Stack>
            </Stack>
            <Box sx={{ sb: -6 }}>
                <Stack alignItems="center" sx={{ mt: 2, mb: 1 }}>
                    <Typography variant="h6" component="div">
                        {graphName}
                    </Typography>
                </Stack>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={usageToGraphData(usage)}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" height={60} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {graphType === UsageType.SMS && (
                            <React.Fragment>
                                <Line
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="SMS Sent"
                                    stroke="#8884d8"
                                    activeDot={{ r: 4 }}
                                />
                                <Line
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="SMS Received"
                                    stroke="#82ca9d"
                                    activeDot={{ r: 4 }}
                                />
                            </React.Fragment>
                        )}
                        {graphType === UsageType.Call && (
                            <Line
                                strokeWidth={2}
                                type="monotone"
                                dataKey="Total Minutes"
                                stroke="#8884d8"
                                activeDot={{ r: 4 }}
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}

export default UsageGraph;
