import React, { useState } from 'react';
// Waypoint
import { Waypoint } from 'react-waypoint';

// HammerTemplate
import { useRightClickState } from 'HammerTemplate/CommonFunctions/RightClick';

// Common Models
import { CenterLabelHeader } from 'Modules/Common/Components/LayoutComponents';
// Material UI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';

// Models
import { Conversation } from 'Modules/Models/Conversation';

import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';
type ConversationListProps = {
    conversations: Conversation[];
    fetchAdditionalConversations: () => Promise<void>;
    deleteConversation: (conversationId: number) => Promise<void>;
    selectedConversationIndex: number;
    selectNewConversation: (
        conversationId: number,
        markAsRead: boolean
    ) => void;
    handleConversationDialogClickOpen: () => void;
    disabled?: boolean;
};
function ConversationList({
    conversations,
    fetchAdditionalConversations,
    deleteConversation,
    selectedConversationIndex,
    selectNewConversation,
    handleConversationDialogClickOpen,
    disabled = false,
}: ConversationListProps) {
    const handleListItemClick = async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        conversation: Conversation
    ) => {
        selectNewConversation(
            conversation.id,
            conversation.unread === 0 ? false : true
        );
    };

    // Right click on conversationList
    const [clickPosition, setClickPosition] = useState<Element | null>(null);
    const {
        handleClick,
        getPosition,
        close: closeContextMenu,
    } = useRightClickState(setClickPosition);
    const [rightClickedConversation, setRightClickedConversation] =
        useState<number>(-1);
    const position = getPosition();
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Menu
                anchorReference="anchorPosition"
                open={position != null}
                onClose={closeContextMenu}
                anchorPosition={
                    position !== null
                        ? {
                              top: position.mouseY,
                              left: position.mouseX,
                          }
                        : undefined
                }
            >
                <MenuItem
                    onClick={() => {
                        deleteConversation(rightClickedConversation);
                        closeContextMenu();
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
            <Stack height="100%" width="100%">
                <Stack pt={0.5} pb={0.5} direction="row">
                    <CenterLabelHeader>
                        <Typography variant="h6">
                            <b>Conversation List</b>
                        </Typography>
                    </CenterLabelHeader>
                    <IconButton
                        disabled={disabled}
                        onClick={handleConversationDialogClickOpen}
                    >
                        <Tooltip
                            title="Start Conversation"
                            disableFocusListener
                        >
                            <AddCircleIcon sx={{ fontSize: 27 }} />
                        </Tooltip>
                    </IconButton>
                </Stack>
                <Divider />
                {conversations.length !== 0 ? (
                    <List sx={{ height: '100%', overflow: 'auto' }}>
                        {conversations.map((conversation, i) => (
                            <React.Fragment key={i}>
                                <ListItemButton
                                    dense
                                    selected={
                                        selectedConversationIndex ===
                                        conversation.id
                                    }
                                    onClick={(event) =>
                                        handleListItemClick(event, conversation)
                                    }
                                    onContextMenu={(event) => {
                                        handleClick(event);
                                        setRightClickedConversation(
                                            conversation.id
                                        );
                                    }}
                                >
                                    <ListItemText
                                        sx={{
                                            userSelect: 'text',
                                            width: '100%',
                                        }}
                                        primary={
                                            <b>
                                                {conversation.contact != null
                                                    ? conversation.contact.name
                                                    : formatNumberInternational(
                                                          conversation.otherParty
                                                      )}
                                            </b>
                                        }
                                        primaryTypographyProps={{
                                            variant: 'subtitle1',
                                        }}
                                        secondaryTypographyProps={{
                                            maxWidth: 300,
                                            width: '80%',
                                            display: 'inline-block',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            paddingTop: 0.5,
                                        }}
                                        secondary={
                                            conversation.lastMessage
                                                .messageContent
                                        }
                                    />
                                    {conversation.unread !== 0 ? (
                                        <Badge
                                            badgeContent={conversation.unread}
                                            color="primary"
                                        >
                                            <TextsmsIcon />
                                        </Badge>
                                    ) : null}
                                </ListItemButton>
                                <Divider component="li" />
                                {i === conversations.length - 1 && (
                                    <Waypoint
                                        onEnter={() =>
                                            fetchAdditionalConversations()
                                        }
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Button
                        variant="contained"
                        onClick={handleConversationDialogClickOpen}
                        disabled={disabled}
                    >
                        Start Conversation
                    </Button>
                )}
            </Stack>
        </Box>
    );
}

export default ConversationList;
