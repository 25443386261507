import React, {
    useState,
    useEffect,
    ReactNode,
    createContext,
    useRef,
} from 'react';

// Sip.js
import { UserAgent, SessionState } from 'sip.js';
import {
    Invitation,
    Inviter,
    Registerer,
    UserAgentOptions,
} from 'sip.js/lib/api';
import { URI } from 'sip.js/lib/grammar';

// Models
import { Phone } from 'Modules/Models/Phone';
import { Contact } from 'Modules/Models/Contact';

// Http
import { getInboundCallInfoAsync } from 'Modules/Http/CallInterface';
import { getVoiceCredentials } from 'Modules/Http/UserInterface';

import IncomingCallAudio from 'Audio/mixkit-marimba-ringtone-1359.wav';
import DialingCallAudio from 'Audio/Phone_Ringing_8x-Mike_Koenig-696238708.wav';

export enum CallStatus {
    Ringing = 'Ringing',
    IncomingCall = 'IncomingCall',
    Connecting = 'Connecting',
    CallInProgress = 'CallInProgress',
    Standby = 'Standby',
}
type VoiceContextType = {
    currentConnectionStatus: boolean;
    currentCallStatus: CallStatus;
    currentPhoneInCall: Phone | null;
    currentOtherParty: string | Contact | null;
    micEnabled: boolean;
    setCurrentCallStatus: React.Dispatch<React.SetStateAction<CallStatus>>;
    callUser(destination: string | Contact, origin: Phone): Promise<void>;
    hangup(): Promise<void>;
    acceptIncomingCall(): Promise<void>;
    rejectIncomingCall(): Promise<void>;
    setMicMuteState(enabled: boolean): Promise<void>;
};
export const VoiceContext = createContext<VoiceContextType>(
    {} as VoiceContextType
);

type VoiceContextProviderProps = {
    children: ReactNode;
};

function getHostName() {
    return window.location.hostname.replace('www.', '');
}

function VoiceContextProvider({ children }: VoiceContextProviderProps) {
    // Audio
    const audioElement = useRef<HTMLAudioElement | null>(null);
    const remoteStream = new MediaStream();
    function setupRemoteMedia(session: any) {
        if (audioElement.current != null) {
            if (session.sessionDescriptionHandler != null) {
                session.sessionDescriptionHandler.peerConnection
                    .getReceivers()
                    .forEach((receiver: any) => {
                        if (receiver.track) {
                            remoteStream.addTrack(receiver.track);
                        }
                    });
                audioElement.current.srcObject = remoteStream;
                audioElement.current.play();
            }
        }
    }
    function cleanupMedia() {
        if (audioElement.current != null) {
            audioElement.current.srcObject = null;
            audioElement.current.pause();
        }
    }

    // Current Call Status
    const [currentCallStatus, setCurrentCallStatus] = useState<CallStatus>(
        CallStatus.Standby
    );

    // Current Connection Status
    const [currentConnectionStatus, setCurrentConnectionStatus] =
        useState<boolean>(false);

    // Current Phone being used
    const [currentPhoneInCall, setCurrentPhoneInCall] = useState<Phone | null>(
        null
    );

    // Current Other party
    const [currentOtherParty, setCurrentOtherParty] = useState<
        string | Contact | null
    >(null);

    // Current Mute Status
    const [micEnabled, setMicEnabled] = useState<boolean>(true);

    const userAgent = useRef<UserAgent | null>(null);
    const inboundRequest = useRef<Invitation | null>(null);
    const outboundRequest = useRef<Inviter | null>(null);

    async function onInvite(invitation: Invitation) {
        invitation.stateChange.addListener((newState) => {
            switch (newState) {
                case SessionState.Establishing:
                    setCurrentCallStatus(CallStatus.Connecting);
                    break;
                case SessionState.Established:
                    setupRemoteMedia(invitation);
                    setCurrentCallStatus(CallStatus.CallInProgress);
                    break;
                case SessionState.Terminated:
                    cleanupMedia();
                    setCurrentCallStatus(CallStatus.Standby);
                    setCurrentPhoneInCall(null);
                    setCurrentOtherParty(null);
                    setMicEnabled(true);
                    inboundRequest.current = null;
                    break;
            }
        });
        let otherPartyString = invitation.request.from.displayName;
        let phoneNumber = invitation.request.getHeader('P-Called-Party-ID');
        if (phoneNumber == null) {
            return;
        }
        let inboundCallInfo = await getInboundCallInfoAsync(
            phoneNumber,
            otherPartyString
        );
        if (inboundCallInfo == null) {
            setCurrentPhoneInCall({
                phoneNumber,
                id: 0,
                nickname: '',
                unreadSmsCount: 0,
                unseenPhoneCallsCount: 0,
                inboundSmsCapable: true,
                outboundSmsCapable: true,
                inboundVoiceCapable: true,
                outboundVoiceCapable: true,
                isDeleted: false,
                organizationId: 0,
                teamId: 0,
                userId: 0,
                networkProvider: '',
                creationDate: '',
            });
            setCurrentOtherParty(otherPartyString);
        } else {
            setCurrentPhoneInCall(inboundCallInfo.phone);
            if (inboundCallInfo.contact == null) {
                setCurrentOtherParty(otherPartyString);
            } else {
                setCurrentOtherParty(inboundCallInfo.contact);
            }
        }
        setCurrentCallStatus(CallStatus.IncomingCall);
        inboundRequest.current = invitation;
    }

    function onConnect() {
        setCurrentConnectionStatus(true);
    }

    function onDisconnect() {
        setCurrentConnectionStatus(false);
    }

    async function connectToServer(
        username: string,
        password: string,
        coturnUsername: string,
        coturnPassword: string
    ) {
        const userAgentOptions: UserAgentOptions = {
            authorizationUsername: username,
            authorizationPassword: password,
            transportOptions: {
                server: `wss://${getHostName()}:8089/ws`,
                stunServers: { urls: `stun:${getHostName()}:5349` },
                turnServers: {
                    urls: `turn:${getHostName()}:5349`,
                    username: coturnUsername,
                    password: coturnPassword,
                },
            },
            uri: UserAgent.makeURI(`sip:${username}@${getHostName()}`),
            contactName: username,
            delegate: {
                onInvite,
                onConnect,
                onDisconnect,
            },
        };
        let uA = new UserAgent(userAgentOptions);
        const uARegisterer = new Registerer(uA);
        uA.start()
            .then(async () => {
                await uARegisterer.register();
                userAgent.current = uA;
                setCurrentConnectionStatus(true);
            })
            .catch((error: Error) => {
                setCurrentConnectionStatus(false);
            });
    }

    async function callUser(destination: string | Contact, origin: Phone) {
        if (userAgent.current == null) {
            return;
        }
        setCurrentPhoneInCall(origin);
        setCurrentOtherParty(destination);
        let otherPartyPhoneNumber: string;
        if (typeof destination === 'string') {
            otherPartyPhoneNumber = destination;
        } else {
            otherPartyPhoneNumber = destination.phoneNumber;
        }
        let sipAddress = UserAgent.makeURI(
            `sip:${otherPartyPhoneNumber}@${getHostName()}`
        );

        const inviter = userAgent.current._makeInviter(sipAddress as URI, {
            params: { fromDisplayName: origin.phoneNumber },
        });

        inviter.stateChange.addListener((newState) => {
            switch (newState) {
                case SessionState.Established:
                    setupRemoteMedia(inviter);
                    setCurrentCallStatus(CallStatus.CallInProgress);
                    break;
                case SessionState.Terminated:
                    cleanupMedia();
                    setCurrentCallStatus(CallStatus.Standby);
                    setCurrentPhoneInCall(null);
                    setCurrentOtherParty(null);
                    setMicEnabled(true);
                    outboundRequest.current = null;
                    break;
                default:
                    break;
            }
        });
        setCurrentCallStatus(CallStatus.Ringing);
        await inviter
            .invite({})
            .then((outgoingInviteRequest) => {
                // Invite sent
            })
            .catch((error: Error) => {
                // Invite did not send
                setCurrentCallStatus(CallStatus.Standby);
            });
        outboundRequest.current = inviter;
    }
    async function hangup() {
        if (userAgent.current != null) {
            // If we are in an outbound request
            if (outboundRequest.current != null) {
                switch (outboundRequest.current.state) {
                    case SessionState.Establishing:
                        // An unestablished outgoing session
                        await outboundRequest.current.cancel();
                        break;
                    case SessionState.Established:
                        // An established session
                        await outboundRequest.current.bye();
                        break;
                }
            }
            // If we are in an inbound request
            if (inboundRequest.current != null) {
                if (inboundRequest.current.state === SessionState.Established) {
                    await inboundRequest.current.bye();
                }
            }
        }
    }
    async function changeMuteStatus(
        sessionDescriptionHandler: any,
        enable: boolean
    ): Promise<boolean> {
        let peer = sessionDescriptionHandler.peerConnection;
        let senders = peer.getSenders();
        if (!senders.length) {
            return false;
        }
        senders.forEach(function (sender: any) {
            if (sender.track) {
                sender.track.enabled = enable;
            }
        });
        return true;
    }
    async function setMicMuteState(enabled: boolean) {
        // Set mute for the inbound call
        if (inboundRequest.current != null) {
            if (
                await changeMuteStatus(
                    inboundRequest.current.sessionDescriptionHandler,
                    enabled
                )
            ) {
                setMicEnabled(enabled);
            }
        }
        // Set mute for the outbound call
        if (outboundRequest.current != null) {
            if (
                await changeMuteStatus(
                    outboundRequest.current.sessionDescriptionHandler,
                    enabled
                )
            ) {
                setMicEnabled(enabled);
            }
        }
    }

    async function acceptIncomingCall() {
        if (userAgent.current != null && inboundRequest.current != null) {
            await inboundRequest.current.accept();
        }
    }
    async function rejectIncomingCall() {
        if (userAgent.current != null && inboundRequest.current != null) {
            await inboundRequest.current.reject();
        }
    }
    useEffect(() => {
        const setup = async () => {
            navigator.mediaDevices
                .getUserMedia({ video: false, audio: true })
                .then(async (stream) => {
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });

                    let userSIPAccount = await getVoiceCredentials();
                    if (userSIPAccount) {
                        connectToServer(
                            userSIPAccount.username,
                            userSIPAccount.password,
                            userSIPAccount.coturnUsername,
                            userSIPAccount.coturnPassword
                        );
                    }
                })
                .catch((err) => {
                    console.error(`Error getting Audio: ${err}`);
                });
        };
        // fetch credentials here
        setup();
    }, []);

    return (
        <VoiceContext.Provider
            value={{
                currentConnectionStatus,
                currentCallStatus,
                setCurrentCallStatus,
                currentPhoneInCall,
                currentOtherParty,
                micEnabled,
                callUser,
                hangup,
                acceptIncomingCall,
                rejectIncomingCall,
                setMicMuteState,
            }}
        >
            <audio ref={audioElement}></audio>
            {currentCallStatus === CallStatus.IncomingCall && (
                <audio autoPlay loop src={IncomingCallAudio} />
            )}

            {currentCallStatus === CallStatus.Ringing && (
                <audio autoPlay loop src={DialingCallAudio} />
            )}

            {children}
        </VoiceContext.Provider>
    );
}
export default VoiceContextProvider;
