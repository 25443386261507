import { Team } from 'Modules/Models/Team';
export enum UserType {
    User = 'User',
    TeamAdmin = 'TeamAdmin',
    OrganizationAdmin = 'OrganizationAdmin',
    SuperAdmin = 'SuperAdmin',
}

export type User = {
    id: number;
    username: string;
    userType: UserType;
    lastActivity: Date;
    currentTeam: Team | null;
    organizationId: number;
    teamId: number;
};

export type VoiceCredentials = {
    username: string;
    password: string;
    coturnUsername: string;
    coturnPassword: string;
};
