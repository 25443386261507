import axios from 'axios';

// Models
import { Conversation } from 'Modules/Models/Conversation';
import { Message } from 'Modules/Models/Message';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync from 'HammerTemplate/CommonFunctions/FetchWrapper';
/**
 * Gets conversations for a phone through GET /api/Conversation
 * @param startingIndex
 * @param numConversationsToGet
 * @param phoneId
 * @returns An array of conversations or null if error
 */
async function getPhoneConversationsAsync(
    startingIndex: number,
    numConversationsToGet: number,
    phoneId: number
): Promise<Conversation[] | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('startingIndex', startingIndex.toString());
    searchParams.append(
        'numConversationsToGet',
        numConversationsToGet.toString()
    );
    searchParams.append('phoneId', phoneId.toString());
    let response = await FetchDataWrapperAsync<Conversation[] | null>(() =>
        axios.get(
            `/api/Conversation?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}
/**
 * Gets messages for a conversation through GET /api/Conversation/Messages
 * @param startingIndex
 * @param numMessagesToGet
 * @param conversationId
 * @returns An array of messages or null if error
 */
async function getConversationMessagesAsync(
    startingIndex: number,
    numMessagesToGet: number,
    conversationId: number
): Promise<Message[] | null> {
    let searchParams = new URLSearchParams();
    searchParams.append('startingIndex', startingIndex.toString());
    searchParams.append('numMessagesToGet', numMessagesToGet.toString());
    searchParams.append('conversationId', conversationId.toString());
    let response = await FetchDataWrapperAsync<Message[] | null>(() =>
        axios.get(
            `/api/Conversation/Messages?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

async function markAllConversationMessagesAsReadAsync(
    conversationId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('conversationId', conversationId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.put(
            `/api/Conversation?${searchParams.toString()}`,
            {},
            getAuthHeader()
        )
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}
/**
 * Deletes a conversation through DELETE api/Conversation
 * @param conversationId
 * @returns True on success, false on error
 */
async function deleteConversationAsync(
    conversationId: number
): Promise<boolean> {
    let searchParams = new URLSearchParams();
    searchParams.append('conversationId', conversationId.toString());
    let response = await FetchDataWrapperAsync(() =>
        axios.delete(
            `/api/Conversation?${searchParams.toString()}`,
            getAuthHeader()
        )
    );
    if (response && !response.error) {
        return true;
    }
    return false;
}

export {
    getPhoneConversationsAsync,
    getConversationMessagesAsync,
    markAllConversationMessagesAsReadAsync,
    deleteConversationAsync,
};
