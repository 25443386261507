import React, { useMemo, useState, useEffect, Suspense } from 'react';

// Routing
import { Route, Routes, Navigate } from 'react-router';

// Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// Types
import { PageGroup } from 'HammerTemplate/HammerTemplateTypes';

// Authentication
import AuthService from 'HammerTemplate/Authentication/AuthService';

// Components
import SideBar, { SideBarGroupProps } from './components/SideBar';
import HeaderBar from './components/HeaderBar';

export type SideBarProps = {
    sideBarGroupProps?: SideBarGroupProps;
    sideBarFixed?: boolean;
    defaultSideBarOpen?: boolean;
};

export interface LayoutProps {
    appTitle: string;
    pageSetGenerator: () => Promise<PageGroup[]> | PageGroup[];
    HeaderBarAddOn?: React.FC;
    sideBarProps?: SideBarProps;
    version?: string;
    additionalComponents?: React.ReactNode;
}

function Layout({
    appTitle,
    pageSetGenerator,
    additionalComponents,
    ...props
}: LayoutProps) {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(
        !!props.sideBarProps?.defaultSideBarOpen
    );

    const [pageGroups, setPageGroups] = useState<PageGroup[] | null>(null);

    useEffect(() => {
        async function setupPages() {
            setPageGroups(await pageSetGenerator());
        }
        setupPages();
    }, [pageSetGenerator]);

    const {
        routeArray,
        firstRoute,
    }: { routeArray: React.ReactElement[]; firstRoute: string } =
        useMemo(() => {
            if (pageGroups == null) {
                return { routeArray: [], firstRoute: '' };
            }
            let firstRoute: string = '';
            let routeArray: React.ReactElement[] = [];
            pageGroups.forEach((group) => {
                if ('pages' in group && group.pages.length > 0) {
                    group.pages.forEach((page) => {
                        if (firstRoute === '') {
                            firstRoute = group.route + page.route;
                        }
                        routeArray.push(
                            <Route
                                key={group.route + page.route}
                                path={group.route + page.route}
                                element={<page.page />}
                            />
                        );
                    });
                } else if ('page' in group) {
                    if (firstRoute === '') {
                        firstRoute = group.route;
                    }
                    routeArray.push(
                        <Route
                            key={group.route}
                            path={group.route}
                            element={<group.page />}
                        />
                    );
                }
            });
            return { routeArray, firstRoute };
        }, [pageGroups]);

    console.log(AuthService.getToken());

    const loading = (
        <Stack
            sx={{ width: '100vw', height: '100vh' }}
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress />
        </Stack>
    );

    if (pageGroups == null) {
        return loading;
    }

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'grid',
                gridTemplateRows: 'min-content auto',
            }}
        >
            <>{additionalComponents}</>
            <HeaderBar
                appTitle={appTitle}
                toggleDrawer={
                    props.sideBarProps?.sideBarFixed
                        ? undefined
                        : () => setDrawerOpen(!drawerOpen)
                }
                HeaderBarAddOn={props.HeaderBarAddOn}
            />

            <Box
                sx={{
                    display: 'flex',
                    overflowY: 'hidden',
                    overflowX: 'visible',
                    bgcolor: 'theme.palette.background',
                }}
            >
                <SideBar
                    drawerOpen={
                        props.sideBarProps?.sideBarFixed ? true : drawerOpen
                    }
                    pageGroups={pageGroups}
                    sideBarGroupProps={props.sideBarProps?.sideBarGroupProps}
                    version={props?.version}
                />
                <Suspense fallback={loading}>
                    <Routes>
                        {routeArray}
                        <Route
                            path="*"
                            element={<Navigate to={firstRoute} replace />}
                        />
                    </Routes>
                </Suspense>
            </Box>
        </Box>
    );
}

export default Layout;
