import React from 'react';

// Auth
import RenderAuthenticated from './Authentication/RenderAuthenticated';

// Theme
import Theme from 'HammerTemplate/Layout/Theme';
import { ThemeOptions } from '@mui/material/styles';

// Layout
import Layout, { LayoutProps } from 'HammerTemplate/Layout/Layout';

// Material UI
import CssBaseline from '@mui/material/CssBaseline';

type HammerTemplateProps = LayoutProps & { themeOptions?: ThemeOptions };

function HammerTemplate({ themeOptions, ...props }: HammerTemplateProps) {
    return (
        <CssBaseline>
            <Theme themeOptions={themeOptions}>
                <RenderAuthenticated>
                    <Layout {...props} />
                </RenderAuthenticated>
            </Theme>
        </CssBaseline>
    );
}

export default HammerTemplate;
