import React from 'react';

import { useNavigate } from 'react-router';
// Auth
import AuthService from 'HammerTemplate/Authentication/AuthService';

// Material UI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import LockResetIcon from '@mui/icons-material/LockReset';

// Models
import { User, UserType } from 'Modules/Models/User';

function AccountMenu(currentUser: User) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/account/password')}>
                    <ListItemIcon>
                        <LockResetIcon fontSize="small" />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem onClick={() => AuthService.doLogout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Sign Out
                </MenuItem>
            </Menu>
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="flex-end"
            >
                <Stack>
                    <Typography variant="h6">{currentUser.username}</Typography>
                    {currentUser.userType !== UserType.User && (
                        <Typography variant="caption">
                            {currentUser.userType}
                        </Typography>
                    )}
                </Stack>
                <IconButton onClick={handleClick} size="small">
                    <Avatar sx={{ bgcolor: 'secondary.main' }} />
                </IconButton>
            </Stack>
        </React.Fragment>
    );
}
export default AccountMenu;
