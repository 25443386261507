import React, { useContext, useState } from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MenuIcon from '@mui/icons-material/Menu';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleIcon from '@mui/icons-material/Article';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

// Models
import { Call, CallType } from 'Modules/Models/Call';
import { Phone } from 'Modules/Models/Phone';

// Context
import { CallStatus, VoiceContext } from 'Modules/VoiceContextProvider';

// Common Components
import ContactDialog from 'Modules/Messages/Components/ContactDialog';

// Phone number validating
import { formatNumberInternational } from 'Modules/Common/PhoneNumberFormatter';

enum CallHistoryViewOptions {
    Missed,
    All,
}

type CallHistoryProps = {
    callHistory: Call[];
    selectedPhone: Phone | null;
    getCallData(): Promise<void>;
    deleteCall(callId: number): Promise<void>;
};
function CallHistory({
    callHistory,
    selectedPhone,
    getCallData,
    deleteCall,
}: CallHistoryProps) {
    const phone = useContext(VoiceContext);
    const [callHistorySelector, setCallHistorySelector] =
        useState<CallHistoryViewOptions>(CallHistoryViewOptions.All);
    const [callHistoryFilter, setCallHistoryFilter] = useState<string>('');
    const [currentCallMenuOpen, setCurrentCallMenuOpen] = useState<Call | null>(
        null
    );
    const [contactMenuOpen, setContactMenuOpen] = useState<boolean>(false);
    async function handleContactDialogClose(updateContacts?: boolean) {
        setContactMenuOpen(false);
        if (updateContacts) {
            await getCallData();
        }
    }
    const [menuPosition, setMenuPosition] = useState<null | HTMLElement>(null);
    const open = Boolean(menuPosition);

    function handleMenuButtonClick(
        event: React.MouseEvent<HTMLButtonElement>,
        call: Call
    ) {
        setMenuPosition(event.currentTarget);
        setCurrentCallMenuOpen(call);
    }

    function callRecentCallClick() {
        if (selectedPhone != null && currentCallMenuOpen != null) {
            if (currentCallMenuOpen.contact != null) {
                phone.callUser(currentCallMenuOpen.contact, selectedPhone);
            } else {
                phone.callUser(currentCallMenuOpen.otherParty, selectedPhone);
            }
            setMenuPosition(null);
        }
    }
    function createContactClick() {
        setContactMenuOpen(true);
        setMenuPosition(null);
    }

    async function deleteCallClick() {
        if (currentCallMenuOpen != null) {
            await deleteCall(currentCallMenuOpen.id);
        }
        setMenuPosition(null);
    }

    // Filter down calls based on user input in search box
    function filterCalls(): Call[] {
        let filteredCalls = callHistory.filter(
            (c) =>
                c.contact?.name
                    .toLowerCase()
                    .includes(callHistoryFilter.toLowerCase()) ||
                c.otherParty.includes(callHistoryFilter)
        );
        if (callHistorySelector === CallHistoryViewOptions.Missed) {
            filteredCalls = filteredCalls.filter(
                (c) => c.callType === CallType.Missed
            );
        }
        return filteredCalls;
    }
    function formatDate(date: Date): string {
        let newDate = new Date(date);
        return newDate.toLocaleString();
    }
    return (
        <React.Fragment>
            <Menu
                id="basic-menu"
                anchorEl={menuPosition}
                open={open}
                onClose={() => setMenuPosition(null)}
            >
                {phone.currentCallStatus === CallStatus.Standby &&
                    selectedPhone?.outboundVoiceCapable && (
                        <MenuItem onClick={() => callRecentCallClick()}>
                            <ListItemIcon>
                                <LocalPhoneIcon />
                            </ListItemIcon>
                            Call
                        </MenuItem>
                    )}
                {currentCallMenuOpen?.contact == null && (
                    <MenuItem onClick={() => createContactClick()}>
                        <ListItemIcon>
                            <AddCircleIcon />
                        </ListItemIcon>
                        Create Contact
                    </MenuItem>
                )}
                <MenuItem onClick={() => deleteCallClick()}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
            {contactMenuOpen && (
                <ContactDialog
                    isOpen={contactMenuOpen}
                    handleContactDialogClose={handleContactDialogClose}
                    selectedPhoneNumber={currentCallMenuOpen?.otherParty}
                    selectedPhoneIndex={selectedPhone?.id}
                    contact={null}
                />
            )}
            <Grid2 width="100%" height="100%" container>
                <Grid2 xs={12} height="15%">
                    <Stack
                        width="100%"
                        height="100%"
                        pl={2}
                        pr={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom={1}
                    >
                        <Typography variant="h5">
                            <b>Call History</b>
                        </Typography>
                        <TextField
                            size="small"
                            onChange={(event) =>
                                setCallHistoryFilter(event.target.value)
                            }
                            value={callHistoryFilter}
                            label="Search Calls"
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={() => setCallHistoryFilter('')}
                                    >
                                        {callHistoryFilter.length > 0 ? (
                                            <ClearIcon />
                                        ) : (
                                            ''
                                        )}
                                    </IconButton>
                                ),
                            }}
                        />
                        <ToggleButtonGroup
                            color="primary"
                            value={callHistorySelector}
                            exclusive
                            onChange={(
                                event,
                                newAlignment: CallHistoryViewOptions
                            ) => setCallHistorySelector(newAlignment)}
                        >
                            <ToggleButton value={CallHistoryViewOptions.All}>
                                All
                            </ToggleButton>
                            <ToggleButton value={CallHistoryViewOptions.Missed}>
                                Missed
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Grid2>
                <Grid2 xs={12} height="85%" overflow="auto">
                    {callHistory.length === 0 ? (
                        <Box
                            width="100%"
                            height="80%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Stack alignItems="center" justifyContent="center">
                                <Typography variant="h5">
                                    No call history
                                </Typography>
                                <ArticleIcon sx={{ fontSize: '3rem' }} />
                            </Stack>
                        </Box>
                    ) : (
                        <List dense>
                            {filterCalls().map((call) => (
                                <ListItem key={call.id}>
                                    <Stack
                                        height="100%"
                                        width="100%"
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        borderBottom={1}
                                        borderColor="grey.500"
                                    >
                                        <Stack width="30%">
                                            <Typography variant="subtitle1">
                                                {call.contact?.name == null ? (
                                                    <b>
                                                        {formatNumberInternational(
                                                            call.otherParty
                                                        )}
                                                    </b>
                                                ) : (
                                                    <b> {call.contact.name}</b>
                                                )}
                                            </Typography>
                                            <Typography variant="caption">
                                                {call.contact?.name == null
                                                    ? ''
                                                    : formatNumberInternational(
                                                          call.otherParty
                                                      )}
                                            </Typography>
                                        </Stack>
                                        <Stack width="30%" direction="row">
                                            {call.isSeen === false && (
                                                <NewReleasesIcon color="error" />
                                            )}
                                            <Typography
                                                color={
                                                    call.callType ===
                                                    CallType.Missed
                                                        ? 'red'
                                                        : 'black}'
                                                }
                                            >
                                                {call.callType ===
                                                CallType.Missed
                                                    ? call.callType
                                                    : call.callType +
                                                      ' - ' +
                                                      new Date(
                                                          call.seconds * 1000
                                                      )
                                                          .toISOString()
                                                          .substring(11, 19)}
                                            </Typography>
                                        </Stack>
                                        <Typography width="30%">
                                            {formatDate(call.timestamp)}
                                        </Typography>
                                        <Box width="7.5%">
                                            <IconButton
                                                onClick={(event) =>
                                                    handleMenuButtonClick(
                                                        event,
                                                        call
                                                    )
                                                }
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

export default CallHistory;
