import React, { ReactNode, useEffect, useState } from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/system/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';

//API interface
import { postTestingPairSuperAdmin } from 'Modules/Http/SuperAdminInterface';
import { getAllPhonesInOrganizationAsync } from 'Modules/Http/AdminInterface';

// Common Components
import TitleWithExitButton from 'Modules/Common/Components/TitleWithExitButton';

//Model
import { Phone } from 'Modules/Models/Phone';

type AddPhoneDialogProps = {
    children?: ReactNode;
    isOpen: boolean;
    handleDialogClose(): void;
};
function AddTestingPairDialog({
    children,
    isOpen,
    handleDialogClose,
}: AddPhoneDialogProps) {
    const [testingPairName, setTestingPairName] = useState<String>();
    const [phones, setPhones] = useState<Phone[]>([]);
    const [sendingPhone, setSendingPhone] = useState<Phone | null>();
    const [receivingPhone, setReceivingPhone] = useState<Phone | null>();
    const [arePhonesTheSame, setArePhonesTheSame] = useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    async function fetchData() {
        let allPhones = await getAllPhonesInOrganizationAsync(1);
        setPhones(allPhones ? allPhones : []);
    }

    function SenderFilter(option: Phone) {
        return (
            !receivingPhone || receivingPhone.phoneNumber !== option.phoneNumber
        );
    }

    function ReceiverFilter(option: Phone) {
        return !sendingPhone || sendingPhone.phoneNumber !== option.phoneNumber;
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (sendingPhone === receivingPhone && sendingPhone) {
            setArePhonesTheSame(true);
        } else {
            setArePhonesTheSame(false);
        }
    }, [sendingPhone, receivingPhone]);

    async function handleAdd() {
        try {
            if (testingPairName && sendingPhone && receivingPhone) {
                await postTestingPairSuperAdmin(
                    String(testingPairName),
                    sendingPhone.id,
                    receivingPhone.id
                );
                handleDialogClose();
            }
        } catch (e: any) {
            setErrorAlert(true);
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                <TitleWithExitButton
                    title="Test Pair"
                    exitAction={handleDialogClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction={'column'} spacing={2}>
                    <TextField
                        label="Testing Pair Name"
                        onChange={(value) =>
                            setTestingPairName(value.target.value)
                        }
                    />
                    <Autocomplete
                        options={phones.filter(SenderFilter)}
                        getOptionLabel={(option) => option.phoneNumber}
                        renderInput={(params) => (
                            <TextField {...params} label="Sending Phone" />
                        )}
                        onChange={(event, value: Phone | null) =>
                            setSendingPhone(value)
                        }
                    />
                    <Autocomplete
                        options={phones.filter(ReceiverFilter)}
                        getOptionLabel={(option) => option.phoneNumber}
                        renderInput={(params) => (
                            <TextField {...params} label="Receiving Phone" />
                        )}
                        onChange={(event, value: Phone | null) =>
                            setReceivingPhone(value)
                        }
                    />
                    {arePhonesTheSame && (
                        <Alert severity="warning">
                            Sending and receiving phone cannot match
                        </Alert>
                    )}
                    {errorAlert && (
                        <Alert severity="error">
                            Unable to add testing pair
                        </Alert>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleAdd();
                    }}
                    variant="contained"
                    disabled={
                        testingPairName &&
                        sendingPhone &&
                        receivingPhone &&
                        testingPairName.length > 0 &&
                        !arePhonesTheSame
                            ? false
                            : true
                    }
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default AddTestingPairDialog;
