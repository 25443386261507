import { useMemo, ReactNode } from 'react';

// Material UI
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';

type ThemeProps = {
    children?: ReactNode;
    themeOptions?: ThemeOptions;
};

declare module '@mui/material/styles' {
    interface Palette {
        neutral?: Palette['primary'];
    }

    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

/**
 * Handles changing the application theme
 * @category Theme
 * @subcategory Theme
 */
function Theme({ children, themeOptions }: ThemeProps) {
    const themeObject = useMemo(() => {
        return createTheme(themeOptions);
    }, [themeOptions]);

    return <ThemeProvider theme={themeObject}>{children}</ThemeProvider>;
}
export default Theme;
