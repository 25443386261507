import { format } from 'date-fns';

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

export enum UsageDateRangeOptions {
    Last7Days = 7,
    Last30Days = 30,
    Last60Days = 60,
    Last120Days = 120,
    Custom = 0,
}

function dateRangeToUsageDateRangeOptions(
    dateRange: DateRange<Dayjs>
): UsageDateRangeOptions {
    dateRange = removeTimeFromDateRange(dateRange);
    var today = dayjs()
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 999);

    if (dateRange[1]?.isSame(today)) {
        var last7Days = today
            .subtract(7, 'day')
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);

        if (dateRange[0]?.isSame(last7Days)) {
            return UsageDateRangeOptions.Last7Days;
        }
        var last30Days = today
            .subtract(30, 'day')
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);
        console.log(dateRange[0]);
        console.log(last30Days);
        if (dateRange[0]?.isSame(last30Days)) {
            return UsageDateRangeOptions.Last30Days;
        }
        var last60Days = today
            .subtract(60, 'day')
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);
        if (dateRange[0]?.isSame(last60Days)) {
            return UsageDateRangeOptions.Last60Days;
        }
        var last120Days = today
            .subtract(120, 'day')
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);
        if (dateRange[0]?.isSame(last120Days)) {
            return UsageDateRangeOptions.Last120Days;
        }
        return UsageDateRangeOptions.Custom;
    } else {
        return UsageDateRangeOptions.Custom;
    }
}

function removeTimeFromDateRange(
    dateRange: DateRange<Dayjs>
): DateRange<Dayjs> {
    if (dateRange[0] !== null) {
        dateRange[0] = dateRange[0]
            ?.set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);
    }
    if (dateRange[1] !== null) {
        dateRange[1] = dateRange[1]
            ?.set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .set('millisecond', 999);
    }

    return dateRange;
}

function DateToSortableString(date: Date) {
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date
        .getUTCDate()
        .toString()
        .padStart(2, '0')}T${date
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${date
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')}`;
}

export {
    removeTimeFromDateRange,
    DateToSortableString,
    dateRangeToUsageDateRangeOptions,
};
